import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Divider, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  divider: {
    flexGrow: 1,
    margin: theme.spacing(0, 2),
  },
}));

const TextHorizontalDivider = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.dividerContainer}>
      <Divider className={classes.divider} />
      <Typography variant="body1">{text}</Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

TextHorizontalDivider.propTypes = {
  text: PropTypes.string,
};

export default TextHorizontalDivider;
