import React, { useState } from 'react';
import {
	CircularProgress,
	IconButton,
	TextField,
	Grid,
	Tooltip,
} from '@mui/material';
import { getData } from './network';
import DatabaseIcon from '@mui/icons-material/Storage';
import SearchDbIcon from '@mui/icons-material/FindInPage';
import CrmIcon from '@mui/icons-material/AccountTree';
import ObjectTreeView from './ObjectTreeView';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomerSelect from './CustomerSelect';
import CombineSearchIcon from '@mui/icons-material/CallSplit';
import { formElement, select } from './util';

const SearchIcon = ({ title, IconComponent, active, onClick }) => (
	<Grid item>
		<Tooltip title={title}>
			<IconButton
				onClick={onClick}
				color={active ? 'secondary' : 'inherit'}
			>
				<IconComponent />
			</IconButton>
		</Tooltip>
	</Grid>
);

const InputField = ({ label, value, onChange, name }) => (
	<Grid item xs={12} sm={6} md={2}>
		<TextField
			fullWidth
			label={label}
			value={value}
			onChange={onChange}
			name={name}
		/>
	</Grid>
);

function DebugCRM() {
	const queryOptions = ['CONTACTS', 'ACCOUNTS', 'OPPORTUNITIES'];
	const customerDataOptions = [
		'SALESFORCE',
		'HUBSPOT',
		'PIPEDRIVE',
		'FRESHSALES',
	];

	const [selectedCustomerId, setSelectedCustomerId] = useState(null);
	const [selectedCRMOption, setSelectedCRMOption] = useState('');
	const [selectedQueryOption, setSelectedQueryOption] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [name, setName] = useState('');
	const [crmId, setCrmId] = useState('');
	const [searchResults, setSearchResults] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [activeSearch, setActiveSearch] = useState(''); // New state to track active search
	const [abortController, setAbortController] = useState(null);
	const [website, setWebsite] = useState('');

	const cancelSearch = () => {
		if (abortController) {
			abortController.abort();
			setAbortController(null);
		}
	};
	const handleTextFieldChange = (setter) => (e) => setter(e.target.value);

	const searchFieldsConfig = {
		CONTACTS: [
			{ label: 'CRM ID', value: crmId, setter: setCrmId },
			{ label: 'Email', value: email, setter: setEmail },
			{ label: 'Name', value: name, setter: setName },
			{
				label: 'Phone Number',
				value: phoneNumber,
				setter: setPhoneNumber,
			},
		],
		ACCOUNTS: [
			{ label: 'CRM ID', value: crmId, setter: setCrmId },
			{ label: 'Name', value: name, setter: setName },
			{ label: 'Website', value: website, setter: setWebsite },
		],
		OPPORTUNITIES: [
			{ label: 'CRM ID', value: crmId, setter: setCrmId },
			{ label: 'Name', value: name, setter: setName },
		],
	};

	const renderSearchIcons = () => {
		const icons = [
			{
				title: 'Query Mongo',
				icon: DatabaseIcon,
				action: () => performSearch('mongo'),
				searchType: 'mongo',
			},
			{
				title: 'Query Elastic',
				icon: SearchDbIcon,
				action: () => performSearch('elastic'),
				searchType: 'elastic',
			},
			{
				title: 'Query CRM',
				icon: CrmIcon,
				action: () => performSearch('crm'),
				searchType: 'crm',
			},
			{
				title: 'Combined Mongo and Elastic Search',
				icon: CombineSearchIcon,
				action: () => performSearch('both'),
				searchType: 'both',
			},
			// No need to check for active since the cancel button has a different context
		].map((i) => (
			<SearchIcon
				key={i.title}
				title={i.title}
				IconComponent={i.icon}
				active={activeSearch === i.searchType}
				onClick={i.action}
			/>
		));

		// Adding the abort button separately to always render it but enable/disable based on the abortController's presence
		icons.push(
			<Grid item key="cancel-search">
				<Tooltip title="Cancel Search">
					<span>
						{' '}
						{/* Wrapping in span for tooltip to work on disabled buttons */}
						<IconButton
							onClick={cancelSearch}
							color="secondary"
							disabled={!abortController}
						>
							<CancelIcon />
						</IconButton>
					</span>
				</Tooltip>
			</Grid>
		);
		return (
			<Grid item xs={12} container justifyContent="flex-end" spacing={1}>
				{icons}
			</Grid>
		);
	};

	const renderAdditionalFields = () => (
		<Grid
			container
			spacing={1}
			alignItems="center"
			justifyContent="space-evenly"
		>
			{searchFieldsConfig[selectedQueryOption]?.map(
				({ label, value, setter }) => (
					<InputField
						key={label}
						label={label}
						value={value}
						onChange={handleTextFieldChange(setter)}
						name={label.replace(' ', '').toLowerCase()}
					/>
				)
			)}
			{renderSearchIcons()}
		</Grid>
	);

	async function fetchData(e, controller) {
		const res = await getData(e.endpoint, e.params, controller.signal);
		const json = await res.json();
		console.log('res', e.result, json);
		return {
			[e.result]: json,
		};
	}

	const fetchDataForActSearch = async (actSearch, params, controller) => {
		const endpoints = [];
		const mongoEndpoint = `/support_tools/search/${selectedQueryOption.toLowerCase()}`;
		const crmEndpoint = `/support_tools/crm/fetch-entity`;
		const mp = {
			CONTACTS: 'eoc_search',
			ACCOUNTS: 'eoa_search',
			OPPORTUNITIES: 'eoo_search',
		};
		if (actSearch === 'mongo' || actSearch === 'both')
			endpoints.push({
				endpoint: mongoEndpoint,
				params,
				result: 'mongo',
			});
		if (actSearch === 'elastic' || actSearch === 'both')
			endpoints.push({
				endpoint: '/support_tools/search/dynamic-elastic',
				params: {
					index: mp[selectedQueryOption],
					queryParams: JSON.stringify(params),
				},
				result: 'elastic',
			});
		if (actSearch === 'crm')
			endpoints.push({
				endpoint: crmEndpoint,
				params: {
					...params,
					docType: selectedQueryOption.toUpperCase(),
					crmType: params.sourceCRM,
				},
				result: 'crm',
			});
		return Promise.all(endpoints.map((e) => fetchData(e, controller)));
	};
	const performSearch = async (actSearch) => {
		cancelSearch();
		console.log(`Starting fetchData for ${actSearch}`);
		const controller = new AbortController();
		setAbortController(controller);
		setIsLoading(true);
		setActiveSearch(actSearch);
		setSearchResults({ mongo: null, elastic: null });

		// Prepare the params, excluding any that are unset or empty
		const rawParams = {
			customerId: selectedCustomerId?.trim(),
			sourceCRM: selectedCRMOption?.trim(),
			name: name?.trim(),
			crmId: crmId?.trim(),
			email: email?.trim(),
			phoneNumber: phoneNumber?.trim(),
			website: website?.trim(),
		};

		const params = Object.entries(rawParams).reduce((acc, [key, value]) => {
			if (value) {
				// Only add parameters that are truthy (not undefined, null, or empty strings)
				acc[key] = value;
			}
			return acc;
		}, {});

		try {
			const results = await fetchDataForActSearch(
				actSearch,
				params,
				controller
			);
			setSearchResults(results.reduce((c, a) => ({ ...c, ...a }), {}));
		} catch (error) {
			console.error('Caught error performing search', actSearch, error);
		} finally {
			setIsLoading(false);
			setAbortController(null);
			console.log('Fetch data done', actSearch);
		}
	};

	const searchBoth = async () => {
		performSearch('both');
	};

	const search = async () => {
		performSearch('mongo');
	};

	const searchElasticsearch = async () => {
		performSearch('elastic');
	};

	const searchCRM = async () => {
		performSearch('crm');
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-evenly',
					marginBottom: '20px',
				}}
			>
				{/* Customer Select */}
				<div style={{ width: '25%' }}>
					<CustomerSelect onSelect={setSelectedCustomerId} />
				</div>

				{/* Select CRM Options */}
				<div style={{ width: '25%' }}>
					{formElement(
						select(
							selectedCRMOption,
							setSelectedCRMOption,
							customerDataOptions
						),
						'CRM Options'
					)}
				</div>

				{/* Select Query Options */}
				<div style={{ width: '25%' }}>
					{formElement(
						select(
							selectedQueryOption,
							setSelectedQueryOption,
							queryOptions
						),
						'Query Options'
					)}
				</div>
			</div>
			{/* Render additional fields based on selected query option */}
			{renderAdditionalFields()}
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size={60} />
				</div>
			) : (
				searchResults && (
					<ObjectTreeView
						key={Date.now()}
						name="Search Results"
						obj={searchResults}
					/>
				)
			)}
		</>
	);
}
export default DebugCRM;
