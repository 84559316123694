import React, { useState, useEffect } from 'react';
import { getData, getJsonData, postJson } from './network';
import { IconButton, Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Select, CircularProgress } from '@mui/material';
import MyDoughnut from './MyDoughnut';
import SyncIcon from '@mui/icons-material/Sync';

const HOUR_IN_MS = 60 * 60 * 1000;
const WEEK_IN_MS = 7 * 24 * HOUR_IN_MS;

export default function CustomerMigration(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState({});
  const [inProgress, setInProgress] = useState(false);

  async function onSync(source, fromDate, accessKey, secret, cookie) {
    setInProgress(true);
    fromDate = new Date(fromDate).toISOString();
    const res = await postJson('/migration/' + props.selectedCustomerId + '/new-migration', {
      config: {source, fromDate, accessKey, secret, cookie}
    }).catch(console.error);
    const resText = await res.text();
    alert(resText);
    props.onSync();
    setDialogOpen(false);
    setInProgress(false);
  }

  function getLastestSyncTime() {
    if (!props.migrationConfig) return;
    return props.migrationConfig.map(c => c.migrated_at).sort().reverse().shift();
  }

  function olderThanAWeek(dt) {
    const a = new Date();
    return (a.getTime() - dt.getTime()) > WEEK_IN_MS;
  }

  function showNewMigrationOrMessage() {
    var latestSyncTime = getLastestSyncTime();
    if (latestSyncTime && !olderThanAWeek(new Date(latestSyncTime))) {
      return "Last migration at: " + latestSyncTime + ". Contact eng if you need to restart migration.";
    }
    if (inProgress) {
      return <CircularProgress />;
    }
    return <Button onClick={() => setDialogOpen(true)} variant="contained" color="primary">Start migration</Button>;
  }

  return (<div>
    {props.migrationConfig && props.migrationConfig.length > 1 && <div>Number of migrations: {props.migrationConfig.length}</div>}
    {props.migrationConfig && props.migrationConfig.slice(0, 1).map((config, i) => <div key={i}>
      {config && <div>
        <div>Migrated from: {config.migrated_from}</div>
        <div>Sync from: {config.from_date}</div>
        <div>Migrated at: {config.migrated_at}</div>
        <Button onClick={() => { setStatusDialogOpen(true); setSelectedConfig(config); }} variant="contained" color="primary">Show status</Button>
      </div>}
    </div>)}
    <NewSyncDialog open={dialogOpen} onClose={() => setDialogOpen(false)} selectedCustomerId={props.selectedCustomerId} onSync={onSync} />
    <StatusDialog open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)} config={selectedConfig} onSync={props.onSync} />
    {showNewMigrationOrMessage()}
  </div>);
}

function oneYearBeforeDate() {
  var a = new Date();
  a.setFullYear(a.getFullYear() - 1);
  return a.toISOString().substr(0, 10);
}

function NewSyncDialog(props) {
  const [source, setSource] = useState('GONG');
  const [accessKey, setAccessKey] = useState('');
  const [secret, setSecret] = useState('');
  const [fromDate, setFromDate] = useState(oneYearBeforeDate());
  const [cookie, setCookie] = useState('');
  const [syncing, setSyncing] = useState(false);

  return (<Dialog open={!!props.open} onClose={props.onClose}>
    <DialogTitle>Migration params</DialogTitle>
    <DialogContent>
      <div>
        <Select variant="standard" value={source} onChange={e => setSource(e.target.value)}>
          <MenuItem value="GONG">GONG</MenuItem>
          <MenuItem value="CHORUS">CHORUS</MenuItem>
        </Select>
      </div>
      <div>
        <TextField value={fromDate} onChange={e => setFromDate(e.target.value)} type="date" />
      </div>
      {source === "GONG" && <div>
        <TextField value={accessKey} onChange={e => setAccessKey(e.target.value)} label="Access key" style={{marginRight: '15px'}} />
        <TextField value={secret} onChange={e => setSecret(e.target.value)} label="Access key secret" />
      </div>}
      {source === "CHORUS" && <div>
        <TextField value={accessKey} onChange={e => setAccessKey(e.target.value)} label="API key" style={{marginRight: '15px'}} />
        <TextField value={cookie} onChange={e => setCookie(e.target.value)} label="Cookie" />
      </div>}
    </DialogContent>
    <DialogActions>
        <Button onClick={props.onClose} color="primary">Cancel</Button>
        {syncing && <CircularProgress />}
        {!syncing && <Button onClick={async () => {
          setSyncing(true);
          const res = await getData('/migration/' + props.selectedCustomerId + '/verify-creds', {source, fromDate, accessKey, secret, cookie}).catch(console.error);
          const resText = await res.text();
          alert(resText);
          setSyncing(false);
        }} color="primary" variant="contained">Check creds</Button>}
        {!syncing && <Button onClick={async () => {
          setSyncing(true);
          await props.onSync(source, fromDate, accessKey, secret, cookie);
          setSyncing(false);
          setAccessKey('');
          setSecret('');
          setCookie('');
          setSource('GONG');
        }} color="secondary" variant="contained">Start migration</Button>}
    </DialogActions>
</Dialog>)
}

function StatusDialog(props) {
  const [migratedUsers, setMigratedUsers] = useState(null);
  const [migratedTempUsers, setMigratedTempUsers] = useState(null);
  const [migratedCallStatuses, setMigratedCallStatuses] = useState(null);
  const [metadata, setMetadata] = useState('metadata');

  async function refreshStatus() {
    const source = props.config?.migrated_from;
    console.log('Got source', source, props.config);
    if (!source) return;
    const res = await getJsonData('/migration/' + props.config.customer_id + '/migration-status?source=' + source).catch(console.error);
    console.log('Got migration status: ', res);
    if (res && res.success) {
      setMigratedUsers(res.migratedUsers);
      setMigratedTempUsers(res.migratedTempUsers);
      setMigratedCallStatuses(res.migratedCallStatuses);
    }
    if (props.config?.gong_creds?.metadata) {
      setMetadata(JSON.parse(props.config.gong_creds.metadata));
    }
  }

  async function retryFailedDownload() {
    const configId = props.config?._id;
    if (!configId) {
      alert('Something wrong with migration, contact eng');
      return;
    }
    const res = await getJsonData('/migration/' + props.config.customer_id + `/retry-failed-calls?configId=${configId}`).catch(console.error);
    console.log('Got retry response: ', res);
    alert(res);
    props.onSync();
    props.onClose();
  }

  function latestRetryAttemptInLastHour() {
    var retries = props.config?.retryAttempts;
    if (!retries || retries.length === 0) return false;
    retries.sort();
    retries.reverse();
    return (new Date().getTime() - new Date(retries[0]).getTime()) < HOUR_IN_MS;
  }

  function shouldShowRetry() {
    return migratedCallStatuses && migratedCallStatuses['FAILED_TO_DOWNLOAD'] > 0 && !latestRetryAttemptInLastHour();
  }

  useEffect(refreshStatus, [props.config]);

  return (<Dialog open={!!props.open} onClose={props.onClose}>
    <DialogTitle>Migration status</DialogTitle>
    <DialogContent>
      {migratedUsers && <div>Migrated users: {migratedUsers - migratedTempUsers}{metadata?.users_count ? " / " + metadata.users_count : ""}</div>}
      {migratedTempUsers && <div>Migrated temp users: {migratedTempUsers}</div>}
      {migratedCallStatuses && <div>
        {metadata?.calls_count ? `Migrated calls ${Object.values(migratedCallStatuses).reduce((c, i) => c + i, 0)} / ${metadata.calls_count}` : ""}
        <MyDoughnut title="Call status distribution" stats={migratedCallStatuses} />
      </div>}
    </DialogContent>
    <DialogActions>
      {shouldShowRetry() && <Button onClick={retryFailedDownload}>Retry downloads</Button>}
      <IconButton onClick={refreshStatus}><SyncIcon /></IconButton>
      <Button onClick={props.onClose} color="primary">Close</Button>
    </DialogActions>
  </Dialog>)
}