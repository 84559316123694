import {
	MenuItem,
	Select,
	InputLabel,
	FormControl,
} from '@mui/material';

/** Convert a 2D array into a CSV string
 */
export function arrayToCsv(data) {
  return data.map(row =>
    row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double colons
      .map(v => `"${v}"`)  // quote it
      .join(',')  // comma-separated
  ).join('\r\n');  // rows starting on new lines
}


/** Download contents as a file */
export function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

export function downloadFile(contents, type, fileName) {
  const binaryString = window.atob(contents);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const blob = new Blob([bytes], {
    type,
  });
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
}


export function readBlobAsText(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsText(blob);
  });
}

// utils.js
export function displayStr(item) {
  const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
  return str.replace(/_/g, ' ');
}

export function select(state, setState, arr, multiple) {
	return (
		<Select
			value={state}
			multiple={multiple}
			onChange={(e) => setState(e.target.value)}
		>
			{arr.map((item, i) => (
				<MenuItem key={i} value={item}>
					{displayStr(item)}
				</MenuItem>
			))}
		</Select>
	);
}

export function formElement(elem, label, opacity = 1) {
	return (
		<FormControl
			variant="standard"
			style={{ opacity, marginBottom: '1rem', width: '100%' }}
		>
			{label && <InputLabel>{label}</InputLabel>}
			{elem}
		</FormControl>
	);
}

export function formatQuery (query) {
	// Replace commas with comma followed by newline for better readability
	let formattedQuery = query.replace(/, /g, ',\n  ');

	// Add newline before keywords for better structure
	formattedQuery = formattedQuery.replace(
		/(select|from|where)/gi,
		'\n$1'
	);

	// Indent the content after keywords for better alignment
	formattedQuery = formattedQuery.replace(
		/\n(select|from|where)/gi,
		'\n$1\n  '
	);

	return formattedQuery;
};

export function formatDate (dateString) {
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		// Invalid date, return the original string
		return dateString;
	} else {
		// Valid date, format and return it
		return date.toLocaleString();
	}
};

export function getRelevantFileName  (fileName) {
	const parts = fileName.split('/');
	const lastPart = parts[parts.length - 1];
	const fileNameParts = lastPart.split('_');

	const fileType = fileNameParts[fileNameParts.length - 1]; // Extract the file type (request or response)
	const timestamp = fileNameParts[fileNameParts.length - 3]; // Extract the timestamp
	const uniqueId = fileNameParts[fileNameParts.length - 2]; // Extract the unique identifier

	const relevantPart = fileNameParts.slice(0, -3).join('_'); // Extract the relevant part of the file name

	return `${relevantPart} - ID: ${uniqueId} ${fileType} `;
};