import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Alert,
  Typography,
} from "@mui/material";
import DataTableAndExport from "./DataTableAndExport";
import { makeStyles } from "@mui/styles";
import { postWithoutParsingBody } from "../network";
import UploadFileUI from "./common/UploadFile";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  modalStyle: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.33)",
    borderRadius: "8px",
    color: "#111827",
  },
  createUserTable: {
    width: 700,
    padding: "0px 20px",
  },
  header: {
    borderBottom: "1px solid #E2E8F0",
    padding: 16,
  },
  alignSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#3f51b5",
    textDecoration: "none",
    fontWeight: 500,
  },
  closeIconUi: {
    cursor: "pointer",
    color: "#808080",
  },
  textAlign: {
    textAlign: "center",
  },
  footer: {
    background: "#F9FAFB",
    fontSize: "14px",
    padding: "12px 20px 12px 20px",
    marginTop: "5px",
  },
}));

function BulkUploadModal({ openModal, setOpenModal, customerId, csvSampleLink, children, bulkUploadCsv, columns, defaultExcelText = " Default Excel", loadingForDownload, handleCsvSample, isSampleLink = true }) {
  const [csvFile, setCsvFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [usersResponse, setUsersResponse] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const classes = useStyles();

  const handleClose = () => setOpenModal(false);
  const setFile = async (e) => {
    setCsvFile(e.target.files[0]);
  };

  const createUserByCsv = async () => {
    if (!csvFile || csvFile.length === 0) {
      setErrorMsg("Choose CSV file to upload");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    const emailId = localStorage.getItem('email_id');
    formData.append('csvfile', csvFile, csvFile.name);
    formData.append('customerId', customerId);
    formData.append('emailId', emailId);
    const topicResponse = await bulkUploadCsv(formData);
    if (topicResponse.status === 200) {
      const topicJson = await topicResponse.json();
      if (topicJson?.error) setErrorMsg(topicJson?.message);
      else {
        const topicGenericRes = topicJson?.genericResponses?.map((item, index) =>
          ({ ...item, id: index + 1 })) ?? [];
        setUsersResponse(topicGenericRes);
      }
    }
    else setErrorMsg(topicResponse?.response || "Error while uploading topics");
    setLoading(false);
  };

  if (usersResponse.length > 0) {
    return (
      <Modal open={openModal} onClose={handleClose}>
        <Box className={`${classes.modalStyle} ${classes.createUserTable}`}>
          <DataTableAndExport
            usersResponse={usersResponse}
            pageSize={10}
            columns={columns}
            goBackOption={() => setUsersResponse([])}
          />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box className={classes.modalStyle}>
        {/* header Part */}
        <Box className={`${classes.alignSpaceBetween} ${classes.header}`}>
          {children}
          <CloseIcon
            className={`${classes.closeIconUi}`}
            onClick={handleClose}
          />
        </Box>

        {/* Body Part Modal */}
        <UploadFileUI setFile={setFile} csvFile={csvFile} type=".csv" />
        <Typography className={classes.textAlign}>Choose CSV only</Typography>

        {/* Footer part Modal */}
        <div className={`${classes.alignSpaceBetween}  ${classes.footer}`}>
          {isSampleLink ?
            <a
              className={classes.link}
              href={csvSampleLink}
              target={"_blank"}
            >
              {defaultExcelText}
            </a>
            :
            <Button variant="text" onClick={handleCsvSample}>
              {loadingForDownload && (
                <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />
              )}
              {defaultExcelText} </Button>
          }

          <div>
            <Button variant="text" color="error" onClick={() => setCsvFile('')}> Clear </Button>
            <Button
              variant="contained"
              onClick={createUserByCsv}
              color="primary"
              disabled={loading}
            >
              {loading && (
                <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />
              )}
              Submit
            </Button>
          </div>
        </div>

        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      </Box>
    </Modal >
  );
}
export default BulkUploadModal;
