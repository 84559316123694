import React, { Component } from 'react';
import { getConfig } from './config';
import CustomerDetail from './CustomerDetail';
import { Snackbar, TextField } from '@mui/material';
import { getJsonData } from './network';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { GridLinkOperator } from "@mui/x-data-grid";

const sum = (arr, field) => {
  if (!arr.length) return 0;
  var s = 0;
  for (var i = 0; i < arr.length; i++) s += arr[i][field] || 0;
  return s;
}

function CustomToolbar() {
  return (<GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>);
}

class CustomersTable extends Component {
  constructor(props) {
    super(props);
    this.config = getConfig();

    this.state = {
      data: [],
      filterText: '',
      filterModel: {items: []},
      loading: true,
      selectedCustomer: null
    };
    this.columns = [
      {
        headerName: 'Name',
        field: 'name',
        filterable: true,
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? row[id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 : true;
        },
        Footer: columnProps => {
          return <span>Count: {columnProps.data.length}</span>
        }
      }, {
        field: 'recording',
        headerName: 'Recording',
        valueGetter: (params) => params.row.recording_disabled ? 'False' : 'True'
      }, {
        field: 'unbilled',
        headerName: 'Unbilled',
        type: 'number',
        valueGetter: (params) => {
          const row = params.row;
          const recording_users = row && row.recording_users ? row.recording_users.length : 0;
          const total_billed = row.total_billed ? row.total_billed : 0;
          return recording_users - total_billed;
        },
        Footer: columnProps => {
          return <span>Total: {sum(columnProps.data, 'unbilled')}</span>
        }
      }, {
        field: 'recording_users',
        headerName: 'Recording Users',
        type: 'number',
        valueGetter: params => params.row && params.row.recording_users && params.row.recording_users.length,
        Footer: columnProps => {
          return <span>Total: {sum(columnProps.data, 'recording_users.length')}</span>
        }
      }, {
        headerName: 'Total billed',
        field: 'total_billed',
        type: 'number',
        Footer: columnProps => {
          return <span>Total: {sum(columnProps.data, 'total_billed')}</span>
        }
      }
    ];
    getJsonData('/billing/')
      .then(data => {
        const selectedId = window.location.hash.length > 0 ? window.location.hash.substr(1) : null;
        var selectedCustomer = null;
        if (selectedId) selectedCustomer = data.find(customer => customer._id === selectedId) || null;
        this.setState({ loading: false, data, selectedCustomer });
      });
    this.updateRecording = this.updateRecording.bind(this);
  }

  updateRecording(value) {
    var selectedCustomer = { ...this.state.selectedCustomer };
    selectedCustomer.recording_disabled = value;
    this.setState({ selectedCustomer });
  }

  render() {
    return (
      <div >
        <div style={{ display: "flex", height: "600px" }}>

          <Snackbar open={this.state.loading} message="Loading..." />
          <div style={{ width: "66%", overflow: "auto" }}>
            <TextField label="Search" variant="standard" value={this.state.filterText} onChange={e => {
              this.setState({
                filterText: e.target.value,
                filterModel: {
                  items: e.target && e.target.value && e.target.value.length > 0 ? [{
                    columnField: 'name',
                    value: e.target.value,
                    operatorValue: 'contains'
                  }] : [],
                  linkOperator: GridLinkOperator.Or
                }
              });
            }} />
            <DataGrid
              rows={this.state.data}
              columns={this.columns}
              pageSize={10}
              filterModel={this.state.filterModel}
              density="compact"
              components={{
                Toolbar: CustomToolbar
              }}
              onCellClick={params => {
                console.log('Click on row', params);
                this.setState({selectedCustomer: params.row});
                window.location.hash = params.row._id;
              }}
              getRowId={row => row._id} />
          </div>
          <div style={{ width: "33%", overflowY: "scroll", padding: "10px" }}>
            <CustomerDetail customer={this.state.selectedCustomer} updateRecordingCb={this.updateRecording}></CustomerDetail>
          </div>
        </div>
      </div>
    )
  }
};

export default CustomersTable;