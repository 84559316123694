import React from 'react';
import {getConfig} from './config';
import { Paper } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';

const onGoogleLogin = async(response) => {
  console.log('Got onGoogleLogin callback', response);
  const body = JSON.stringify({
    googleOAuthCode: response.code,
    redirectUri: window.location.origin
  });
  const headers = new Headers({'Content-Type': 'application/json'});
  const backendResponse = await fetch(getConfig().backendUrl + '/auth', {method: 'POST', headers, credentials: 'include', body});
  if (!backendResponse.ok) {
    alert("Login failure, either you're not admin or your accessToken is invalid");
    return;
  }
  const jsonResponse = await backendResponse.json();
  localStorage.setItem('email_id', jsonResponse.emailId);
  localStorage.setItem('loggedIn', true);
  localStorage.setItem('userName', jsonResponse.userName);
  if (jsonResponse.isAdmin) localStorage.setItem('isAdmin', jsonResponse.isAdmin);
  window.location.reload();
}

export async function Logout() {
  const response = await fetch(getConfig().backendUrl + '/logout', {method: 'POST', credentials: 'include', body: ''});
  if (!response.ok) {
    alert('Logout failure, maybe retry', response);
    return;
  }
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('userName');
  localStorage.removeItem('isAdmin');
  window.location.href = '/';
}

export function isLoggedIn() {
  return !!localStorage.getItem('loggedIn');
}

export function isAgent() {
  return !localStorage.getItem('isAdmin');
}

export function getDomain() {
  if(!localStorage.getItem('email_id')) Logout();
  else return localStorage.getItem('email_id')?.split("@")[1];
}

export default function Login() {

  const login = useGoogleLogin({
    onSuccess: onGoogleLogin,
    flow: 'auth-code',
  });

  return (<Paper style={{textAlign: 'center', padding: '100px'}}>
    <button style={{backgroundColor: '#fff',
        display: 'inline-flex',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, .54)',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
        padding: 0,
        borderRadius: 2,
        border: '1px solid transparent',
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif'}} onClick={() => login()}>
      <div style={{ marginRight: 10, background: '#fff', padding: 10, borderRadius: 2 }}>
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="#000" fillRule="evenodd">
            <path
              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
              fill="#EA4335"
            />
            <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4" />
            <path
              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
              fill="#FBBC05"
            />
            <path
              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
              fill="#34A853"
            />
            <path fill="none" d="M0 0h18v18H0z" />
          </g>
        </svg>
      </div>
      <span style={{ paddingRight: 10, fontWeight: 500, paddingLeft: 0, paddingTop: 10, paddingBottom: 10 }}>
        Sign in with Google
      </span>
    </button>
  </Paper>);
}