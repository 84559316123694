import React, { useContext, useMemo, useState } from 'react';
import { getData, postJson } from '../../../network';
import GptTextOptionsComponent from './GptTextOptionsComponent';
import screens from '../screens';
import GptPlaygroundCallTextInput from './GptPlaygroundCallTextInput';
import { useGptPlaygroundActions } from '../customHooks';
import { GptPlaygroundContext, GptPlaygroundDispatchContext, actionTypes } from '../redux';

export default function GptPlaygroundInputComponent() {

  const {
    screen: { screenData },
  }  = useContext(GptPlaygroundContext);

  const gptPlaygroundDispatcher = useContext(GptPlaygroundDispatchContext);

  const {
		setLoading,
		setError,
		resetLoading,
		resetError,
    updateScreen,
	} = useGptPlaygroundActions();

  const currScreenData = screenData[screens.userGptPromptInputScreen];

  //Since the callId value in the store is not updated for every user character input,
  //that value is stored in callId, and then once the input is completed,
  //this value is stored in the store's callId.
  const [callId, setCallId] = useState(currScreenData?.callId ?? '');

  //Since the text value in the store is not updated for every user character input,
  //that value is stored in userText, and then once the input is completed,
  //this value is stored in the store's text.
  const [userText, setUserText] = useState(currScreenData?.text ?? '');

  const [showTextInputOptions, setTextInputOptionsState] = useState(true);

  const [transcript, setTranscript] = useState(
    callId.length && currScreenData?.transcript?.length
      ? currScreenData.transcript
      : ''
  );


   //Pre-populate the user prompt (If it exists in the redux store).
   const userPrompt = useMemo(() => {
    return callId.length && currScreenData?.userPrompt?.length
      ? currScreenData.userPrompt
      : '';
  }, [callId, currScreenData]);

  //Pre-populate the system prompt (If it exists in the redux store).
  const systemPrompt = useMemo(() => {
    return callId.length && currScreenData?.systemPrompt?.length
      ? currScreenData.systemPrompt
      : '';
  }, [callId, currScreenData]);

  const setCallIdHandler = async (callID) => {
    setCallId(callID.target.value);
  };

  const getTranscript = async (callID) => {
    const callTranscriptResponse = await getData(
      `/gpt-playground/call-transcript?callId=${callID}`
    );
    return callTranscriptResponse;
  };

  const onSuccessfulSummaryFetch = (summary, screenData) => {
		gptPlaygroundDispatcher({
			type: actionTypes.SET_SUMMARY,
			payload: summary,
		});
		updateScreen(
			screens.showSummaryScreen,
			screens.userGptPromptInputScreen,
			screenData
		);
	};

  const getTranscriptHandler = async () => {
    setLoading();
    try {
      const callTranscriptResponse = await getTranscript(callId);
      if (callTranscriptResponse.status !== 200) {
        throw new Error('Something went wrong!');
      }
      const body = await callTranscriptResponse.json();
      setTranscript(body.transcript);
      resetError(false);
    } catch (e) {
      setError('Something went wrong', e);

    } finally {
      resetLoading();
    }
  };

  const getSummaryResponse = async (systemPrompt, userPrompt) => {
    const body = {
      callId,
      systemPrompt,
      userPrompt,
      type: callId.length ? "call" : "text",
      text: userText
    };
    const response = await postJson('/gpt-playground/summary', body);
    return response;
  };

  const getSummaryHandler = async (systemPrompt, userPrompt) => {
    setLoading();
    try {
      const response = await getSummaryResponse(systemPrompt, userPrompt);
      if (response?.status !== 200) {
        setError('Invalid Input!!', e);
        return;
      }
      const body = await response.json();
      onSuccessfulSummaryFetch(body.summary.toString(), {
        transcript: transcript,
        callId: callId,
        userPrompt: userPrompt,
        systemPrompt: systemPrompt,
        text: userText,
      });
    } catch (e) {
      console.log(e);
    } finally {
      resetLoading();
    }
  };

  const onUserTextHandle = (userText) => {
    setUserText(userText);
  };

  const onNextHandle = async () => {
    if (!!callId.length) {
      await getTranscriptHandler();
    }
    setTextInputOptionsState(false);
  };

  const onBackHandle = () => {
    setTextInputOptionsState(true);
  };

  return (
    <>
      <GptPlaygroundCallTextInput
        isInputState={showTextInputOptions}
        callId={callId}
        setCallIdHandler={setCallIdHandler}
        userText={userText}
        onUserTextHandle={onUserTextHandle}
        onNextHandle={onNextHandle}
      />
      {!showTextInputOptions ? (
        <GptTextOptionsComponent
          transcript={transcript}
          showTranscript={!!callId.length}
          getSummary={getSummaryHandler}
          userPrompt={userPrompt}
          systemPrompt={systemPrompt}
          onBackHandle={onBackHandle}
        />
      ) : null}
    </>
  );
}