import React, { useState, useEffect, useRef } from 'react';
import { Paper } from '@mui/material';
import { getJsonData } from './network';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: 'service',
    headerName: 'Service'
  }, {
    field: 'deployedFromLocal',
    headerName: 'Latest deployed commit',
    flex: 1
  }, {
    field: 'latestCommitBy',
    headerName: 'Latest commit by'
  }, {
    field: 'commit',
    renderCell: params => <a target="_blank" href={params.row.browse}>{params.row.commitMsg}</a>,
    headerName: 'Commit message',
    sortable: false,
    flex: 1
  }
];

export default function Deployments(props) {

  const [rows, setRows] = useState([]);
  const ref = useRef();

  function parseDeploymentsToRows(data) {
    if (!data || !data.deployments) return [];
    var result = data.deployments.map((d, i) => {
      const ret = {
        id: i,
        service: d.name
      };
      const metadata = d.metadata && d.metadata[0] && d.metadata[0];
      if (metadata) {
        ret.deployedFrom = metadata.author.date;
        ret.deployedFromLocal = new Date(metadata.author.date).toString();
        ret.latestCommitBy = metadata.author.name;
        ret.browse = metadata.browse;
        ret.commitMsg = metadata.message;
      }
      return ret;
    });
    result.sort((a, b) => (!b.deployedFrom || a.deployedFrom > b.deployedFrom) ? -1 : 1);
    return result;
  }

  function fetchDeployments() {
    getJsonData('/deployments/list').then(data => parseDeploymentsToRows(data)).then(setRows).catch(console.error);
  }

  useEffect(() => {
    fetchDeployments();
  }, []);

  return (<Paper style={{ height: 500, width: '100%' }}>
    <DataGrid
      columns={columns}
      rows={rows}
      pageSize={10}
      density="compact"
    />
  </Paper>);
}