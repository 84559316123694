import React, {Component} from 'react';
import './DiarizationReview.css';
import {Link} from "react-router-dom";
import { Paper, TextField, Button, Accordion, AccordionSummary, AccordionDetails, IconButton, Snackbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { getJsonData } from './network';

export default class DiarizationReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewedCalls: [],
      reviewedCallsLoaded: false,
      toReviewCallsLoaded: false,
      toReviewCalls: []
    }
    getJsonData('/diarization/reviewed-calls')
      .then(data => this.setState({reviewedCallsLoaded: true, reviewedCalls: data}))
      .catch(console.error);
    getJsonData('/diarization/to-review-calls')
      .then(data => this.setState({toReviewCallsLoaded: true, toReviewCalls: data}))
      .catch(console.error);
  }

  callsList(list) {
    return list.map(callId => <Link to={"./" + callId}><Button color="primary" style={{textTransform: 'none'}}>{callId}</Button></Link>);
  }

  render() {
    return (<Paper style={{padding: "30px", overflowY: "scroll"}}>
      <Snackbar open={!this.state.reviewedCallsLoaded || !this.state.toReviewCallsLoaded} message="Loading..." />
      <div style={{textAlign: 'center', marginBottom: '30px'}}>
        <TextField label="Call id" id='callId' />
        <IconButton onClick={() => {window.location.href = './' + document.getElementById('callId').value}}>
          <RateReviewIcon />
        </IconButton>
      </div>
      <Accordion>
        <AccordionSummary>
          Review instructions
        </AccordionSummary>
        <AccordionDetails>
- Aim is to separate user audio from rest of the call.<br/>
- You can listen to user’s audio fingerprint by clicking on “Play fingerprint” button.<br/>
- Call is already segmented into turns, in most cases it has only audio from one person, but it may also have multiple people talking.<br/>
- You’ve to identify and mark if the turn has<br/>
&nbsp;&nbsp;• only the expected user speaking during entire turn<br/>
&nbsp;&nbsp;• user not speaking during entire turn<br/>
&nbsp;&nbsp;• there are both user’s audio and other’s audio present in the turn.<br/>
NOTE: Filler words like Hmm, okay from other party can be ignored in a big turn.<br/>
- You can use keyboard shortcuts to review faster<br/>
J/&lt;Down&gt;  - focus on next turn<br/>
K/&lt;Up&gt;    - focus on previous turn<br/>
H/&lt;Left&gt;  - mark turn as expected user<br/>
L/&lt;Right&gt; - mark turn as not user<br/>
I - mark turn as both user and not user<br/>
&lt;Space&gt; - play turn audio<br/>
F - play fingerprint<br/>
S - submit reviews<br/>
- You can adjust playing speed of call audio according to your preference.<br/>
- Once you review a turn it’ll turn blue/yellow/green from grey. <br/>
- Reviews are stored in your browser as you review, so even if you close the page by mistake, it will not be lost.<br/>
- Once you’ve reviewed all turns, you can submit reviews using Submit button.
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Calls to review
        </AccordionSummary>
        <AccordionDetails style={{flexDirection: "column"}}>
          {this.callsList(this.state.toReviewCalls)}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Calls reviewed
        </AccordionSummary>
        <AccordionDetails style={{flexDirection: "column"}}>
          {this.callsList(this.state.reviewedCalls)}
        </AccordionDetails>
      </Accordion>
    </Paper>);
  }
}