import { useContext } from "react";
import {
    actionTypes,
    GptPlaygroundDispatchContext,
} from '../redux';

const useGptPlaygroundActions = () => {

    const gptPlaygroundDispatcher = useContext(GptPlaygroundDispatchContext);

    const setLoading = () => {
		gptPlaygroundDispatcher({
			type: actionTypes.SET_LOADING,
		});
	};

	const resetLoading = () => {
		gptPlaygroundDispatcher({
			type: actionTypes.RESET_LOADING,
		});
	};

	const resetError = (errorMessage) => {
		gptPlaygroundDispatcher({
			type: actionTypes.RESET_ALERT,
		});
	};

	const setError = (errorMessage, errorObject) => {
		gptPlaygroundDispatcher({
			type: actionTypes.SET_ALERT,
			payload: {
				hasError: true,
				message: errorMessage,
				error: errorObject,
			},
		});
	};

	const changeScreen = (screenName) => {
		gptPlaygroundDispatcher({
			type: actionTypes.UPDATE_SCREEN_TYPE,
			payload: screenName,
		});
	};

	const updateScreen = (screenName, updatedScreen, screenData) => {
		gptPlaygroundDispatcher({
			type: actionTypes.UPDATE_SCREEN,
			payload: {
				screenName: screenName,
				updatedScreen: updatedScreen,
				screenData: screenData,
			},
		});
	};


    return {
        setLoading,
        resetLoading,
        resetError,
        setError,
        updateScreen,
        changeScreen
    }
}

export default useGptPlaygroundActions;