import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
} from '@mui/material';

function SyncDetailsTable({ data }) {
	if (!data || typeof data !== 'object') {
		
		return null; 
	}

	const rows = Object.entries(data).map(([key, value]) => ({ key, value }));

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.key}>
							<TableCell component="th" scope="row">
								{row.key}
							</TableCell>
							<TableCell>
								{JSON.stringify(row.value, null, 2)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default SyncDetailsTable;
