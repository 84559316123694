import screens from "../screens";



/*
 * 
  screenData for screens.userGptPromptInputScreen:
  {  
    transcript: string,
    callId: string,
    userPrompt: string,
    systemPrompt: string,
    type: callId | text,
    text: string,
    temperature: number,
  }
 * */
const initialState = {
    screen: {
      currScreen: screens.userGptPromptInputScreen,
      screenData: {},
    },
    alert: {
      hasError: false,
      message: '',
      error: null,
    },
    loading: false,
    summary: {
      text: '',
    },
};

export default initialState;