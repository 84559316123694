import React, { useState } from 'react';
import { useEffect } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { Link } from "react-router-dom";
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getJsonData, getData } from './network';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { GridLinkOperator } from "@mui/x-data-grid";

function nameOrId(row) {
  return row.name ? row.name : row._id;
}

export default function Customers(props) {
  var [customers, setCustomers] = useState([]);
  var [customerInfo, setCustomerInfo] = useState(null);
  var [loading, setLoading] = useState(false);
  var [filterModel, setFilterModel] = useState({items: []});
  var [filterText, setFilterText] = useState('');
  
  function showInfo(id) {
    setLoading(true);
    getJsonData('/customers/' + id + '/info').then(setCustomerInfo).catch(console.error).then(() => setLoading(false));
  }

  function CustomToolbar() {
    return (<GridToolbarContainer>
      
    </GridToolbarContainer>);
  }

  const columns = [{
    id: 1,
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortable: false,
    renderCell: params => {
      return (<>
        {params.row.recording_disabled ? <CancelIcon style={{color: 'red', verticalAlign: 'middle', marginRight: '10px'}} /> : <CheckCircleIcon style={{color: 'green', verticalAlign: 'middle', marginRight: '10px'}} /> }
        <Link to={`/customers/edit/${params.row._id}`} style={{marginRight: '10px'}}>
          <IconButton style={{padding: '0'}}>
            <CreateIcon />
          </IconButton>
        </Link>
        <IconButton onClick={() => showInfo(params.row._id)} style={{marginRight: '10px', padding: '0'}}>
          <InfoIcon />
        </IconButton>
        {nameOrId(params.row)}
      </>);
    }
  }, {
    id: 2,
    field: 'domains',
    headerName: 'Domains',
    flex: 1,
    sortable: false,
    valueGetter: params => {
      return params.row.authorized_domains ? params.row.authorized_domains.join(', ') : '';
    }
  }, {
    id: 3,
    hide: true,
    field: 'created_date'
  }];

  useEffect(() => {
    setLoading(true);
    getJsonData('/customers/all').then(setCustomers).catch(console.error).then(() => setLoading(false));
  }, []);

  function syncCustomer(id) {
    if (id === null) {
      alert('id empty');
    } else {
      getData('/customers/sync/' + id).then(d => d.text()).then(d => alert(d))
    }
  }

  function showCustomerInfoDialog() {
    if (!customerInfo) return null;
    return (<Dialog open={customerInfo !== null}
        onClose={() => setCustomerInfo(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{customerInfo.name}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            Id: {customerInfo.id}
            <IconButton onClick={() =>  navigator.clipboard.writeText(customerInfo.id)}>
              <FlipToBackIcon />
            </IconButton>
          </div>
          <div style={{display: 'flex', verticalAlign: 'middle'}}>
            Recording: {customerInfo.recording ? <CheckCircleIcon style={{color: 'green'}} /> : <CancelIcon style={{color: 'red'}} /> }
          </div>
          <div>
            Admins: {customerInfo.admins.map(a => a.email_id).join(', ')}
          </div>
          <div>
            <span style={{paddingRight: '20px'}}>Total users: {customerInfo.totalUsers}</span>
            <span>Recording users: {customerInfo.recordingUsers}</span>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => syncCustomer(customerInfo.id)}>Sync Integrations</Button>
        <Button onClick={() => setCustomerInfo(null)} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>)
  }

  return (<div>
    {showCustomerInfoDialog()}
    <div style={{margin: '5px', padding: '5px'}}>
      <Link to="./new" style={{textDecoration: 'none'}}>
        <Button color="primary" variant="contained">New customer</Button>
      </Link>
      <TextField value={filterText} placeholder="Search" style={{marginLeft: '10px'}} onChange={e => {
        setFilterText(e.target.value);
        setFilterModel({
          items: e.target && e.target.value && e.target.value.length > 0 ? [{
            columnField: 'name',
            value: e.target.value,
            operatorValue: 'contains'
          }] : [],
          linkOperator: GridLinkOperator.Or
        })
      }} />
    </div>
    <Paper style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={customers}
        columns={columns}
        pageSize={10}
        getRowId={row => row._id}
        density="compact"
        filterModel={filterModel}
        components={{
          Toolbar: CustomToolbar
        }}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_date', sort: 'desc' }],
          },
        }} />
    </Paper>
  </div>);
}