const CONFIG = {
    default: {
        backendUrl: 'https://admin-backend.copilot.clari.com',
        appLink: 'https://copilot.clari.com',
        apiServerUrl: 'https://backend.copilot.clari.com',
        envMarker: '',
    },
    'eks-dev': {
        backendUrl: 'https://admin-backend.dev.copilot.clari.com',
        appLink: 'https://dev.copilot.clari.com',
        apiServerUrl: 'https://backend.dev.copilot.clari.com',
        envMarker: ' Dev 😑',
    },
    'eks-prod': {
        backendUrl: 'https://admin-backend.copilot.clari.com',
        apiServerUrl: 'https://backend.copilot.clari.com',
    },
    local: {
        appLink: 'https://localhost:3000',
        backendUrl: 'http://localhost:3003',
        apiServerUrl: 'http://localhost:8080',
        envMarker: ' Local 😜',
    },
};

let config = null;

function extractEnvName(url) {
    const regex = /dev(\d+)/;
    const match = url.match(regex);

    if (match && match[1]) {
        // The number will be captured in the first capturing group (index 1)
        return `dev${match[1]}`;
    }

    return 'eks-dev';
}

export function getConfig() {
    if (config) return config;
    const url = window.location.href;
    const env = url.startsWith('http://localhost')
        ? 'local'
        : url.includes('dev')
            ? extractEnvName(url)
            : 'eks-prod';
    if (env.includes('dev') && env != 'eks-dev') {
        CONFIG[env] = {
            backendUrl: `https://admin-backend.${env}.copilot.clari.com`,
            appLink: `https://${env}.copilot.clari.com`,
            apiServerUrl: `https://backend.${env}.copilot.clari.com`,
            envMarker: `${env}`,
        };
    }
    console.log(`Loading config for env: ${env}`);
    if (!CONFIG.hasOwnProperty(env)) {
        console.error(`Unable to load env ${env}`);
        return CONFIG.default;
    }
    return Object.assign(CONFIG.default, CONFIG[env]);
}

config = getConfig();
