import React from 'react';
import {isAgent} from './Login';
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {getPageTitle} from "./App";
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {getConfig} from './config';

const envMarker = getConfig().envMarker;

function getUserState() {
    const userName = localStorage.getItem('userName');
    if (userName) return (<>
        <small>{userName}</small>
        <Link className="header-link" to="/logout/">
            <IconButton title={"Bye, " + userName} style={{color: "white"}}>
                <ExitToAppIcon />
            </IconButton>
        </Link>
    </>);
    return (<Link to="/">
        <IconButton style={{color: "white"}}>
            <MeetingRoomIcon />
        </IconButton>
    </Link>);
}

function Headers(props) {
    const location = useLocation();

    return (<AppBar position="static" style={{marginBottom: "20px", color: "white"}}>
        <Toolbar>
            <div style={{flex: "1 0 0"}}>
                {location && location.pathname !== '/' && <Link to="/">
                    <IconButton style={{color: "white"}}>
                        <HomeIcon />
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Link>}
                <Typography variant="h7">{getPageTitle(location && location.pathname)}</Typography>
            </div>
            <div style={{flex: "1 0 0", textAlign: 'center'}}>
            <Typography variant="h6">Copilot {isAgent() ? "Agent" : "Admin"}{envMarker}</Typography>
            </div>
            <div style={{flex: "1 0 0", textAlign: 'right'}}>
                {getUserState(props)}
            </div>
        </Toolbar>
    </AppBar>);
}

export default Headers;