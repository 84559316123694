import { EMAIL_REGEX_PATTERN } from 'utils/constants';

export function validateEmail(email) {
    return EMAIL_REGEX_PATTERN.test(email);
}

export function displayStr(item) {
    const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    return str.replace(/_/g, ' ');
}

