import React, { useState, useEffect } from 'react';
import {
	TextField,
	Grid,
	Tabs,
	Tab,
	Backdrop,
	CircularProgress,
	FormGroup,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	AccordionSummary,
	Accordion,
	AccordionDetails,
	Typography,
} from '@mui/material';
import ObjectTreeView from './ObjectTreeView';
import { getData } from './network';
import PropTypes from 'prop-types';
import ApiIcon from '@mui/icons-material/Api';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{children}
		</div>
	);
}

function displayStr(item) {
	const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
	return str.replace(/_/g, ' ');
}

function formElement(elem, label, opacity = 1) {
	return (
		<FormControl
			variant="standard"
			style={{ opacity, marginBottom: '1rem' }}
		>
			{label && <InputLabel>{label}</InputLabel>}
			{elem}
		</FormControl>
	);
}

function select(state, setState, arr, multiple) {
	return (
		<Select
			value={state}
			multiple={multiple}
			onChange={(e) => setState(e.target.value)}
		>
			{arr.map((item, i) => (
				<MenuItem key={i} value={item}>
					{displayStr(item)}
				</MenuItem>
			))}
		</Select>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export default function DebugCallSummaries(props) {
	const [modelList, setModelList] = useState([]);
	const [versionIdList, setVersionIdList] = useState([]);
	const [callIdsStr, setCallIdsStr] = useState('');
	const [loading, setLoading] = useState(false);
	const [modelName, setModelName] = useState('');
	const [versionId, setVersionId] = useState('');
	const [versionToCompare, setVersionToCompare] = useState('');
	const [callSummariesObj, setCallSummariesObj] = useState({});
	const [getGptOutput, setGetGptOutput] = useState(false);

	//Populates the model list on page load
	useEffect(() => {
		(async () => {
			try {
				var data = await getData(
					'/debug_call/gpt/get-model-list/' + modelName
				);
				data = await data.json();
				data.sort();
				setModelList(data);
			} catch (e) {
				alert('Models could not be fetched' + e.message);
			}
		})();
	}, []);

	//Populates the version list on model name change
	useEffect(() => {
		if (!modelName) {
			return;
		}
		(async () => {
			try {
				var data = await getData(
					'/debug_call/gpt/get-version-list/' + modelName
				);
				data = await data.json();
				data.sort();
				setVersionIdList(data);
			} catch (e) {
				alert(
					'Versions could not be fetched for the specified model' +
						e.message
				);
			}
		})();
	}, [modelName]);

	//runs the prompt
	useEffect(() => {
		if (getGptOutput == false) return;
		if (!callIdsStr || !versionId || !modelName) {
			alert("CallIds or version can't be empty");
			setGetGptOutput(false);
			return;
		}
		let callList = callIdsStr?.split('\n').map((c) => c.trim());
		if (callList.length > 5) {
			alert(
				"Load throttling: You can't process more than 5 calls at a time"
			);
			setGetGptOutput(false);
			return;
		}
		(async () => {
			setLoading(true);
			console.log('sending request to run the prompt');
			const callProtoData = await getData(
				'/debug_call/gpt/get-prompt-output',
				{
					callIds: callList,
					modelName: modelName,
					version: versionId,
					comparison_version_id: versionToCompare || '',
				}
			);
			setLoading(false);
			const summaryList = await callProtoData.json();
			if (callProtoData?.status === 500) {
				setGetGptOutput(false);
				alert(summaryList?.message);
				return;
			}
			let summaryObj = {};
			for (var callId of Object.keys(summaryList)) {
				summaryObj[callId] = summaryList[callId];
			}
			setCallSummariesObj(summaryObj);
			setGetGptOutput(false);
		})();
	}, [getGptOutput]);

	const SummaryComponent = ({ callId }) => {
		return (
			<Accordion>
				<AccordionSummary expandIcon={<GridExpandMoreIcon />}>
					<Typography>
						<strong>Insight from Call Id:</strong> {callId}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Tabs value={0}>
								<Tab label={`Version: ${versionId}`} id={0} />
							</Tabs>
							<TabPanel
								style={{
									height: '600px',
									overflowWrap: 'break-word', // This should work for breaking long words
									wordWrap: 'break-word', // Ensure compatibility
									overflow: 'auto',
								}}
								value={0}
								index={0}
							>
								<ObjectTreeView
									obj={
										callSummariesObj?.[callId]?.[
											versionId
										] || {}
									}
									name={`Version: ${versionId}`}
								/>
							</TabPanel>
						</Grid>
						<Grid item xs={6}>
							<Tabs value={0}>
								<Tab
									label={`Version: ${versionToCompare}`}
									id={0}
								/>
							</Tabs>
							<TabPanel
								style={{
									height: '600px',
									overflowWrap: 'break-word', // This should work for breaking long words
									wordWrap: 'break-word', // Ensure compatibility
									overflow: 'auto',
								}}
								value={0}
								index={0}
							>
								<ObjectTreeView
									obj={
										versionToCompare
											? callSummariesObj?.[callId]?.[
													versionToCompare
											  ] || {}
											: {}
									}
									name={`Version: ${versionToCompare}`}
								/>
							</TabPanel>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		);
	};

	function renderSummaryList() {
		const callIds = Object.keys(callSummariesObj);
		if (callIds.length == 0) {
			return;
		}
		const summaryComponents = [];
		for (let i = 0; i < callIds.length; i++) {
			const callId = callIds[i];
			summaryComponents.push(
				<SummaryComponent
					style={{ marginBottom: '0.7rem' }}
					key={callId}
					callId={callId}
				/>
			);
		}
		return summaryComponents;
	}

	return (
		<div style={{ paddingRight: '20px', width: '1300px' }}>
			<Backdrop open={loading} style={{ zIndex: '5000' }}>
				<CircularProgress />
			</Backdrop>
			<FormGroup>
				<TextField
					style={{ marginBottom: '1rem' }}
					value={callIdsStr}
					onChange={(e) => setCallIdsStr(e.target.value)}
					label="Call ids"
					multiline
				/>
				{formElement(
					select(modelName, setModelName, modelList),
					'Model Name'
				)}
				{formElement(
					select(versionId, setVersionId, versionIdList),
					'Version Id'
				)}
				{formElement(
					select(
						versionToCompare,
						setVersionToCompare,
						versionIdList
					),
					'Comparison Version Id (Optional)'
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Button
						style={{ width: '100%', marginBottom: '0.7rem' }}
						variant="contained"
						color="primary"
						onClick={() => setGetGptOutput(true)}
						startIcon={<ApiIcon />}
					>
						Generate Insights
					</Button>
				</div>
				<div>{renderSummaryList()}</div>
			</FormGroup>
		</div>
	);
}
