import React, {Component} from 'react';
import { Button,CircularProgress, } from '@mui/material';
import {getConfig} from './config';
import { getJsonData } from './network';
import moment from "moment"

class CustomerDetail extends Component {

  constructor() {
    super();
    this.config = getConfig();
    this.disableRecording = this.disableRecording.bind(this);
    this.enableRecording = this.enableRecording.bind(this);
    this.getBillingLogs = this.getBillingLogs.bind(this);
    this.state ={
      loading :false,
      showBillingLogs : false,
      logs: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customer !== this.props.customer) {
      this.setState({
        showBillingLogs : false ,  loading :false,logs: null
      });
    }
  }



  renderUser(user) {
    const recordingSince = user.became_recording_latest ? <small>since: {user.became_recording_latest}</small> : null;
    return (<li key={user.email_id}>
      {user.email_id}
      {recordingSince && <br/>}
      {recordingSince}
    </li>);
  }

  renderUsers(customer) {
    if (!customer.recording_users) return 'No recording users';
    customer.recording_users.sort((a, b) => {
      const ad = a.became_recording_latest;
      const bd = b.became_recording_latest;
      if (ad && !bd) return -1;
      if (bd && !ad) return 1;
      if (ad === bd) return 0;
      return ad < bd ? 1 : -1;
    });
    return (<ul>
        {customer.recording_users.map(this.renderUser)}
    </ul>);
  }

  renderLog(log) {
    let updatedBy = ""
    if(log.actionHappendThroughSso) {
       updatedBy = " Provisioning "
    }
    else {
      updatedBy = (log.performedBy.name || log.performedBy.emailId);
    }
    const updatedUser = log.updatedUser.name || log.updatedUser.emailId;
    const updatedTime = log.updatedTime;
    const oldLicensesCount = log.licenseFrom;
    const newLicensesCount = log.licenseTo

  if(log.actionType === 'CRON_UPDATE') { 
    return(
      <>
      <li>
        cron updated licenses from {oldLicensesCount} to {newLicensesCount} on {moment(updatedTime).format('DD MMM, YYYY')}.
        <br/>
      </li>
      <br/>
      </>
    )
  }

  if(log.actionType === 'INVITED_RECORDING_USER') {
    return(
      <>
      <li>
        {updatedBy} invited a new recording user, {updatedUser} on {moment(updatedTime).format('DD MMM, YYYY')}.
      </li>
      <br/>
      </>
    )
  }

  if(log.actionType === 'MAKE_RECORDER') {
    return(
      <>
      <li>
        {updatedBy} converted, {updatedUser} to a recording user on {moment(updatedTime).format('DD MMM, YYYY')}.
      </li>
      <br/>
      </>
    )
  }

  if(log.actionType === "BECAME_RECORDER_IN_SELF_SIGN_UP") {
    return(
      <>
      <li>
        {updatedBy} chose a recording role during self signup on  {moment(updatedTime).format('DD MMM, YYYY')}.
      </li>
      <br/>
      </>
    )
  }

  if(log.actionType === 'DELETE_RECORDER') {
    return(
      <>
      <li>
        {updatedBy} deleted a recording user, {updatedUser} on {moment(updatedTime).format('DD MMM, YYYY')}.
      </li>
      <br/>
      </>
    )
  }

  if(log.actionType === 'MAKE_NON_RECORDER') {
    return(
      <>
      <li>
        {updatedBy} removed recording access from {updatedUser} on {moment(updatedTime).format('DD MMM, YYYY')}.
      </li>
      <br/>
      </>
    )
  }

  }

  renderBillingLogs() {
    const logsLocal = this.state.logs;
    if(logsLocal.length === 0) return "No logs available for this customer."
    return(
      <ul>
        {logsLocal.map(this.renderLog)}
      </ul>
    )
  }

  unbillied(customer) {
    const recording_users = customer && customer.recording_users ? customer.recording_users.length : 0;
    const total_billed = customer.total_billed ? customer.total_billed : 0;
    return recording_users - total_billed;
  }
 
async getBillingLogs(id) {
  if(this.state.showBillingLogs) {
    this.setState({...this.state, showBillingLogs : false, logs:null})
    return;
  }
  this.setState({loading:true})
  const response = await getJsonData('/billing/get-logs/' +id);
  this.setState({...this.state,logs:response , loading : false, showBillingLogs : true})

  
}

  updateRecording(value) {
    getJsonData('/billing/' + this.props.customer._id + '/enable_wingman_access?action=recording&value=' + value).then(res => {
      console.log('updated recording', res);
  }).catch(console.error);
    this.props.updateRecordingCb(value === 'false');
  }

  disableRecording() {
    const name = prompt('Enter name of customer to confirm disabling');
    if (name.toLowerCase() !== this.props.customer.name.toLowerCase()) {
      alert('You were trying to disble recording for "' + this.props.customer.name + '" but you entered "' + name + '", so ignoring request');
      return;
    }
    this.updateRecording('false');
  }

  enableRecording() {
    this.updateRecording('true');
  }

  renderDetails(customer) {
    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            Name: {customer.name}<br/>
            Billed: {customer.total_billed}<br/>
            Unbilled: {this.unbillied(customer)}<br/>
            Recording: {customer.recording_disabled ? "Disabled" : "Enabled"}<br/>
            {!customer.recording_disabled && <Button variant="contained" color="secondary" size="small" style={{margin: "5px"}} onClick={this.disableRecording}>Disable recording</Button>}
            {customer.recording_disabled && <Button variant="contained" color="primary" size="small" style={{margin: "5px"}} onClick={this.enableRecording}>Enable recording</Button>}
           
            <Button variant="contained" color={ "primary"} size="small" style={{margin: "5px"}} onClick={() => this.getBillingLogs(customer._id)}>
              {this.state.loading && 
              <CircularProgress color="inherit" sx={{ mr: 1 }} size={20}/>
              }
              {this.state.showBillingLogs ? "Hide " : "Show " } billing logs </Button>
              <br/>
              Logs are available only after 10-Dec-2022
              <br/>
              {this?.state?.logs  &&
              <div>
              {this.renderBillingLogs()}
              </div>
              }
            <br/>
            Recording users:<br/>
            {this.renderUsers(customer)}
        </div>
    )
  }

  render() {
    if (!this.props.customer) return <div>Select a customer</div>;
    return this.renderDetails(this.props.customer);
  }
};

export default CustomerDetail;

