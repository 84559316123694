import { Box, Input, InputLabel, IconButton, FormControl, CircularProgress, MobileStepper, Button } from "@mui/material";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import React, { useState, useEffect } from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { getJsonData } from './network';

export default function DebugImages(props) {
  const [imgIndex, setImgIndex] = useState(0);
  const [callId, setCallId] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hash = window.location.hash.length > 0 ? window.location.hash.substr(1) : null;
    if (!hash || !hash.startsWith('debug_images')) return;
    const passedId = hash.substr('debug_images'.length + 1);
    setCallId(passedId);
    getImages(passedId);
  }, []);

  function getImages(passedId=null) {
    if (!passedId) passedId = callId;
    setLoading(true);
    getJsonData('/debug_call/' + passedId + '/debug_images')
      .then(resp => {
        if (!Array.isArray(resp)) {
          alert(resp);
          return;
        }
        resp.forEach(img => img.createdDt = new Date(img.updated));
        setImages(resp);
        setImgIndex(resp.length - 1);
        window.location.hash = 'debug_images/' + callId;
      })
      .catch(e => alert('Error occured while fetching images'));
  }

  function prevImage() {
    if (imgIndex === 0) return;
    setLoading(true);
    setImgIndex(imgIndex - 1);
  }

  function nextImage() {
    if (imgIndex === images.length - 1) return;
    setLoading(true);
    setImgIndex(imgIndex + 1);
  }

  function padded(i) {
    return (i < 10 ? '0' : '') + i;
  }

  function timeFromStart(dt) {
    if (images.length === 0) return '';
    const s = Math.floor((dt - images[0].createdDt) / 1000);
    const ss = padded(s % 60);
    const m = Math.floor(s / 60);
    const mm = padded(m % 60);
    const h = Math.floor(m / 60);
    return ' since start: ' + (h > 0 ? h + ':' : '') + (m > 0 ? mm + ':' : '') + ss;
  }

  function getImageScrollStatus() {
    if (imgIndex >= images.length || imgIndex < 0) return '';
    const dt = images[imgIndex].createdDt;
    return "Taken: " + (dt ? dt.toLocaleString() : "null") + " " + timeFromStart(dt);
  }

  function linkToHTML() {
    if (!images || !images[imgIndex] || !images[imgIndex].link) return null;
    const link = images[imgIndex].link;
    const exts = ['.png', '.jpeg'];
    if (!exts.some(ext => link.endsWith(ext))) return null;
    const htmlLink = link.substr(0, link.lastIndexOf('.')) + '.html';
    return <a target="_blank" href={htmlLink}>HTML link</a>
  }

  function basename() {
    if (!images || !images[imgIndex] || !images[imgIndex].link) return null;
    const link = images[imgIndex].link;
    const i = link.lastIndexOf('/');
    return i !== -1 ? link.substr(i + 1) : link;
  }

  function callImages() {
    if (!images || images.length === 0) return "No images to show";
    return (<div>
      <div>
        <a target="_blank" href={`https://s3.console.aws.amazon.com/s3/buckets/strings-data/debug/?region=us-east-1&prefixSearch=${callId}`}>S3 Link</a>&nbsp;
        {linkToHTML()}<br/>
        {getImageScrollStatus()}<br/>
        {basename()}
      </div>
      <div style={{width: '600px', height: '400px'}}>
        {loading && <div style={{width: '0px', height: '0px'}}>
          <CircularProgress style={{position: 'relative', left: '280px', top: '180px'}} />
        </div>}
        <img style={{maxWidth: "600px", maxHeight: "400px"}} onLoad={e => setLoading(false)} src={images && images[imgIndex] && images[imgIndex].link} />
      </div>
      <div>
        <MobileStepper
          variant="dots"
          steps={images.length}
          position="static"
          activeStep={imgIndex}
          nextButton={
            <Button size="small" onClick={nextImage} disabled={imgIndex === images.length - 1}>
              Next <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={prevImage} disabled={imgIndex === 0}>
              <KeyboardArrowLeft /> Back
            </Button>
          }
        />
      </div>
    </div>);
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  function handleKeyDown(e) {
    if (e.keyCode === 13) getImages();
    else if (e.keyCode === 37) prevImage();
    else if (e.keyCode === 39) nextImage();
    else return;
    e.preventDefault();
  }

  return (<div>
    <Box style={{margin: '15px'}}>
      <FormControl style={{width: "350px"}}>
        <InputLabel>Call id</InputLabel>
        <Input autoFocus value={callId} onChange={e => setCallId(e.target.value)} />
      </FormControl>
      <IconButton onClick={() => getImages()}>
      <PhotoLibraryIcon />
      </IconButton>
    </Box>
    {callImages()}
  </div>)
}