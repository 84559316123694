import React, { useState } from "react";
import { Modal, Box, Button,  Paper,  } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {postWithoutParsingBody} from '../network';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const useStyles = makeStyles(() => ({
  alignSpaceBetween:{
    display: "flex",
    justifyContent: "space-between",
  },
}));

function CustomToolbar() {
  return (
      <GridToolbarContainer>
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
  );
}

function DataTableAndExport({ usersResponse, columns, pageSize, goBackOption }) {
  const classes = useStyles();
  
    return( 
    <>
      <div className={classes.alignSpaceBetween}>
      <h4>Create User Status</h4>
      <Button color="primary" onClick={goBackOption}> Go back </Button>
      </div>
       <Paper style={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={usersResponse}
            columns={columns}
            components={{
                Toolbar: CustomToolbar
            }}
            pageSize={pageSize}
            density="compact"
          />
       </Paper>
       <br />
       </>
      );
  }

export default DataTableAndExport;
