import React, { useState, useEffect } from 'react';
import { Paper, Modal, TextField, FormGroup, Select, FormControl, InputLabel, MenuItem, FormControlLabel, Switch, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import { makeStyles } from '@mui/styles';
import { postJson } from '../network';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: theme.spacing(2, 4, 3),
        width: '80%',
        maxWidth: '500px',
    },
    textField: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    }
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

export default function BulkRoleChange(props) {
    const nonRbacRoles = [
        { value: "REP", label: "Rep" },
        { value: "MANAGER", label: "Manager" },
        { value: "OBSERVER", label: "Observer" },
      ];
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState("");
    const [role, setRole] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [roleUpdateStatus, setRoleUpdateStatus] = useState([]);
    const [roles, setRoles] = useState(nonRbacRoles);

    const columns = [
        {
            headerName: 'Email',
            field: 'email',
            flex: 2,
        },
        {
            headerName: 'Status',
            field: 'status',
            flex: 1,
        },
        {
            headerName: 'Message',
            field: 'message',
            flex: 3,
        },
    ];

    const [showFormFields, setShowFormFields] = useState({
        roleField: false,
        recordingField: false,
        adminField: false,
    });

    useEffect(() => {
       
        if (role !== "MANAGER") {
            setShowFormFields((prevState) => ({
                ...prevState,
                recordingField: false,
            }));
        }
    }, [role]);

    useEffect(() => {
     if(props.isRbacEnabled) {
        const allRoles = props.allRolesAvailableForCustomer;
        let menuItems = [];
         allRoles.forEach(role => menuItems.push({value:role.id , label : role.name}));
        setRoles(menuItems);
        setShowFormFields({...showFormFields, roleField: true});
     }
    }, [props.isRbacEnabled]);

    const toggleRoleChange = (e) => {
        const fieldName = e.target.name;
        let isRecording;
        if(fieldName === "roleField") {
            isRecording = false;
            setRole("")
        }else if(fieldName === "recordingField") {
            isRecording = !showFormFields["recordingField"] ;
        }
        else {
            isRecording = showFormFields["recordingField"]
        }

        setShowFormFields((prevState) => ({
            ...prevState,
            [fieldName]: !prevState[fieldName],
            "recordingField" : isRecording
        }));
    };

    const transformEmails = (stringOfEmails) => {
        if (stringOfEmails.trim().length === 0) return "";

        const emails = stringOfEmails.trim().split(/[,\s]+/);
        const cleanedEmails = emails.filter(email => email.length > 0);
        const uniqueEmails = [...new Set(cleanedEmails)];
        return uniqueEmails.join(',');
    }

    const getButtonDisabledStatus = () => {
        if (loading) return true;
        if (emails.trim().length === 0) return true;
        if (Object.values(showFormFields).filter(Boolean).length === 0) return true;
        return false;
    }

    const clear = () => {
        setEmails("");
        setRole("");
        setIsRecording(false);
        setIsAdmin(false);
        setShowFormFields({
            roleField: props.isRbacEnabled ? true : false,
            recordingField: false,
            adminField: false,
        });
    }

    const submit = async (e) => {
        e.preventDefault();
        const isRbacEnabled = props.isRbacEnabled;
        const data = {
            customerId: props.customerId,
            emails: transformEmails(emails),
        }; 
        
        if(isRbacEnabled) {
            data["roleId"] = role;
        }
        if(  !isRbacEnabled &&  showFormFields["roleField"]) {
            data["role"] = role ;
        }
        if( showFormFields["recordingField"]) {
            data["isRecording"] = isRecording;
        }
        if( showFormFields["adminField"]) {
            data["isAdmin"] = isAdmin;
        } 
        try {
            setLoading(true);
            setRoleUpdateStatus([]);
            const result = await postJson('/customers/update-role/', data);
            const transformedResult = (await result.json()).map((item, index) => ({
                ...item,
                id: index + 1,
            })) ?? [];
            setRoleUpdateStatus(transformedResult);
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }

    return (
        <Modal open={props.open} onClose={props.onClose} className={classes.modal}>
            <div className={classes.paper}>
                <h2>Bulk Role/Permission Change</h2>
                <p>
                    This will change the role/permission of all users in the list to the selected role.
                </p>

                {
                    roleUpdateStatus.length > 0 ? (
                        <div>
                            <h4>Role Update Status</h4>
                            <Paper style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={roleUpdateStatus}
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar
                                    }}
                                    pageSize={15}
                                    density="compact"
                                />
                            </Paper>
                            <br />
                            <Button style={{ float: "right" }} variant="contained" color="primary" onClick={() => setRoleUpdateStatus([])}> Go back </Button>
                        </div>
                    ) : (
                        <div>
                            <TextField
                                className={classes.textarea}
                                label="List of emails"
                                value={emails}
                                onChange={(e) => setEmails(e.target.value)}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                multiline
                                placeholder="Enter a list of emails separated by commas or spaces (eg. user1@domain.com, user2@domain.com)"
                                minRows={5}
                                maxRows={10}
                            />

                            <div>Filters:</div>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showFormFields["roleField"]} onChange={toggleRoleChange} name="roleField" color="primary"  disabled={props.isRbacEnabled}/>
                                    }
                                    label="Change Role"
                                />
                                {!props.isRbacEnabled && 
                                <>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showFormFields["recordingField"]} onChange={toggleRoleChange} name="recordingField" color="primary" />
                                    }
                                    label="Edit Recording"
                                    disabled={role !== "MANAGER"}
                                />

                                <FormControlLabel
                                    control={
                                        <Switch checked={showFormFields["adminField"]} onChange={toggleRoleChange} name="adminField" color="primary" />
                                    }
                                    label="Edit Admin"
                                />
                           
                            </>
                }
                 </FormGroup>

                            <FormControl variant="outlined" className={classes.textField} fullWidth style={{ display: showFormFields["roleField"] ? "block" : "none" }}>
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select onChange={e => setRole(e.target.value)} value={role} name="role" label="Role" fullWidth>
                                    {roles.map((item, i) => <MenuItem key={i} value={item.value}>{item.label}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" className={classes.textField} fullWidth style={{ display: showFormFields["recordingField"] ? "block" : "none" }}>
                                <InputLabel id="recording-select-label">isRecording</InputLabel>
                                <Select onChange={e => setIsRecording(e.target.value)} value={isRecording} name="role" label="Role" fullWidth>
                                    <MenuItem value={true}>{"true"}</MenuItem>
                                    <MenuItem value={false}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" className={classes.textField} fullWidth style={{ display: showFormFields["adminField"] ? "block" : "none" }}>
                                <InputLabel id="admin-select-label">isAdmin</InputLabel>
                                <Select onChange={e => setIsAdmin(e.target.value)} value={isAdmin} name="role" label="Role" fullWidth>
                                    <MenuItem value={true}>{"true"}</MenuItem>
                                    <MenuItem value={false}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>

                            <br />

                            <div style={{ float: "right" }}>
                                <Button variant="contained" color="inherit" style={{
                                    backgroundColor: "#dedede",
                                    marginRight: "10px"
                                }} onClick={clear}> Clear </Button>
                                <Button
                                    variant="contained"
                                    disabled={getButtonDisabledStatus()}
                                    startIcon={loading ? <CircularProgress size={20} color="primary" /> : null}
                                    onClick={submit}> Submit </Button>
                            </div>
                        </div>
                    )
                }
            </div>
        </Modal>
    )
}