import React from "react";
import { makeStyles } from "@mui/styles";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "grid",
    margin: "25px 0px",
  },
  samePostionStyle: {
    gridColumn: "1",
    gridRow: "1",
    zIndex: 0,
  },
  inputUpload: {
    opacity: 0,
    filter: "alpha(opacity: 0)",
    zIndex: 10,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  uploadUIFile: {
    width: "70%",
    margin: "auto",
    height: "8rem",
  },
  mockUploadUI: {
    background: "#FAFAFA",
    border: "1px dashed #B3B3B3",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  }
}));

function UploadFileUI({ csvFile, setFile, type }) {
  const classes = useStyles();
  return (
    <div className={`${classes.inputContainer}`}>
      <div
        className={`${classes.samePostionStyle} ${classes.inputUpload} ${classes.uploadUIFile}`}
      >
        <input
          type="file"
          className={`${classes.inputUpload}`}
          name="myfile"
          onChange={setFile}
          accept={type}
        />
      </div>
      <div
        className={`${classes.samePostionStyle} ${classes.mockUploadUI} ${classes.uploadUIFile} `}
      >
        <DriveFolderUploadIcon />
        <div>Browse files</div>
        <span>{csvFile?.name ?? "No File Choosen"}</span>
      </div>
    </div>
  );
}
export default UploadFileUI;
