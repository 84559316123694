// source: service/generated/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.ActionItem', null, global);
goog.exportSymbol('proto.ActionItem.ActionItemType', null, global);
goog.exportSymbol('proto.AudioSegment', null, global);
goog.exportSymbol('proto.CallData', null, global);
goog.exportSymbol('proto.CallDisposition', null, global);
goog.exportSymbol('proto.CallMetadata', null, global);
goog.exportSymbol('proto.CallProcessorType', null, global);
goog.exportSymbol('proto.CallStatus', null, global);
goog.exportSymbol('proto.CallType', null, global);
goog.exportSymbol('proto.ChannelData', null, global);
goog.exportSymbol('proto.ConversationTurn', null, global);
goog.exportSymbol('proto.DiarizationEngine', null, global);
goog.exportSymbol('proto.DiarizationResponse', null, global);
goog.exportSymbol('proto.DiarizationResponse.DiarizationTurn', null, global);
goog.exportSymbol('proto.DiarizationResponse.EngineToStringsId', null, global);
goog.exportSymbol('proto.DiarizationStatus', null, global);
goog.exportSymbol('proto.DiarizeAudioRequest', null, global);
goog.exportSymbol('proto.DiarizeAudioResponse', null, global);
goog.exportSymbol('proto.DiarizeStage', null, global);
goog.exportSymbol('proto.LanguageCode', null, global);
goog.exportSymbol('proto.ModelPrediction', null, global);
goog.exportSymbol('proto.ModelPrediction.AttentionWeight', null, global);
goog.exportSymbol('proto.ModelPrediction.ClassificationPrediction', null, global);
goog.exportSymbol('proto.ModelPrediction.Metadata', null, global);
goog.exportSymbol('proto.ModelPrediction.ModelType', null, global);
goog.exportSymbol('proto.ModelPrediction.PredCase', null, global);
goog.exportSymbol('proto.ModelPrediction.SentenceSimilarityPrediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.PredCase', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.PredictionType', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight', null, global);
goog.exportSymbol('proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction', null, global);
goog.exportSymbol('proto.NamedEntity', null, global);
goog.exportSymbol('proto.PartialCallStatus', null, global);
goog.exportSymbol('proto.PredictionStatus', null, global);
goog.exportSymbol('proto.ProcessAudioStage', null, global);
goog.exportSymbol('proto.ProcessTranscriptRequest', null, global);
goog.exportSymbol('proto.ProcessTranscriptResponse', null, global);
goog.exportSymbol('proto.QueueType', null, global);
goog.exportSymbol('proto.Sentence', null, global);
goog.exportSymbol('proto.Sentence.Annotation', null, global);
goog.exportSymbol('proto.SentenceMarker', null, global);
goog.exportSymbol('proto.SentimentData', null, global);
goog.exportSymbol('proto.SentimentData.Analyzer', null, global);
goog.exportSymbol('proto.SentimentData.SentimentType', null, global);
goog.exportSymbol('proto.StringsQueueTask', null, global);
goog.exportSymbol('proto.SummaryData', null, global);
goog.exportSymbol('proto.TranscribeAudioRequest', null, global);
goog.exportSymbol('proto.TranscribeAudioResponse', null, global);
goog.exportSymbol('proto.TranscribeEngine', null, global);
goog.exportSymbol('proto.TranscribeResponse', null, global);
goog.exportSymbol('proto.TranscribeResponse.TranscribeTurn', null, global);
goog.exportSymbol('proto.TranscribeResponse.WordInfo', null, global);
goog.exportSymbol('proto.TranscriptionStatus', null, global);
goog.exportSymbol('proto.TranscriptionType', null, global);
goog.exportSymbol('proto.Word', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TranscribeAudioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TranscribeAudioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TranscribeAudioRequest.displayName = 'proto.TranscribeAudioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TranscribeAudioResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TranscribeAudioResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TranscribeAudioResponse.displayName = 'proto.TranscribeAudioResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DiarizeAudioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DiarizeAudioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DiarizeAudioRequest.displayName = 'proto.DiarizeAudioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DiarizeAudioResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DiarizeAudioResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DiarizeAudioResponse.displayName = 'proto.DiarizeAudioResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProcessTranscriptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProcessTranscriptRequest.repeatedFields_, null);
};
goog.inherits(proto.ProcessTranscriptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProcessTranscriptRequest.displayName = 'proto.ProcessTranscriptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProcessTranscriptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProcessTranscriptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProcessTranscriptResponse.displayName = 'proto.ProcessTranscriptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ModelPrediction.repeatedFields_, proto.ModelPrediction.oneofGroups_);
};
goog.inherits(proto.ModelPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelPrediction.displayName = 'proto.ModelPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelPrediction.AttentionWeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelPrediction.AttentionWeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelPrediction.AttentionWeight.displayName = 'proto.ModelPrediction.AttentionWeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelPrediction.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelPrediction.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelPrediction.Metadata.displayName = 'proto.ModelPrediction.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelPrediction.ClassificationPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelPrediction.ClassificationPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelPrediction.ClassificationPrediction.displayName = 'proto.ModelPrediction.ClassificationPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelPrediction.SentenceSimilarityPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelPrediction.SentenceSimilarityPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelPrediction.SentenceSimilarityPrediction.displayName = 'proto.ModelPrediction.SentenceSimilarityPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ModelWorkflowOutput.repeatedFields_, null);
};
goog.inherits(proto.ModelWorkflowOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.displayName = 'proto.ModelWorkflowOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ModelWorkflowOutput.Prediction.repeatedFields_, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.displayName = 'proto.ModelWorkflowOutput.Prediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ModelWorkflowOutput.Prediction.SubPrediction.repeatedFields_, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.repeatedFields_, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.displayName = 'proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Word = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Word.repeatedFields_, null);
};
goog.inherits(proto.Word, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Word.displayName = 'proto.Word';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ConversationTurn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ConversationTurn.repeatedFields_, null);
};
goog.inherits(proto.ConversationTurn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ConversationTurn.displayName = 'proto.ConversationTurn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SentenceMarker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SentenceMarker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SentenceMarker.displayName = 'proto.SentenceMarker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChannelData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ChannelData.repeatedFields_, null);
};
goog.inherits(proto.ChannelData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChannelData.displayName = 'proto.ChannelData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CallMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CallMetadata.repeatedFields_, null);
};
goog.inherits(proto.CallMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CallMetadata.displayName = 'proto.CallMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TranscribeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TranscribeResponse.repeatedFields_, null);
};
goog.inherits(proto.TranscribeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TranscribeResponse.displayName = 'proto.TranscribeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TranscribeResponse.WordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TranscribeResponse.WordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TranscribeResponse.WordInfo.displayName = 'proto.TranscribeResponse.WordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TranscribeResponse.TranscribeTurn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TranscribeResponse.TranscribeTurn.repeatedFields_, null);
};
goog.inherits(proto.TranscribeResponse.TranscribeTurn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TranscribeResponse.TranscribeTurn.displayName = 'proto.TranscribeResponse.TranscribeTurn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DiarizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DiarizationResponse.repeatedFields_, null);
};
goog.inherits(proto.DiarizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DiarizationResponse.displayName = 'proto.DiarizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DiarizationResponse.DiarizationTurn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DiarizationResponse.DiarizationTurn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DiarizationResponse.DiarizationTurn.displayName = 'proto.DiarizationResponse.DiarizationTurn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DiarizationResponse.EngineToStringsId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DiarizationResponse.EngineToStringsId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DiarizationResponse.EngineToStringsId.displayName = 'proto.DiarizationResponse.EngineToStringsId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CallData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CallData.repeatedFields_, null);
};
goog.inherits(proto.CallData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CallData.displayName = 'proto.CallData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StringsQueueTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StringsQueueTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StringsQueueTask.displayName = 'proto.StringsQueueTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ActionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActionItem.displayName = 'proto.ActionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SummaryData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SummaryData.repeatedFields_, null);
};
goog.inherits(proto.SummaryData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SummaryData.displayName = 'proto.SummaryData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NamedEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NamedEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NamedEntity.displayName = 'proto.NamedEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SentimentData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SentimentData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SentimentData.displayName = 'proto.SentimentData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AudioSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AudioSegment.repeatedFields_, null);
};
goog.inherits(proto.AudioSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AudioSegment.displayName = 'proto.AudioSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Sentence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Sentence.repeatedFields_, null);
};
goog.inherits(proto.Sentence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Sentence.displayName = 'proto.Sentence';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TranscribeAudioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TranscribeAudioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TranscribeAudioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeAudioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.CallData.toObject(includeInstance, f),
    languageCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transcribeEngine: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TranscribeAudioRequest}
 */
proto.TranscribeAudioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TranscribeAudioRequest;
  return proto.TranscribeAudioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TranscribeAudioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TranscribeAudioRequest}
 */
proto.TranscribeAudioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CallData;
      reader.readMessage(value,proto.CallData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 3:
      var value = /** @type {!proto.TranscribeEngine} */ (reader.readEnum());
      msg.setTranscribeEngine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TranscribeAudioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TranscribeAudioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TranscribeAudioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeAudioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CallData.serializeBinaryToWriter
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTranscribeEngine();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional CallData data = 1;
 * @return {?proto.CallData}
 */
proto.TranscribeAudioRequest.prototype.getData = function() {
  return /** @type{?proto.CallData} */ (
    jspb.Message.getWrapperField(this, proto.CallData, 1));
};


/**
 * @param {?proto.CallData|undefined} value
 * @return {!proto.TranscribeAudioRequest} returns this
*/
proto.TranscribeAudioRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TranscribeAudioRequest} returns this
 */
proto.TranscribeAudioRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TranscribeAudioRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string language_code = 2;
 * @return {string}
 */
proto.TranscribeAudioRequest.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TranscribeAudioRequest} returns this
 */
proto.TranscribeAudioRequest.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TranscribeEngine transcribe_engine = 3;
 * @return {!proto.TranscribeEngine}
 */
proto.TranscribeAudioRequest.prototype.getTranscribeEngine = function() {
  return /** @type {!proto.TranscribeEngine} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.TranscribeEngine} value
 * @return {!proto.TranscribeAudioRequest} returns this
 */
proto.TranscribeAudioRequest.prototype.setTranscribeEngine = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TranscribeAudioResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TranscribeAudioResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TranscribeAudioResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeAudioResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.CallData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TranscribeAudioResponse}
 */
proto.TranscribeAudioResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TranscribeAudioResponse;
  return proto.TranscribeAudioResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TranscribeAudioResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TranscribeAudioResponse}
 */
proto.TranscribeAudioResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CallData;
      reader.readMessage(value,proto.CallData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TranscribeAudioResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TranscribeAudioResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TranscribeAudioResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeAudioResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CallData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CallData data = 1;
 * @return {?proto.CallData}
 */
proto.TranscribeAudioResponse.prototype.getData = function() {
  return /** @type{?proto.CallData} */ (
    jspb.Message.getWrapperField(this, proto.CallData, 1));
};


/**
 * @param {?proto.CallData|undefined} value
 * @return {!proto.TranscribeAudioResponse} returns this
*/
proto.TranscribeAudioResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TranscribeAudioResponse} returns this
 */
proto.TranscribeAudioResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TranscribeAudioResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DiarizeAudioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DiarizeAudioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DiarizeAudioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizeAudioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.CallData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DiarizeAudioRequest}
 */
proto.DiarizeAudioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DiarizeAudioRequest;
  return proto.DiarizeAudioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DiarizeAudioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DiarizeAudioRequest}
 */
proto.DiarizeAudioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CallData;
      reader.readMessage(value,proto.CallData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DiarizeAudioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DiarizeAudioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DiarizeAudioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizeAudioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CallData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CallData data = 1;
 * @return {?proto.CallData}
 */
proto.DiarizeAudioRequest.prototype.getData = function() {
  return /** @type{?proto.CallData} */ (
    jspb.Message.getWrapperField(this, proto.CallData, 1));
};


/**
 * @param {?proto.CallData|undefined} value
 * @return {!proto.DiarizeAudioRequest} returns this
*/
proto.DiarizeAudioRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DiarizeAudioRequest} returns this
 */
proto.DiarizeAudioRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DiarizeAudioRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DiarizeAudioResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DiarizeAudioResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DiarizeAudioResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizeAudioResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    callId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DiarizeAudioResponse}
 */
proto.DiarizeAudioResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DiarizeAudioResponse;
  return proto.DiarizeAudioResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DiarizeAudioResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DiarizeAudioResponse}
 */
proto.DiarizeAudioResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DiarizeAudioResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DiarizeAudioResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DiarizeAudioResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizeAudioResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string call_id = 1;
 * @return {string}
 */
proto.DiarizeAudioResponse.prototype.getCallId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DiarizeAudioResponse} returns this
 */
proto.DiarizeAudioResponse.prototype.setCallId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProcessTranscriptRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProcessTranscriptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ProcessTranscriptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProcessTranscriptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessTranscriptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.CallData.toObject(includeInstance, f),
    analyzerList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    diarizationEngine: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProcessTranscriptRequest}
 */
proto.ProcessTranscriptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProcessTranscriptRequest;
  return proto.ProcessTranscriptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProcessTranscriptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProcessTranscriptRequest}
 */
proto.ProcessTranscriptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CallData;
      reader.readMessage(value,proto.CallData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.SentimentData.Analyzer>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAnalyzer(values[i]);
      }
      break;
    case 3:
      var value = /** @type {!proto.DiarizationEngine} */ (reader.readEnum());
      msg.setDiarizationEngine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProcessTranscriptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProcessTranscriptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProcessTranscriptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessTranscriptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CallData.serializeBinaryToWriter
    );
  }
  f = message.getAnalyzerList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getDiarizationEngine();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional CallData data = 1;
 * @return {?proto.CallData}
 */
proto.ProcessTranscriptRequest.prototype.getData = function() {
  return /** @type{?proto.CallData} */ (
    jspb.Message.getWrapperField(this, proto.CallData, 1));
};


/**
 * @param {?proto.CallData|undefined} value
 * @return {!proto.ProcessTranscriptRequest} returns this
*/
proto.ProcessTranscriptRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProcessTranscriptRequest} returns this
 */
proto.ProcessTranscriptRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessTranscriptRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SentimentData.Analyzer analyzer = 2;
 * @return {!Array<!proto.SentimentData.Analyzer>}
 */
proto.ProcessTranscriptRequest.prototype.getAnalyzerList = function() {
  return /** @type {!Array<!proto.SentimentData.Analyzer>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.SentimentData.Analyzer>} value
 * @return {!proto.ProcessTranscriptRequest} returns this
 */
proto.ProcessTranscriptRequest.prototype.setAnalyzerList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.SentimentData.Analyzer} value
 * @param {number=} opt_index
 * @return {!proto.ProcessTranscriptRequest} returns this
 */
proto.ProcessTranscriptRequest.prototype.addAnalyzer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProcessTranscriptRequest} returns this
 */
proto.ProcessTranscriptRequest.prototype.clearAnalyzerList = function() {
  return this.setAnalyzerList([]);
};


/**
 * optional DiarizationEngine diarization_engine = 3;
 * @return {!proto.DiarizationEngine}
 */
proto.ProcessTranscriptRequest.prototype.getDiarizationEngine = function() {
  return /** @type {!proto.DiarizationEngine} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.DiarizationEngine} value
 * @return {!proto.ProcessTranscriptRequest} returns this
 */
proto.ProcessTranscriptRequest.prototype.setDiarizationEngine = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProcessTranscriptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ProcessTranscriptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProcessTranscriptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessTranscriptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.CallData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProcessTranscriptResponse}
 */
proto.ProcessTranscriptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProcessTranscriptResponse;
  return proto.ProcessTranscriptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProcessTranscriptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProcessTranscriptResponse}
 */
proto.ProcessTranscriptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CallData;
      reader.readMessage(value,proto.CallData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProcessTranscriptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProcessTranscriptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProcessTranscriptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessTranscriptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CallData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CallData data = 1;
 * @return {?proto.CallData}
 */
proto.ProcessTranscriptResponse.prototype.getData = function() {
  return /** @type{?proto.CallData} */ (
    jspb.Message.getWrapperField(this, proto.CallData, 1));
};


/**
 * @param {?proto.CallData|undefined} value
 * @return {!proto.ProcessTranscriptResponse} returns this
*/
proto.ProcessTranscriptResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProcessTranscriptResponse} returns this
 */
proto.ProcessTranscriptResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessTranscriptResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ModelPrediction.repeatedFields_ = [8,5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ModelPrediction.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.ModelPrediction.PredCase = {
  PRED_NOT_SET: 0,
  CLASS_PRED: 6,
  SENT_PRED: 7
};

/**
 * @return {proto.ModelPrediction.PredCase}
 */
proto.ModelPrediction.prototype.getPredCase = function() {
  return /** @type {proto.ModelPrediction.PredCase} */(jspb.Message.computeOneofCase(this, proto.ModelPrediction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && proto.ModelPrediction.Metadata.toObject(includeInstance, f),
    startWordIdx: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endWordIdx: jspb.Message.getFieldWithDefault(msg, 4, 0),
    attentionWeightsList: jspb.Message.toObjectList(msg.getAttentionWeightsList(),
    proto.ModelPrediction.AttentionWeight.toObject, includeInstance),
    predictionList: jspb.Message.toObjectList(msg.getPredictionList(),
    proto.ModelPrediction.toObject, includeInstance),
    classPred: (f = msg.getClassPred()) && proto.ModelPrediction.ClassificationPrediction.toObject(includeInstance, f),
    sentPred: (f = msg.getSentPred()) && proto.ModelPrediction.SentenceSimilarityPrediction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelPrediction}
 */
proto.ModelPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelPrediction;
  return proto.ModelPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelPrediction}
 */
proto.ModelPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ModelPrediction.ModelType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.ModelPrediction.Metadata;
      reader.readMessage(value,proto.ModelPrediction.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartWordIdx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndWordIdx(value);
      break;
    case 8:
      var value = new proto.ModelPrediction.AttentionWeight;
      reader.readMessage(value,proto.ModelPrediction.AttentionWeight.deserializeBinaryFromReader);
      msg.addAttentionWeights(value);
      break;
    case 5:
      var value = new proto.ModelPrediction;
      reader.readMessage(value,proto.ModelPrediction.deserializeBinaryFromReader);
      msg.addPrediction(value);
      break;
    case 6:
      var value = new proto.ModelPrediction.ClassificationPrediction;
      reader.readMessage(value,proto.ModelPrediction.ClassificationPrediction.deserializeBinaryFromReader);
      msg.setClassPred(value);
      break;
    case 7:
      var value = new proto.ModelPrediction.SentenceSimilarityPrediction;
      reader.readMessage(value,proto.ModelPrediction.SentenceSimilarityPrediction.deserializeBinaryFromReader);
      msg.setSentPred(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ModelPrediction.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStartWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEndWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAttentionWeightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ModelPrediction.AttentionWeight.serializeBinaryToWriter
    );
  }
  f = message.getPredictionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ModelPrediction.serializeBinaryToWriter
    );
  }
  f = message.getClassPred();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ModelPrediction.ClassificationPrediction.serializeBinaryToWriter
    );
  }
  f = message.getSentPred();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ModelPrediction.SentenceSimilarityPrediction.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.ModelPrediction.ModelType = {
  UNKNOWN_MODEL_TYPE: 0,
  CLASSIFICATION_MODEL: 1,
  SENTENCE_SIMILARITY: 2,
  SEGMENT: 3,
  TOPIC: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelPrediction.AttentionWeight.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelPrediction.AttentionWeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelPrediction.AttentionWeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.AttentionWeight.toObject = function(includeInstance, msg) {
  var f, obj = {
    wordIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelPrediction.AttentionWeight}
 */
proto.ModelPrediction.AttentionWeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelPrediction.AttentionWeight;
  return proto.ModelPrediction.AttentionWeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelPrediction.AttentionWeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelPrediction.AttentionWeight}
 */
proto.ModelPrediction.AttentionWeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWordIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelPrediction.AttentionWeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelPrediction.AttentionWeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelPrediction.AttentionWeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.AttentionWeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional uint32 word_idx = 1;
 * @return {number}
 */
proto.ModelPrediction.AttentionWeight.prototype.getWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelPrediction.AttentionWeight} returns this
 */
proto.ModelPrediction.AttentionWeight.prototype.setWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float weight = 2;
 * @return {number}
 */
proto.ModelPrediction.AttentionWeight.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelPrediction.AttentionWeight} returns this
 */
proto.ModelPrediction.AttentionWeight.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelPrediction.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelPrediction.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelPrediction.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    predictionType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelVersion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelPrediction.Metadata}
 */
proto.ModelPrediction.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelPrediction.Metadata;
  return proto.ModelPrediction.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelPrediction.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelPrediction.Metadata}
 */
proto.ModelPrediction.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPredictionType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelPrediction.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelPrediction.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelPrediction.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPredictionType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string prediction_type = 1;
 * @return {string}
 */
proto.ModelPrediction.Metadata.prototype.getPredictionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelPrediction.Metadata} returns this
 */
proto.ModelPrediction.Metadata.prototype.setPredictionType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_version = 2;
 * @return {string}
 */
proto.ModelPrediction.Metadata.prototype.getModelVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelPrediction.Metadata} returns this
 */
proto.ModelPrediction.Metadata.prototype.setModelVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelPrediction.ClassificationPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelPrediction.ClassificationPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelPrediction.ClassificationPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.ClassificationPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelPrediction.ClassificationPrediction}
 */
proto.ModelPrediction.ClassificationPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelPrediction.ClassificationPrediction;
  return proto.ModelPrediction.ClassificationPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelPrediction.ClassificationPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelPrediction.ClassificationPrediction}
 */
proto.ModelPrediction.ClassificationPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelPrediction.ClassificationPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelPrediction.ClassificationPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelPrediction.ClassificationPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.ClassificationPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.ModelPrediction.ClassificationPrediction.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelPrediction.ClassificationPrediction} returns this
 */
proto.ModelPrediction.ClassificationPrediction.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float score = 2;
 * @return {number}
 */
proto.ModelPrediction.ClassificationPrediction.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelPrediction.ClassificationPrediction} returns this
 */
proto.ModelPrediction.ClassificationPrediction.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelPrediction.SentenceSimilarityPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelPrediction.SentenceSimilarityPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.SentenceSimilarityPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchedPhraseStr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    matchedPhraseId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelPrediction.SentenceSimilarityPrediction}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelPrediction.SentenceSimilarityPrediction;
  return proto.ModelPrediction.SentenceSimilarityPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelPrediction.SentenceSimilarityPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelPrediction.SentenceSimilarityPrediction}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchedPhraseStr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchedPhraseId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelPrediction.SentenceSimilarityPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelPrediction.SentenceSimilarityPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelPrediction.SentenceSimilarityPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchedPhraseStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMatchedPhraseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string matched_phrase_str = 1;
 * @return {string}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.getMatchedPhraseStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelPrediction.SentenceSimilarityPrediction} returns this
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.setMatchedPhraseStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string matched_phrase_id = 2;
 * @return {string}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.getMatchedPhraseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelPrediction.SentenceSimilarityPrediction} returns this
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.setMatchedPhraseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelPrediction.SentenceSimilarityPrediction} returns this
 */
proto.ModelPrediction.SentenceSimilarityPrediction.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional ModelType type = 1;
 * @return {!proto.ModelPrediction.ModelType}
 */
proto.ModelPrediction.prototype.getType = function() {
  return /** @type {!proto.ModelPrediction.ModelType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ModelPrediction.ModelType} value
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Metadata metadata = 2;
 * @return {?proto.ModelPrediction.Metadata}
 */
proto.ModelPrediction.prototype.getMetadata = function() {
  return /** @type{?proto.ModelPrediction.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.ModelPrediction.Metadata, 2));
};


/**
 * @param {?proto.ModelPrediction.Metadata|undefined} value
 * @return {!proto.ModelPrediction} returns this
*/
proto.ModelPrediction.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelPrediction.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 start_word_idx = 3;
 * @return {number}
 */
proto.ModelPrediction.prototype.getStartWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.setStartWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 end_word_idx = 4;
 * @return {number}
 */
proto.ModelPrediction.prototype.getEndWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.setEndWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated AttentionWeight attention_weights = 8;
 * @return {!Array<!proto.ModelPrediction.AttentionWeight>}
 */
proto.ModelPrediction.prototype.getAttentionWeightsList = function() {
  return /** @type{!Array<!proto.ModelPrediction.AttentionWeight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelPrediction.AttentionWeight, 8));
};


/**
 * @param {!Array<!proto.ModelPrediction.AttentionWeight>} value
 * @return {!proto.ModelPrediction} returns this
*/
proto.ModelPrediction.prototype.setAttentionWeightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ModelPrediction.AttentionWeight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelPrediction.AttentionWeight}
 */
proto.ModelPrediction.prototype.addAttentionWeights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ModelPrediction.AttentionWeight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.clearAttentionWeightsList = function() {
  return this.setAttentionWeightsList([]);
};


/**
 * repeated ModelPrediction prediction = 5;
 * @return {!Array<!proto.ModelPrediction>}
 */
proto.ModelPrediction.prototype.getPredictionList = function() {
  return /** @type{!Array<!proto.ModelPrediction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelPrediction, 5));
};


/**
 * @param {!Array<!proto.ModelPrediction>} value
 * @return {!proto.ModelPrediction} returns this
*/
proto.ModelPrediction.prototype.setPredictionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ModelPrediction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelPrediction}
 */
proto.ModelPrediction.prototype.addPrediction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ModelPrediction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.clearPredictionList = function() {
  return this.setPredictionList([]);
};


/**
 * optional ClassificationPrediction class_pred = 6;
 * @return {?proto.ModelPrediction.ClassificationPrediction}
 */
proto.ModelPrediction.prototype.getClassPred = function() {
  return /** @type{?proto.ModelPrediction.ClassificationPrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelPrediction.ClassificationPrediction, 6));
};


/**
 * @param {?proto.ModelPrediction.ClassificationPrediction|undefined} value
 * @return {!proto.ModelPrediction} returns this
*/
proto.ModelPrediction.prototype.setClassPred = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.ModelPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.clearClassPred = function() {
  return this.setClassPred(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelPrediction.prototype.hasClassPred = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SentenceSimilarityPrediction sent_pred = 7;
 * @return {?proto.ModelPrediction.SentenceSimilarityPrediction}
 */
proto.ModelPrediction.prototype.getSentPred = function() {
  return /** @type{?proto.ModelPrediction.SentenceSimilarityPrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelPrediction.SentenceSimilarityPrediction, 7));
};


/**
 * @param {?proto.ModelPrediction.SentenceSimilarityPrediction|undefined} value
 * @return {!proto.ModelPrediction} returns this
*/
proto.ModelPrediction.prototype.setSentPred = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.ModelPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelPrediction} returns this
 */
proto.ModelPrediction.prototype.clearSentPred = function() {
  return this.setSentPred(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelPrediction.prototype.hasSentPred = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ModelWorkflowOutput.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    workflowName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workflowVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    predictionList: jspb.Message.toObjectList(msg.getPredictionList(),
    proto.ModelWorkflowOutput.Prediction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput}
 */
proto.ModelWorkflowOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput;
  return proto.ModelWorkflowOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput}
 */
proto.ModelWorkflowOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkflowName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkflowVersion(value);
      break;
    case 3:
      var value = new proto.ModelWorkflowOutput.Prediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.deserializeBinaryFromReader);
      msg.addPrediction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkflowName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkflowVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPredictionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ModelWorkflowOutput.Prediction.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ModelWorkflowOutput.Prediction.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    subPredictionList: jspb.Message.toObjectList(msg.getSubPredictionList(),
    proto.ModelWorkflowOutput.Prediction.SubPrediction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction}
 */
proto.ModelWorkflowOutput.Prediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction;
  return proto.ModelWorkflowOutput.Prediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction}
 */
proto.ModelWorkflowOutput.Prediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.deserializeBinaryFromReader);
      msg.addSubPrediction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubPredictionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.repeatedFields_ = [2];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_ = [[3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.PredCase = {
  PRED_NOT_SET: 0,
  CLASSIFICATION: 3,
  GENERATION: 4,
  NUMERIC: 5,
  SIMILARITY: 6,
  CLUSTER: 7
};

/**
 * @return {proto.ModelWorkflowOutput.Prediction.SubPrediction.PredCase}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getPredCase = function() {
  return /** @type {proto.ModelWorkflowOutput.Prediction.SubPrediction.PredCase} */(jspb.Message.computeOneofCase(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    segmentList: jspb.Message.toObjectList(msg.getSegmentList(),
    proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.toObject, includeInstance),
    classification: (f = msg.getClassification()) && proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.toObject(includeInstance, f),
    generation: (f = msg.getGeneration()) && proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.toObject(includeInstance, f),
    numeric: (f = msg.getNumeric()) && proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.toObject(includeInstance, f),
    similarity: (f = msg.getSimilarity()) && proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.toObject(includeInstance, f),
    cluster: (f = msg.getCluster()) && proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ModelWorkflowOutput.Prediction.SubPrediction.PredictionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.deserializeBinaryFromReader);
      msg.addSegment(value);
      break;
    case 3:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.deserializeBinaryFromReader);
      msg.setClassification(value);
      break;
    case 4:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.deserializeBinaryFromReader);
      msg.setGeneration(value);
      break;
    case 5:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.deserializeBinaryFromReader);
      msg.setNumeric(value);
      break;
    case 6:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.deserializeBinaryFromReader);
      msg.setSimilarity(value);
      break;
    case 7:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.deserializeBinaryFromReader);
      msg.setCluster(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSegmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.serializeBinaryToWriter
    );
  }
  f = message.getClassification();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.serializeBinaryToWriter
    );
  }
  f = message.getGeneration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.serializeBinaryToWriter
    );
  }
  f = message.getNumeric();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.serializeBinaryToWriter
    );
  }
  f = message.getSimilarity();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.serializeBinaryToWriter
    );
  }
  f = message.getCluster();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.PredictionType = {
  CLASSIFICATION: 0,
  GENERATIVE: 1,
  NUMERIC: 2,
  SENTENCE_SIMILARITY: 3,
  CLUSTER: 4
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    startWordIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endWordIdx: jspb.Message.getFieldWithDefault(msg, 2, 0),
    turnId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sentenceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    attentionWeightsList: jspb.Message.toObjectList(msg.getAttentionWeightsList(),
    proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartWordIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndWordIdx(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTurnId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSentenceId(value);
      break;
    case 5:
      var value = new proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight;
      reader.readMessage(value,proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.deserializeBinaryFromReader);
      msg.addAttentionWeights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEndWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTurnId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSentenceId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAttentionWeightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.toObject = function(includeInstance, msg) {
  var f, obj = {
    wordIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWordIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional uint32 word_idx = 1;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.prototype.getWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.prototype.setWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float weight = 2;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint32 start_word_idx = 1;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.getStartWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.setStartWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 end_word_idx = 2;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.getEndWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.setEndWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 turn_id = 3;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.getTurnId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 sentence_id = 4;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.getSentenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.setSentenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated AttentionWeight attention_weights = 5;
 * @return {!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight>}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.getAttentionWeightsList = function() {
  return /** @type{!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight, 5));
};


/**
 * @param {!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight>} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.setAttentionWeightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.addAttentionWeights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.AttentionWeight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo.prototype.clearAttentionWeightsList = function() {
  return this.setAttentionWeightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float score = 2;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    generatedText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeneratedText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneratedText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string generated_text = 1;
 * @return {string}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.prototype.getGeneratedText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction.prototype.setGeneratedText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    indicator: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndicator(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndicator();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional bool indicator = 1;
 * @return {boolean}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.prototype.getIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.prototype.setIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchedPhraseStr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    matchedPhraseId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchedPhraseStr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchedPhraseId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchedPhraseStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMatchedPhraseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string matched_phrase_str = 1;
 * @return {string}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.getMatchedPhraseStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.setMatchedPhraseStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string matched_phrase_id = 2;
 * @return {string}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.getMatchedPhraseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.setMatchedPhraseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.toObject = function(opt_includeInstance) {
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction;
  return proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional PredictionType type = 1;
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.PredictionType}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getType = function() {
  return /** @type {!proto.ModelWorkflowOutput.Prediction.SubPrediction.PredictionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.PredictionType} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated SegmentInfo segment = 2;
 * @return {!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo>}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getSegmentList = function() {
  return /** @type{!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo, 2));
};


/**
 * @param {!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo>} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setSegmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.addSegment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ModelWorkflowOutput.Prediction.SubPrediction.SegmentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.clearSegmentList = function() {
  return this.setSegmentList([]);
};


/**
 * optional ClassificationPrediction classification = 3;
 * @return {?proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getClassification = function() {
  return /** @type{?proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction, 3));
};


/**
 * @param {?proto.ModelWorkflowOutput.Prediction.SubPrediction.ClassificationPrediction|undefined} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setClassification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.clearClassification = function() {
  return this.setClassification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.hasClassification = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GenerativePrediction generation = 4;
 * @return {?proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getGeneration = function() {
  return /** @type{?proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction, 4));
};


/**
 * @param {?proto.ModelWorkflowOutput.Prediction.SubPrediction.GenerativePrediction|undefined} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setGeneration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.clearGeneration = function() {
  return this.setGeneration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.hasGeneration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NumericPrediction numeric = 5;
 * @return {?proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getNumeric = function() {
  return /** @type{?proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction, 5));
};


/**
 * @param {?proto.ModelWorkflowOutput.Prediction.SubPrediction.NumericPrediction|undefined} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setNumeric = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.clearNumeric = function() {
  return this.setNumeric(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.hasNumeric = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SentenceSimilarityPrediction similarity = 6;
 * @return {?proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getSimilarity = function() {
  return /** @type{?proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction, 6));
};


/**
 * @param {?proto.ModelWorkflowOutput.Prediction.SubPrediction.SentenceSimilarityPrediction|undefined} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setSimilarity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.clearSimilarity = function() {
  return this.setSimilarity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.hasSimilarity = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ClusteringPrediction cluster = 7;
 * @return {?proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.getCluster = function() {
  return /** @type{?proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction} */ (
    jspb.Message.getWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction, 7));
};


/**
 * @param {?proto.ModelWorkflowOutput.Prediction.SubPrediction.ClusteringPrediction|undefined} value
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.setCluster = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.ModelWorkflowOutput.Prediction.SubPrediction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.clearCluster = function() {
  return this.setCluster(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ModelWorkflowOutput.Prediction.SubPrediction.prototype.hasCluster = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated SubPrediction sub_prediction = 1;
 * @return {!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction>}
 */
proto.ModelWorkflowOutput.Prediction.prototype.getSubPredictionList = function() {
  return /** @type{!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelWorkflowOutput.Prediction.SubPrediction, 1));
};


/**
 * @param {!Array<!proto.ModelWorkflowOutput.Prediction.SubPrediction>} value
 * @return {!proto.ModelWorkflowOutput.Prediction} returns this
*/
proto.ModelWorkflowOutput.Prediction.prototype.setSubPredictionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ModelWorkflowOutput.Prediction.SubPrediction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelWorkflowOutput.Prediction.SubPrediction}
 */
proto.ModelWorkflowOutput.Prediction.prototype.addSubPrediction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ModelWorkflowOutput.Prediction.SubPrediction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelWorkflowOutput.Prediction} returns this
 */
proto.ModelWorkflowOutput.Prediction.prototype.clearSubPredictionList = function() {
  return this.setSubPredictionList([]);
};


/**
 * optional string workflow_name = 1;
 * @return {string}
 */
proto.ModelWorkflowOutput.prototype.getWorkflowName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput} returns this
 */
proto.ModelWorkflowOutput.prototype.setWorkflowName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workflow_version = 2;
 * @return {string}
 */
proto.ModelWorkflowOutput.prototype.getWorkflowVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ModelWorkflowOutput} returns this
 */
proto.ModelWorkflowOutput.prototype.setWorkflowVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Prediction prediction = 3;
 * @return {!Array<!proto.ModelWorkflowOutput.Prediction>}
 */
proto.ModelWorkflowOutput.prototype.getPredictionList = function() {
  return /** @type{!Array<!proto.ModelWorkflowOutput.Prediction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelWorkflowOutput.Prediction, 3));
};


/**
 * @param {!Array<!proto.ModelWorkflowOutput.Prediction>} value
 * @return {!proto.ModelWorkflowOutput} returns this
*/
proto.ModelWorkflowOutput.prototype.setPredictionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ModelWorkflowOutput.Prediction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelWorkflowOutput.Prediction}
 */
proto.ModelWorkflowOutput.prototype.addPrediction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ModelWorkflowOutput.Prediction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ModelWorkflowOutput} returns this
 */
proto.ModelWorkflowOutput.prototype.clearPredictionList = function() {
  return this.setPredictionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Word.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Word.prototype.toObject = function(opt_includeInstance) {
  return proto.Word.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Word} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Word.toObject = function(includeInstance, msg) {
  var f, obj = {
    str: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    predictionList: jspb.Message.toObjectList(msg.getPredictionList(),
    proto.ModelPrediction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Word}
 */
proto.Word.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Word;
  return proto.Word.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Word} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Word}
 */
proto.Word.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndMs(value);
      break;
    case 4:
      var value = new proto.ModelPrediction;
      reader.readMessage(value,proto.ModelPrediction.deserializeBinaryFromReader);
      msg.addPrediction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Word.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Word.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Word} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Word.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEndMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPredictionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ModelPrediction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string str = 1;
 * @return {string}
 */
proto.Word.prototype.getStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Word} returns this
 */
proto.Word.prototype.setStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 start_ms = 2;
 * @return {number}
 */
proto.Word.prototype.getStartMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Word} returns this
 */
proto.Word.prototype.setStartMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 end_ms = 3;
 * @return {number}
 */
proto.Word.prototype.getEndMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Word} returns this
 */
proto.Word.prototype.setEndMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ModelPrediction prediction = 4;
 * @return {!Array<!proto.ModelPrediction>}
 */
proto.Word.prototype.getPredictionList = function() {
  return /** @type{!Array<!proto.ModelPrediction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelPrediction, 4));
};


/**
 * @param {!Array<!proto.ModelPrediction>} value
 * @return {!proto.Word} returns this
*/
proto.Word.prototype.setPredictionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ModelPrediction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelPrediction}
 */
proto.Word.prototype.addPrediction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ModelPrediction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Word} returns this
 */
proto.Word.prototype.clearPredictionList = function() {
  return this.setPredictionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ConversationTurn.repeatedFields_ = [6,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ConversationTurn.prototype.toObject = function(opt_includeInstance) {
  return proto.ConversationTurn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ConversationTurn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConversationTurn.toObject = function(includeInstance, msg) {
  var f, obj = {
    speakerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    turnId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    endTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    turnStr: jspb.Message.getFieldWithDefault(msg, 5, ""),
    wordsList: jspb.Message.toObjectList(msg.getWordsList(),
    proto.Word.toObject, includeInstance),
    predictionList: jspb.Message.toObjectList(msg.getPredictionList(),
    proto.ModelPrediction.toObject, includeInstance),
    language: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sentencesList: jspb.Message.toObjectList(msg.getSentencesList(),
    proto.SentenceMarker.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ConversationTurn}
 */
proto.ConversationTurn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ConversationTurn;
  return proto.ConversationTurn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ConversationTurn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ConversationTurn}
 */
proto.ConversationTurn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpeakerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTurnId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnStr(value);
      break;
    case 6:
      var value = new proto.Word;
      reader.readMessage(value,proto.Word.deserializeBinaryFromReader);
      msg.addWords(value);
      break;
    case 7:
      var value = new proto.ModelPrediction;
      reader.readMessage(value,proto.ModelPrediction.deserializeBinaryFromReader);
      msg.addPrediction(value);
      break;
    case 8:
      var value = /** @type {!proto.LanguageCode} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 9:
      var value = new proto.SentenceMarker;
      reader.readMessage(value,proto.SentenceMarker.deserializeBinaryFromReader);
      msg.addSentences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ConversationTurn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ConversationTurn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ConversationTurn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ConversationTurn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeakerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTurnId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTurnStr();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Word.serializeBinaryToWriter
    );
  }
  f = message.getPredictionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ModelPrediction.serializeBinaryToWriter
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSentencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.SentenceMarker.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 speaker_id = 1;
 * @return {number}
 */
proto.ConversationTurn.prototype.getSpeakerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.setSpeakerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 turn_id = 2;
 * @return {number}
 */
proto.ConversationTurn.prototype.getTurnId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float start_time = 3;
 * @return {number}
 */
proto.ConversationTurn.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float end_time = 4;
 * @return {number}
 */
proto.ConversationTurn.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string turn_str = 5;
 * @return {string}
 */
proto.ConversationTurn.prototype.getTurnStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.setTurnStr = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Word words = 6;
 * @return {!Array<!proto.Word>}
 */
proto.ConversationTurn.prototype.getWordsList = function() {
  return /** @type{!Array<!proto.Word>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Word, 6));
};


/**
 * @param {!Array<!proto.Word>} value
 * @return {!proto.ConversationTurn} returns this
*/
proto.ConversationTurn.prototype.setWordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Word=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Word}
 */
proto.ConversationTurn.prototype.addWords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Word, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.clearWordsList = function() {
  return this.setWordsList([]);
};


/**
 * repeated ModelPrediction prediction = 7;
 * @return {!Array<!proto.ModelPrediction>}
 */
proto.ConversationTurn.prototype.getPredictionList = function() {
  return /** @type{!Array<!proto.ModelPrediction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ModelPrediction, 7));
};


/**
 * @param {!Array<!proto.ModelPrediction>} value
 * @return {!proto.ConversationTurn} returns this
*/
proto.ConversationTurn.prototype.setPredictionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ModelPrediction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ModelPrediction}
 */
proto.ConversationTurn.prototype.addPrediction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ModelPrediction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.clearPredictionList = function() {
  return this.setPredictionList([]);
};


/**
 * optional LanguageCode language = 8;
 * @return {!proto.LanguageCode}
 */
proto.ConversationTurn.prototype.getLanguage = function() {
  return /** @type {!proto.LanguageCode} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.LanguageCode} value
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated SentenceMarker sentences = 9;
 * @return {!Array<!proto.SentenceMarker>}
 */
proto.ConversationTurn.prototype.getSentencesList = function() {
  return /** @type{!Array<!proto.SentenceMarker>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SentenceMarker, 9));
};


/**
 * @param {!Array<!proto.SentenceMarker>} value
 * @return {!proto.ConversationTurn} returns this
*/
proto.ConversationTurn.prototype.setSentencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.SentenceMarker=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SentenceMarker}
 */
proto.ConversationTurn.prototype.addSentences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.SentenceMarker, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ConversationTurn} returns this
 */
proto.ConversationTurn.prototype.clearSentencesList = function() {
  return this.setSentencesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SentenceMarker.prototype.toObject = function(opt_includeInstance) {
  return proto.SentenceMarker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SentenceMarker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SentenceMarker.toObject = function(includeInstance, msg) {
  var f, obj = {
    startWordIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endWordIdx: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sentenceId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SentenceMarker}
 */
proto.SentenceMarker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SentenceMarker;
  return proto.SentenceMarker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SentenceMarker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SentenceMarker}
 */
proto.SentenceMarker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartWordIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndWordIdx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSentenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SentenceMarker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SentenceMarker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SentenceMarker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SentenceMarker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEndWordIdx();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSentenceId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 start_word_idx = 1;
 * @return {number}
 */
proto.SentenceMarker.prototype.getStartWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SentenceMarker} returns this
 */
proto.SentenceMarker.prototype.setStartWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 end_word_idx = 2;
 * @return {number}
 */
proto.SentenceMarker.prototype.getEndWordIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SentenceMarker} returns this
 */
proto.SentenceMarker.prototype.setEndWordIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 sentence_id = 4;
 * @return {number}
 */
proto.SentenceMarker.prototype.getSentenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SentenceMarker} returns this
 */
proto.SentenceMarker.prototype.setSentenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ChannelData.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChannelData.prototype.toObject = function(opt_includeInstance) {
  return proto.ChannelData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChannelData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelData.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    speakDuration: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    transcribeResponse: (f = msg.getTranscribeResponse()) && proto.TranscribeResponse.toObject(includeInstance, f),
    isRep: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    userId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.AudioSegment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChannelData}
 */
proto.ChannelData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChannelData;
  return proto.ChannelData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChannelData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChannelData}
 */
proto.ChannelData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeakDuration(value);
      break;
    case 4:
      var value = new proto.TranscribeResponse;
      reader.readMessage(value,proto.TranscribeResponse.deserializeBinaryFromReader);
      msg.setTranscribeResponse(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRep(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = new proto.AudioSegment;
      reader.readMessage(value,proto.AudioSegment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChannelData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChannelData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChannelData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpeakDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTranscribeResponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.TranscribeResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsRep();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.AudioSegment.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 channel_id = 1;
 * @return {number}
 */
proto.ChannelData.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ChannelData} returns this
 */
proto.ChannelData.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float speak_duration = 2;
 * @return {number}
 */
proto.ChannelData.prototype.getSpeakDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChannelData} returns this
 */
proto.ChannelData.prototype.setSpeakDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional TranscribeResponse transcribe_response = 4;
 * @return {?proto.TranscribeResponse}
 */
proto.ChannelData.prototype.getTranscribeResponse = function() {
  return /** @type{?proto.TranscribeResponse} */ (
    jspb.Message.getWrapperField(this, proto.TranscribeResponse, 4));
};


/**
 * @param {?proto.TranscribeResponse|undefined} value
 * @return {!proto.ChannelData} returns this
*/
proto.ChannelData.prototype.setTranscribeResponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ChannelData} returns this
 */
proto.ChannelData.prototype.clearTranscribeResponse = function() {
  return this.setTranscribeResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ChannelData.prototype.hasTranscribeResponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_rep = 10;
 * @return {boolean}
 */
proto.ChannelData.prototype.getIsRep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ChannelData} returns this
 */
proto.ChannelData.prototype.setIsRep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string user_id = 11;
 * @return {string}
 */
proto.ChannelData.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChannelData} returns this
 */
proto.ChannelData.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated AudioSegment segments = 5;
 * @return {!Array<!proto.AudioSegment>}
 */
proto.ChannelData.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.AudioSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AudioSegment, 5));
};


/**
 * @param {!Array<!proto.AudioSegment>} value
 * @return {!proto.ChannelData} returns this
*/
proto.ChannelData.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.AudioSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AudioSegment}
 */
proto.ChannelData.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.AudioSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChannelData} returns this
 */
proto.ChannelData.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CallMetadata.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CallMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.CallMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CallMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CallMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    callId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recordingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    callStartTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    callStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    callType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sampleWidth: jspb.Message.getFieldWithDefault(msg, 9, 0),
    numChannels: jspb.Message.getFieldWithDefault(msg, 10, 0),
    frameRate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    numFrames: jspb.Message.getFieldWithDefault(msg, 12, 0),
    diarizationEngine: jspb.Message.getFieldWithDefault(msg, 13, 0),
    transcribeEngine: jspb.Message.getFieldWithDefault(msg, 14, 0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    analyzerList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    transcribeModelId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    diarizationNumPossibleSpeakers: jspb.Message.getFieldWithDefault(msg, 19, 0),
    kaldiDiarizationTargetEnergy: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    kaldiDiarizationClusteringThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    kaldiClusterToFingerprintThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    version: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CallMetadata}
 */
proto.CallMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CallMetadata;
  return proto.CallMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CallMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CallMetadata}
 */
proto.CallMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCallStartTime(value);
      break;
    case 5:
      var value = /** @type {!proto.CallStatus} */ (reader.readEnum());
      msg.setCallStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {!proto.CallType} */ (reader.readEnum());
      msg.setCallType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSampleWidth(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumChannels(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrameRate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumFrames(value);
      break;
    case 13:
      var value = /** @type {!proto.DiarizationEngine} */ (reader.readEnum());
      msg.setDiarizationEngine(value);
      break;
    case 14:
      var value = /** @type {!proto.TranscribeEngine} */ (reader.readEnum());
      msg.setTranscribeEngine(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 17:
      var values = /** @type {!Array<!proto.SentimentData.Analyzer>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAnalyzer(values[i]);
      }
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranscribeModelId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiarizationNumPossibleSpeakers(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setKaldiDiarizationTargetEnergy(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setKaldiDiarizationClusteringThreshold(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setKaldiClusterToFingerprintThreshold(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CallMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CallMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CallMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CallMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecordingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCallStartTime();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCallStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCallType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSampleWidth();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNumChannels();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getFrameRate();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getNumFrames();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getDiarizationEngine();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getTranscribeEngine();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAnalyzerList();
  if (f.length > 0) {
    writer.writePackedEnum(
      17,
      f
    );
  }
  f = message.getTranscribeModelId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDiarizationNumPossibleSpeakers();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getKaldiDiarizationTargetEnergy();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getKaldiDiarizationClusteringThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getKaldiClusterToFingerprintThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};


/**
 * optional string call_id = 1;
 * @return {string}
 */
proto.CallMetadata.prototype.getCallId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setCallId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string recording_id = 2;
 * @return {string}
 */
proto.CallMetadata.prototype.getRecordingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setRecordingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_id = 3;
 * @return {string}
 */
proto.CallMetadata.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 call_start_time = 4;
 * @return {number}
 */
proto.CallMetadata.prototype.getCallStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setCallStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CallStatus call_status = 5;
 * @return {!proto.CallStatus}
 */
proto.CallMetadata.prototype.getCallStatus = function() {
  return /** @type {!proto.CallStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.CallStatus} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setCallStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string timezone = 6;
 * @return {string}
 */
proto.CallMetadata.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.CallMetadata.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional CallType call_type = 8;
 * @return {!proto.CallType}
 */
proto.CallMetadata.prototype.getCallType = function() {
  return /** @type {!proto.CallType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.CallType} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setCallType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 sample_width = 9;
 * @return {number}
 */
proto.CallMetadata.prototype.getSampleWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setSampleWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 num_channels = 10;
 * @return {number}
 */
proto.CallMetadata.prototype.getNumChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setNumChannels = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 frame_rate = 11;
 * @return {number}
 */
proto.CallMetadata.prototype.getFrameRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setFrameRate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 num_frames = 12;
 * @return {number}
 */
proto.CallMetadata.prototype.getNumFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setNumFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional DiarizationEngine diarization_engine = 13;
 * @return {!proto.DiarizationEngine}
 */
proto.CallMetadata.prototype.getDiarizationEngine = function() {
  return /** @type {!proto.DiarizationEngine} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.DiarizationEngine} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setDiarizationEngine = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional TranscribeEngine transcribe_engine = 14;
 * @return {!proto.TranscribeEngine}
 */
proto.CallMetadata.prototype.getTranscribeEngine = function() {
  return /** @type {!proto.TranscribeEngine} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.TranscribeEngine} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setTranscribeEngine = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string language_code = 15;
 * @return {string}
 */
proto.CallMetadata.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated SentimentData.Analyzer analyzer = 17;
 * @return {!Array<!proto.SentimentData.Analyzer>}
 */
proto.CallMetadata.prototype.getAnalyzerList = function() {
  return /** @type {!Array<!proto.SentimentData.Analyzer>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<!proto.SentimentData.Analyzer>} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setAnalyzerList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {!proto.SentimentData.Analyzer} value
 * @param {number=} opt_index
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.addAnalyzer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.clearAnalyzerList = function() {
  return this.setAnalyzerList([]);
};


/**
 * optional string transcribe_model_id = 18;
 * @return {string}
 */
proto.CallMetadata.prototype.getTranscribeModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setTranscribeModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int32 diarization_num_possible_speakers = 19;
 * @return {number}
 */
proto.CallMetadata.prototype.getDiarizationNumPossibleSpeakers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setDiarizationNumPossibleSpeakers = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional float kaldi_diarization_target_energy = 20;
 * @return {number}
 */
proto.CallMetadata.prototype.getKaldiDiarizationTargetEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setKaldiDiarizationTargetEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float kaldi_diarization_clustering_threshold = 21;
 * @return {number}
 */
proto.CallMetadata.prototype.getKaldiDiarizationClusteringThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setKaldiDiarizationClusteringThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional float kaldi_cluster_to_fingerprint_threshold = 22;
 * @return {number}
 */
proto.CallMetadata.prototype.getKaldiClusterToFingerprintThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setKaldiClusterToFingerprintThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional int32 version = 23;
 * @return {number}
 */
proto.CallMetadata.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.CallMetadata} returns this
 */
proto.CallMetadata.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TranscribeResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TranscribeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TranscribeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TranscribeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transcribeEngine: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transcribeDataBytes: msg.getTranscribeDataBytes_asB64(),
    turnList: jspb.Message.toObjectList(msg.getTurnList(),
    proto.TranscribeResponse.TranscribeTurn.toObject, includeInstance),
    hasDiarization: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    offset: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TranscribeResponse}
 */
proto.TranscribeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TranscribeResponse;
  return proto.TranscribeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TranscribeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TranscribeResponse}
 */
proto.TranscribeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.TranscribeEngine} */ (reader.readEnum());
      msg.setTranscribeEngine(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTranscribeDataBytes(value);
      break;
    case 3:
      var value = new proto.TranscribeResponse.TranscribeTurn;
      reader.readMessage(value,proto.TranscribeResponse.TranscribeTurn.deserializeBinaryFromReader);
      msg.addTurn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDiarization(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TranscribeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TranscribeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TranscribeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTranscribeEngine();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTranscribeDataBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getTurnList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.TranscribeResponse.TranscribeTurn.serializeBinaryToWriter
    );
  }
  f = message.getHasDiarization();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TranscribeResponse.WordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.TranscribeResponse.WordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TranscribeResponse.WordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeResponse.WordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startMsecs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endMsecs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    speakerId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TranscribeResponse.WordInfo}
 */
proto.TranscribeResponse.WordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TranscribeResponse.WordInfo;
  return proto.TranscribeResponse.WordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TranscribeResponse.WordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TranscribeResponse.WordInfo}
 */
proto.TranscribeResponse.WordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartMsecs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndMsecs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConfidence(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpeakerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TranscribeResponse.WordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TranscribeResponse.WordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TranscribeResponse.WordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeResponse.WordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartMsecs();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEndMsecs();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getSpeakerId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.TranscribeResponse.WordInfo.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TranscribeResponse.WordInfo} returns this
 */
proto.TranscribeResponse.WordInfo.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 start_msecs = 2;
 * @return {number}
 */
proto.TranscribeResponse.WordInfo.prototype.getStartMsecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TranscribeResponse.WordInfo} returns this
 */
proto.TranscribeResponse.WordInfo.prototype.setStartMsecs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 end_msecs = 3;
 * @return {number}
 */
proto.TranscribeResponse.WordInfo.prototype.getEndMsecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TranscribeResponse.WordInfo} returns this
 */
proto.TranscribeResponse.WordInfo.prototype.setEndMsecs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float confidence = 4;
 * @return {number}
 */
proto.TranscribeResponse.WordInfo.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TranscribeResponse.WordInfo} returns this
 */
proto.TranscribeResponse.WordInfo.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 speaker_id = 5;
 * @return {number}
 */
proto.TranscribeResponse.WordInfo.prototype.getSpeakerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.TranscribeResponse.WordInfo} returns this
 */
proto.TranscribeResponse.WordInfo.prototype.setSpeakerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TranscribeResponse.TranscribeTurn.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.toObject = function(opt_includeInstance) {
  return proto.TranscribeResponse.TranscribeTurn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TranscribeResponse.TranscribeTurn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeResponse.TranscribeTurn.toObject = function(includeInstance, msg) {
  var f, obj = {
    wordInfoList: jspb.Message.toObjectList(msg.getWordInfoList(),
    proto.TranscribeResponse.WordInfo.toObject, includeInstance),
    transcript: jspb.Message.getFieldWithDefault(msg, 2, ""),
    speakerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    language: jspb.Message.getFieldWithDefault(msg, 4, 0),
    originalLanguage: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TranscribeResponse.TranscribeTurn}
 */
proto.TranscribeResponse.TranscribeTurn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TranscribeResponse.TranscribeTurn;
  return proto.TranscribeResponse.TranscribeTurn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TranscribeResponse.TranscribeTurn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TranscribeResponse.TranscribeTurn}
 */
proto.TranscribeResponse.TranscribeTurn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TranscribeResponse.WordInfo;
      reader.readMessage(value,proto.TranscribeResponse.WordInfo.deserializeBinaryFromReader);
      msg.addWordInfo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranscript(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpeakerId(value);
      break;
    case 4:
      var value = /** @type {!proto.LanguageCode} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 5:
      var value = /** @type {!proto.LanguageCode} */ (reader.readEnum());
      msg.setOriginalLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TranscribeResponse.TranscribeTurn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TranscribeResponse.TranscribeTurn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TranscribeResponse.TranscribeTurn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWordInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TranscribeResponse.WordInfo.serializeBinaryToWriter
    );
  }
  f = message.getTranscript();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpeakerId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOriginalLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * repeated WordInfo word_info = 1;
 * @return {!Array<!proto.TranscribeResponse.WordInfo>}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.getWordInfoList = function() {
  return /** @type{!Array<!proto.TranscribeResponse.WordInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TranscribeResponse.WordInfo, 1));
};


/**
 * @param {!Array<!proto.TranscribeResponse.WordInfo>} value
 * @return {!proto.TranscribeResponse.TranscribeTurn} returns this
*/
proto.TranscribeResponse.TranscribeTurn.prototype.setWordInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TranscribeResponse.WordInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TranscribeResponse.WordInfo}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.addWordInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TranscribeResponse.WordInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TranscribeResponse.TranscribeTurn} returns this
 */
proto.TranscribeResponse.TranscribeTurn.prototype.clearWordInfoList = function() {
  return this.setWordInfoList([]);
};


/**
 * optional string transcript = 2;
 * @return {string}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.getTranscript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TranscribeResponse.TranscribeTurn} returns this
 */
proto.TranscribeResponse.TranscribeTurn.prototype.setTranscript = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 speaker_id = 3;
 * @return {number}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.getSpeakerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TranscribeResponse.TranscribeTurn} returns this
 */
proto.TranscribeResponse.TranscribeTurn.prototype.setSpeakerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional LanguageCode language = 4;
 * @return {!proto.LanguageCode}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.getLanguage = function() {
  return /** @type {!proto.LanguageCode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.LanguageCode} value
 * @return {!proto.TranscribeResponse.TranscribeTurn} returns this
 */
proto.TranscribeResponse.TranscribeTurn.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional LanguageCode original_language = 5;
 * @return {!proto.LanguageCode}
 */
proto.TranscribeResponse.TranscribeTurn.prototype.getOriginalLanguage = function() {
  return /** @type {!proto.LanguageCode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.LanguageCode} value
 * @return {!proto.TranscribeResponse.TranscribeTurn} returns this
 */
proto.TranscribeResponse.TranscribeTurn.prototype.setOriginalLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TranscribeEngine transcribe_engine = 1;
 * @return {!proto.TranscribeEngine}
 */
proto.TranscribeResponse.prototype.getTranscribeEngine = function() {
  return /** @type {!proto.TranscribeEngine} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.TranscribeEngine} value
 * @return {!proto.TranscribeResponse} returns this
 */
proto.TranscribeResponse.prototype.setTranscribeEngine = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes transcribe_data_bytes = 2;
 * @return {!(string|Uint8Array)}
 */
proto.TranscribeResponse.prototype.getTranscribeDataBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes transcribe_data_bytes = 2;
 * This is a type-conversion wrapper around `getTranscribeDataBytes()`
 * @return {string}
 */
proto.TranscribeResponse.prototype.getTranscribeDataBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTranscribeDataBytes()));
};


/**
 * optional bytes transcribe_data_bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTranscribeDataBytes()`
 * @return {!Uint8Array}
 */
proto.TranscribeResponse.prototype.getTranscribeDataBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTranscribeDataBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.TranscribeResponse} returns this
 */
proto.TranscribeResponse.prototype.setTranscribeDataBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated TranscribeTurn turn = 3;
 * @return {!Array<!proto.TranscribeResponse.TranscribeTurn>}
 */
proto.TranscribeResponse.prototype.getTurnList = function() {
  return /** @type{!Array<!proto.TranscribeResponse.TranscribeTurn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TranscribeResponse.TranscribeTurn, 3));
};


/**
 * @param {!Array<!proto.TranscribeResponse.TranscribeTurn>} value
 * @return {!proto.TranscribeResponse} returns this
*/
proto.TranscribeResponse.prototype.setTurnList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.TranscribeResponse.TranscribeTurn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TranscribeResponse.TranscribeTurn}
 */
proto.TranscribeResponse.prototype.addTurn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.TranscribeResponse.TranscribeTurn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TranscribeResponse} returns this
 */
proto.TranscribeResponse.prototype.clearTurnList = function() {
  return this.setTurnList([]);
};


/**
 * optional bool has_diarization = 4;
 * @return {boolean}
 */
proto.TranscribeResponse.prototype.getHasDiarization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TranscribeResponse} returns this
 */
proto.TranscribeResponse.prototype.setHasDiarization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float offset = 5;
 * @return {number}
 */
proto.TranscribeResponse.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TranscribeResponse} returns this
 */
proto.TranscribeResponse.prototype.setOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DiarizationResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DiarizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DiarizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DiarizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    turnsList: jspb.Message.toObjectList(msg.getTurnsList(),
    proto.DiarizationResponse.DiarizationTurn.toObject, includeInstance),
    numSpeakers: jspb.Message.getFieldWithDefault(msg, 2, 0),
    engineIdToStringsIdList: jspb.Message.toObjectList(msg.getEngineIdToStringsIdList(),
    proto.DiarizationResponse.EngineToStringsId.toObject, includeInstance),
    wasRepRecognized: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DiarizationResponse}
 */
proto.DiarizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DiarizationResponse;
  return proto.DiarizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DiarizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DiarizationResponse}
 */
proto.DiarizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DiarizationResponse.DiarizationTurn;
      reader.readMessage(value,proto.DiarizationResponse.DiarizationTurn.deserializeBinaryFromReader);
      msg.addTurns(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSpeakers(value);
      break;
    case 3:
      var value = new proto.DiarizationResponse.EngineToStringsId;
      reader.readMessage(value,proto.DiarizationResponse.EngineToStringsId.deserializeBinaryFromReader);
      msg.addEngineIdToStringsId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWasRepRecognized(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DiarizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DiarizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DiarizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTurnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DiarizationResponse.DiarizationTurn.serializeBinaryToWriter
    );
  }
  f = message.getNumSpeakers();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEngineIdToStringsIdList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.DiarizationResponse.EngineToStringsId.serializeBinaryToWriter
    );
  }
  f = message.getWasRepRecognized();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DiarizationResponse.DiarizationTurn.prototype.toObject = function(opt_includeInstance) {
  return proto.DiarizationResponse.DiarizationTurn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DiarizationResponse.DiarizationTurn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizationResponse.DiarizationTurn.toObject = function(includeInstance, msg) {
  var f, obj = {
    speakerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    endTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    engineId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DiarizationResponse.DiarizationTurn}
 */
proto.DiarizationResponse.DiarizationTurn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DiarizationResponse.DiarizationTurn;
  return proto.DiarizationResponse.DiarizationTurn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DiarizationResponse.DiarizationTurn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DiarizationResponse.DiarizationTurn}
 */
proto.DiarizationResponse.DiarizationTurn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpeakerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEngineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DiarizationResponse.DiarizationTurn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DiarizationResponse.DiarizationTurn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DiarizationResponse.DiarizationTurn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizationResponse.DiarizationTurn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeakerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getEngineId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 speaker_id = 1;
 * @return {number}
 */
proto.DiarizationResponse.DiarizationTurn.prototype.getSpeakerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse.DiarizationTurn} returns this
 */
proto.DiarizationResponse.DiarizationTurn.prototype.setSpeakerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float start_time = 2;
 * @return {number}
 */
proto.DiarizationResponse.DiarizationTurn.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse.DiarizationTurn} returns this
 */
proto.DiarizationResponse.DiarizationTurn.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float end_time = 3;
 * @return {number}
 */
proto.DiarizationResponse.DiarizationTurn.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse.DiarizationTurn} returns this
 */
proto.DiarizationResponse.DiarizationTurn.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 engine_id = 4;
 * @return {number}
 */
proto.DiarizationResponse.DiarizationTurn.prototype.getEngineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse.DiarizationTurn} returns this
 */
proto.DiarizationResponse.DiarizationTurn.prototype.setEngineId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.toObject = function(opt_includeInstance) {
  return proto.DiarizationResponse.EngineToStringsId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DiarizationResponse.EngineToStringsId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizationResponse.EngineToStringsId.toObject = function(includeInstance, msg) {
  var f, obj = {
    engineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stringsId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    speakerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isRep: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DiarizationResponse.EngineToStringsId}
 */
proto.DiarizationResponse.EngineToStringsId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DiarizationResponse.EngineToStringsId;
  return proto.DiarizationResponse.EngineToStringsId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DiarizationResponse.EngineToStringsId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DiarizationResponse.EngineToStringsId}
 */
proto.DiarizationResponse.EngineToStringsId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEngineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStringsId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeakerName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DiarizationResponse.EngineToStringsId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DiarizationResponse.EngineToStringsId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DiarizationResponse.EngineToStringsId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEngineId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStringsId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpeakerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsRep();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 engine_id = 1;
 * @return {number}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.getEngineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse.EngineToStringsId} returns this
 */
proto.DiarizationResponse.EngineToStringsId.prototype.setEngineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 strings_id = 2;
 * @return {number}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.getStringsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse.EngineToStringsId} returns this
 */
proto.DiarizationResponse.EngineToStringsId.prototype.setStringsId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DiarizationResponse.EngineToStringsId} returns this
 */
proto.DiarizationResponse.EngineToStringsId.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string speaker_name = 4;
 * @return {string}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.getSpeakerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DiarizationResponse.EngineToStringsId} returns this
 */
proto.DiarizationResponse.EngineToStringsId.prototype.setSpeakerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_rep = 5;
 * @return {boolean}
 */
proto.DiarizationResponse.EngineToStringsId.prototype.getIsRep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DiarizationResponse.EngineToStringsId} returns this
 */
proto.DiarizationResponse.EngineToStringsId.prototype.setIsRep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated DiarizationTurn turns = 1;
 * @return {!Array<!proto.DiarizationResponse.DiarizationTurn>}
 */
proto.DiarizationResponse.prototype.getTurnsList = function() {
  return /** @type{!Array<!proto.DiarizationResponse.DiarizationTurn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DiarizationResponse.DiarizationTurn, 1));
};


/**
 * @param {!Array<!proto.DiarizationResponse.DiarizationTurn>} value
 * @return {!proto.DiarizationResponse} returns this
*/
proto.DiarizationResponse.prototype.setTurnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DiarizationResponse.DiarizationTurn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DiarizationResponse.DiarizationTurn}
 */
proto.DiarizationResponse.prototype.addTurns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DiarizationResponse.DiarizationTurn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DiarizationResponse} returns this
 */
proto.DiarizationResponse.prototype.clearTurnsList = function() {
  return this.setTurnsList([]);
};


/**
 * optional int32 num_speakers = 2;
 * @return {number}
 */
proto.DiarizationResponse.prototype.getNumSpeakers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DiarizationResponse} returns this
 */
proto.DiarizationResponse.prototype.setNumSpeakers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated EngineToStringsId engine_id_to_strings_id = 3;
 * @return {!Array<!proto.DiarizationResponse.EngineToStringsId>}
 */
proto.DiarizationResponse.prototype.getEngineIdToStringsIdList = function() {
  return /** @type{!Array<!proto.DiarizationResponse.EngineToStringsId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DiarizationResponse.EngineToStringsId, 3));
};


/**
 * @param {!Array<!proto.DiarizationResponse.EngineToStringsId>} value
 * @return {!proto.DiarizationResponse} returns this
*/
proto.DiarizationResponse.prototype.setEngineIdToStringsIdList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.DiarizationResponse.EngineToStringsId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DiarizationResponse.EngineToStringsId}
 */
proto.DiarizationResponse.prototype.addEngineIdToStringsId = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.DiarizationResponse.EngineToStringsId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DiarizationResponse} returns this
 */
proto.DiarizationResponse.prototype.clearEngineIdToStringsIdList = function() {
  return this.setEngineIdToStringsIdList([]);
};


/**
 * optional bool was_rep_recognized = 4;
 * @return {boolean}
 */
proto.DiarizationResponse.prototype.getWasRepRecognized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DiarizationResponse} returns this
 */
proto.DiarizationResponse.prototype.setWasRepRecognized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CallData.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CallData.prototype.toObject = function(opt_includeInstance) {
  return proto.CallData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CallData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CallData.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && proto.CallMetadata.toObject(includeInstance, f),
    gcsBasepath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    channelList: jspb.Message.toObjectList(msg.getChannelList(),
    proto.ChannelData.toObject, includeInstance),
    conversationTurnList: jspb.Message.toObjectList(msg.getConversationTurnList(),
    proto.ConversationTurn.toObject, includeInstance),
    transcribeResponse: (f = msg.getTranscribeResponse()) && proto.TranscribeResponse.toObject(includeInstance, f),
    diarizationResponse: (f = msg.getDiarizationResponse()) && proto.DiarizationResponse.toObject(includeInstance, f),
    recorderCapturedDiarization: (f = msg.getRecorderCapturedDiarization()) && proto.DiarizationResponse.toObject(includeInstance, f),
    totalAudioLengthSecs: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalSpeakLengthSecs: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CallData}
 */
proto.CallData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CallData;
  return proto.CallData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CallData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CallData}
 */
proto.CallData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CallMetadata;
      reader.readMessage(value,proto.CallMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGcsBasepath(value);
      break;
    case 3:
      var value = new proto.ChannelData;
      reader.readMessage(value,proto.ChannelData.deserializeBinaryFromReader);
      msg.addChannel(value);
      break;
    case 4:
      var value = new proto.ConversationTurn;
      reader.readMessage(value,proto.ConversationTurn.deserializeBinaryFromReader);
      msg.addConversationTurn(value);
      break;
    case 5:
      var value = new proto.TranscribeResponse;
      reader.readMessage(value,proto.TranscribeResponse.deserializeBinaryFromReader);
      msg.setTranscribeResponse(value);
      break;
    case 6:
      var value = new proto.DiarizationResponse;
      reader.readMessage(value,proto.DiarizationResponse.deserializeBinaryFromReader);
      msg.setDiarizationResponse(value);
      break;
    case 10:
      var value = new proto.DiarizationResponse;
      reader.readMessage(value,proto.DiarizationResponse.deserializeBinaryFromReader);
      msg.setRecorderCapturedDiarization(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalAudioLengthSecs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalSpeakLengthSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CallData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CallData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CallData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CallData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CallMetadata.serializeBinaryToWriter
    );
  }
  f = message.getGcsBasepath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChannelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ChannelData.serializeBinaryToWriter
    );
  }
  f = message.getConversationTurnList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ConversationTurn.serializeBinaryToWriter
    );
  }
  f = message.getTranscribeResponse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.TranscribeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDiarizationResponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.DiarizationResponse.serializeBinaryToWriter
    );
  }
  f = message.getRecorderCapturedDiarization();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.DiarizationResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalAudioLengthSecs();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalSpeakLengthSecs();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional CallMetadata metadata = 1;
 * @return {?proto.CallMetadata}
 */
proto.CallData.prototype.getMetadata = function() {
  return /** @type{?proto.CallMetadata} */ (
    jspb.Message.getWrapperField(this, proto.CallMetadata, 1));
};


/**
 * @param {?proto.CallMetadata|undefined} value
 * @return {!proto.CallData} returns this
*/
proto.CallData.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CallData.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string gcs_basepath = 2;
 * @return {string}
 */
proto.CallData.prototype.getGcsBasepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.setGcsBasepath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ChannelData channel = 3;
 * @return {!Array<!proto.ChannelData>}
 */
proto.CallData.prototype.getChannelList = function() {
  return /** @type{!Array<!proto.ChannelData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChannelData, 3));
};


/**
 * @param {!Array<!proto.ChannelData>} value
 * @return {!proto.CallData} returns this
*/
proto.CallData.prototype.setChannelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ChannelData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChannelData}
 */
proto.CallData.prototype.addChannel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ChannelData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.clearChannelList = function() {
  return this.setChannelList([]);
};


/**
 * repeated ConversationTurn conversation_turn = 4;
 * @return {!Array<!proto.ConversationTurn>}
 */
proto.CallData.prototype.getConversationTurnList = function() {
  return /** @type{!Array<!proto.ConversationTurn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ConversationTurn, 4));
};


/**
 * @param {!Array<!proto.ConversationTurn>} value
 * @return {!proto.CallData} returns this
*/
proto.CallData.prototype.setConversationTurnList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ConversationTurn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ConversationTurn}
 */
proto.CallData.prototype.addConversationTurn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ConversationTurn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.clearConversationTurnList = function() {
  return this.setConversationTurnList([]);
};


/**
 * optional TranscribeResponse transcribe_response = 5;
 * @return {?proto.TranscribeResponse}
 */
proto.CallData.prototype.getTranscribeResponse = function() {
  return /** @type{?proto.TranscribeResponse} */ (
    jspb.Message.getWrapperField(this, proto.TranscribeResponse, 5));
};


/**
 * @param {?proto.TranscribeResponse|undefined} value
 * @return {!proto.CallData} returns this
*/
proto.CallData.prototype.setTranscribeResponse = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.clearTranscribeResponse = function() {
  return this.setTranscribeResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CallData.prototype.hasTranscribeResponse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DiarizationResponse diarization_response = 6;
 * @return {?proto.DiarizationResponse}
 */
proto.CallData.prototype.getDiarizationResponse = function() {
  return /** @type{?proto.DiarizationResponse} */ (
    jspb.Message.getWrapperField(this, proto.DiarizationResponse, 6));
};


/**
 * @param {?proto.DiarizationResponse|undefined} value
 * @return {!proto.CallData} returns this
*/
proto.CallData.prototype.setDiarizationResponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.clearDiarizationResponse = function() {
  return this.setDiarizationResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CallData.prototype.hasDiarizationResponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DiarizationResponse recorder_captured_diarization = 10;
 * @return {?proto.DiarizationResponse}
 */
proto.CallData.prototype.getRecorderCapturedDiarization = function() {
  return /** @type{?proto.DiarizationResponse} */ (
    jspb.Message.getWrapperField(this, proto.DiarizationResponse, 10));
};


/**
 * @param {?proto.DiarizationResponse|undefined} value
 * @return {!proto.CallData} returns this
*/
proto.CallData.prototype.setRecorderCapturedDiarization = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.clearRecorderCapturedDiarization = function() {
  return this.setRecorderCapturedDiarization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CallData.prototype.hasRecorderCapturedDiarization = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional float total_audio_length_secs = 8;
 * @return {number}
 */
proto.CallData.prototype.getTotalAudioLengthSecs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.setTotalAudioLengthSecs = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float total_speak_length_secs = 9;
 * @return {number}
 */
proto.CallData.prototype.getTotalSpeakLengthSecs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CallData} returns this
 */
proto.CallData.prototype.setTotalSpeakLengthSecs = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StringsQueueTask.prototype.toObject = function(opt_includeInstance) {
  return proto.StringsQueueTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StringsQueueTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringsQueueTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    callId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    processStage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    useRecorderDiarization: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StringsQueueTask}
 */
proto.StringsQueueTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StringsQueueTask;
  return proto.StringsQueueTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StringsQueueTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StringsQueueTask}
 */
proto.StringsQueueTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallId(value);
      break;
    case 2:
      var value = /** @type {!proto.ProcessAudioStage} */ (reader.readEnum());
      msg.setProcessStage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseRecorderDiarization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StringsQueueTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StringsQueueTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StringsQueueTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringsQueueTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProcessStage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUseRecorderDiarization();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string call_id = 1;
 * @return {string}
 */
proto.StringsQueueTask.prototype.getCallId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StringsQueueTask} returns this
 */
proto.StringsQueueTask.prototype.setCallId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProcessAudioStage process_stage = 2;
 * @return {!proto.ProcessAudioStage}
 */
proto.StringsQueueTask.prototype.getProcessStage = function() {
  return /** @type {!proto.ProcessAudioStage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ProcessAudioStage} value
 * @return {!proto.StringsQueueTask} returns this
 */
proto.StringsQueueTask.prototype.setProcessStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool use_recorder_diarization = 3;
 * @return {boolean}
 */
proto.StringsQueueTask.prototype.getUseRecorderDiarization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.StringsQueueTask} returns this
 */
proto.StringsQueueTask.prototype.setUseRecorderDiarization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ActionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    typestr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    time: jspb.Message.getFieldWithDefault(msg, 4, ""),
    turnId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActionItem}
 */
proto.ActionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActionItem;
  return proto.ActionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActionItem}
 */
proto.ActionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ActionItem.ActionItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypestr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTurnId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTypestr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTurnId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ActionItem.ActionItemType = {
  UNSET: 0,
  CALL: 1,
  MESSAGE: 2,
  EMAIL: 3,
  MEETING: 4
};

/**
 * optional ActionItemType type = 1;
 * @return {!proto.ActionItem.ActionItemType}
 */
proto.ActionItem.prototype.getType = function() {
  return /** @type {!proto.ActionItem.ActionItemType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ActionItem.ActionItemType} value
 * @return {!proto.ActionItem} returns this
 */
proto.ActionItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string typestr = 2;
 * @return {string}
 */
proto.ActionItem.prototype.getTypestr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActionItem} returns this
 */
proto.ActionItem.prototype.setTypestr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.ActionItem.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActionItem} returns this
 */
proto.ActionItem.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string time = 4;
 * @return {string}
 */
proto.ActionItem.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ActionItem} returns this
 */
proto.ActionItem.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 turn_id = 5;
 * @return {number}
 */
proto.ActionItem.prototype.getTurnId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ActionItem} returns this
 */
proto.ActionItem.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 timestamp = 6;
 * @return {number}
 */
proto.ActionItem.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ActionItem} returns this
 */
proto.ActionItem.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SummaryData.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SummaryData.prototype.toObject = function(opt_includeInstance) {
  return proto.SummaryData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SummaryData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SummaryData.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionItemList: jspb.Message.toObjectList(msg.getActionItemList(),
    proto.ActionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SummaryData}
 */
proto.SummaryData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SummaryData;
  return proto.SummaryData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SummaryData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SummaryData}
 */
proto.SummaryData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = new proto.ActionItem;
      reader.readMessage(value,proto.ActionItem.deserializeBinaryFromReader);
      msg.addActionItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SummaryData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SummaryData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SummaryData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SummaryData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ActionItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActionItem action_item = 6;
 * @return {!Array<!proto.ActionItem>}
 */
proto.SummaryData.prototype.getActionItemList = function() {
  return /** @type{!Array<!proto.ActionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ActionItem, 6));
};


/**
 * @param {!Array<!proto.ActionItem>} value
 * @return {!proto.SummaryData} returns this
*/
proto.SummaryData.prototype.setActionItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ActionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ActionItem}
 */
proto.SummaryData.prototype.addActionItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ActionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SummaryData} returns this
 */
proto.SummaryData.prototype.clearActionItemList = function() {
  return this.setActionItemList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NamedEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.NamedEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NamedEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NamedEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    match: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    modelId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reviewDecision: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NamedEntity}
 */
proto.NamedEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NamedEntity;
  return proto.NamedEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NamedEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NamedEntity}
 */
proto.NamedEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewDecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NamedEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NamedEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NamedEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NamedEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMatch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReviewDecision();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.NamedEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.NamedEntity.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string match = 3;
 * @return {string}
 */
proto.NamedEntity.prototype.getMatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setMatch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category_id = 4;
 * @return {string}
 */
proto.NamedEntity.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float score = 5;
 * @return {number}
 */
proto.NamedEntity.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string model_id = 6;
 * @return {string}
 */
proto.NamedEntity.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string review_decision = 7;
 * @return {string}
 */
proto.NamedEntity.prototype.getReviewDecision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.NamedEntity} returns this
 */
proto.NamedEntity.prototype.setReviewDecision = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SentimentData.prototype.toObject = function(opt_includeInstance) {
  return proto.SentimentData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SentimentData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SentimentData.toObject = function(includeInstance, msg) {
  var f, obj = {
    sentimentType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    analyzer: jspb.Message.getFieldWithDefault(msg, 4, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SentimentData}
 */
proto.SentimentData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SentimentData;
  return proto.SentimentData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SentimentData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SentimentData}
 */
proto.SentimentData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!proto.SentimentData.SentimentType} */ (reader.readEnum());
      msg.setSentimentType(value);
      break;
    case 4:
      var value = /** @type {!proto.SentimentData.Analyzer} */ (reader.readEnum());
      msg.setAnalyzer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SentimentData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SentimentData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SentimentData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SentimentData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSentimentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAnalyzer();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.SentimentData.Analyzer = {
  DEFAULT_SENTIMENT_ANALYZER: 0,
  STANFORD: 1,
  NLTK_VADER: 2,
  GOOGLE: 3,
  WATSON: 4,
  WATSON_TONE: 5,
  VOKATURI: 6
};

/**
 * @enum {number}
 */
proto.SentimentData.SentimentType = {
  DEFAULT_SENTIMENT_TYPE: 0,
  NEUTRAL: 1,
  SADNESS: 2,
  ANGER: 3,
  DISGUST: 4,
  FEAR: 5,
  JOY: 6,
  TENTATIVE: 7,
  GENERIC: 8
};

/**
 * optional SentimentType sentiment_type = 3;
 * @return {!proto.SentimentData.SentimentType}
 */
proto.SentimentData.prototype.getSentimentType = function() {
  return /** @type {!proto.SentimentData.SentimentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.SentimentData.SentimentType} value
 * @return {!proto.SentimentData} returns this
 */
proto.SentimentData.prototype.setSentimentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Analyzer analyzer = 4;
 * @return {!proto.SentimentData.Analyzer}
 */
proto.SentimentData.prototype.getAnalyzer = function() {
  return /** @type {!proto.SentimentData.Analyzer} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.SentimentData.Analyzer} value
 * @return {!proto.SentimentData} returns this
 */
proto.SentimentData.prototype.setAnalyzer = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional float score = 5;
 * @return {number}
 */
proto.SentimentData.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SentimentData} returns this
 */
proto.SentimentData.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AudioSegment.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AudioSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.AudioSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AudioSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AudioSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    turnId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    end: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    turnStr: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sentenceList: jspb.Message.toObjectList(msg.getSentenceList(),
    proto.Sentence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AudioSegment}
 */
proto.AudioSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AudioSegment;
  return proto.AudioSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AudioSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AudioSegment}
 */
proto.AudioSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTurnId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnStr(value);
      break;
    case 5:
      var value = new proto.Sentence;
      reader.readMessage(value,proto.Sentence.deserializeBinaryFromReader);
      msg.addSentence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AudioSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AudioSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AudioSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AudioSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTurnId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTurnStr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSentenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Sentence.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 turn_id = 1;
 * @return {number}
 */
proto.AudioSegment.prototype.getTurnId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.AudioSegment} returns this
 */
proto.AudioSegment.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float start = 2;
 * @return {number}
 */
proto.AudioSegment.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AudioSegment} returns this
 */
proto.AudioSegment.prototype.setStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float end = 3;
 * @return {number}
 */
proto.AudioSegment.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AudioSegment} returns this
 */
proto.AudioSegment.prototype.setEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string turn_str = 4;
 * @return {string}
 */
proto.AudioSegment.prototype.getTurnStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AudioSegment} returns this
 */
proto.AudioSegment.prototype.setTurnStr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Sentence sentence = 5;
 * @return {!Array<!proto.Sentence>}
 */
proto.AudioSegment.prototype.getSentenceList = function() {
  return /** @type{!Array<!proto.Sentence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Sentence, 5));
};


/**
 * @param {!Array<!proto.Sentence>} value
 * @return {!proto.AudioSegment} returns this
*/
proto.AudioSegment.prototype.setSentenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Sentence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Sentence}
 */
proto.AudioSegment.prototype.addSentence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Sentence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AudioSegment} returns this
 */
proto.AudioSegment.prototype.clearSentenceList = function() {
  return this.setSentenceList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Sentence.repeatedFields_ = [2,7,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Sentence.prototype.toObject = function(opt_includeInstance) {
  return proto.Sentence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Sentence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Sentence.toObject = function(includeInstance, msg) {
  var f, obj = {
    str: jspb.Message.getFieldWithDefault(msg, 1, ""),
    annotationList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    sentimentList: jspb.Message.toObjectList(msg.getSentimentList(),
    proto.SentimentData.toObject, includeInstance),
    namedEntityList: jspb.Message.toObjectList(msg.getNamedEntityList(),
    proto.NamedEntity.toObject, includeInstance),
    startTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    endTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Sentence}
 */
proto.Sentence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Sentence;
  return proto.Sentence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Sentence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Sentence}
 */
proto.Sentence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStr(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.Sentence.Annotation>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAnnotation(values[i]);
      }
      break;
    case 7:
      var value = new proto.SentimentData;
      reader.readMessage(value,proto.SentimentData.deserializeBinaryFromReader);
      msg.addSentiment(value);
      break;
    case 4:
      var value = new proto.NamedEntity;
      reader.readMessage(value,proto.NamedEntity.deserializeBinaryFromReader);
      msg.addNamedEntity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Sentence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Sentence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Sentence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Sentence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnnotationList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getSentimentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.SentimentData.serializeBinaryToWriter
    );
  }
  f = message.getNamedEntityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.NamedEntity.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Sentence.Annotation = {
  DEFAULT: 0,
  SHORT: 1,
  GREETING: 2,
  BYE: 3,
  FOLLOWUP: 4,
  COMPETITORS: 5,
  PRICING: 6,
  ACKNOWLEDGEMENT: 7,
  QUESTION: 8,
  PRED_PRICING: 9,
  PRED_PRODUCT_INFO: 10,
  PRED_NEXT_STEPS: 11,
  PRED_OTHER: 12
};

/**
 * optional string str = 1;
 * @return {string}
 */
proto.Sentence.prototype.getStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.setStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Annotation annotation = 2;
 * @return {!Array<!proto.Sentence.Annotation>}
 */
proto.Sentence.prototype.getAnnotationList = function() {
  return /** @type {!Array<!proto.Sentence.Annotation>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.Sentence.Annotation>} value
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.setAnnotationList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.Sentence.Annotation} value
 * @param {number=} opt_index
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.addAnnotation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.clearAnnotationList = function() {
  return this.setAnnotationList([]);
};


/**
 * repeated SentimentData sentiment = 7;
 * @return {!Array<!proto.SentimentData>}
 */
proto.Sentence.prototype.getSentimentList = function() {
  return /** @type{!Array<!proto.SentimentData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SentimentData, 7));
};


/**
 * @param {!Array<!proto.SentimentData>} value
 * @return {!proto.Sentence} returns this
*/
proto.Sentence.prototype.setSentimentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.SentimentData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SentimentData}
 */
proto.Sentence.prototype.addSentiment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.SentimentData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.clearSentimentList = function() {
  return this.setSentimentList([]);
};


/**
 * repeated NamedEntity named_entity = 4;
 * @return {!Array<!proto.NamedEntity>}
 */
proto.Sentence.prototype.getNamedEntityList = function() {
  return /** @type{!Array<!proto.NamedEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NamedEntity, 4));
};


/**
 * @param {!Array<!proto.NamedEntity>} value
 * @return {!proto.Sentence} returns this
*/
proto.Sentence.prototype.setNamedEntityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.NamedEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NamedEntity}
 */
proto.Sentence.prototype.addNamedEntity = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.NamedEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.clearNamedEntityList = function() {
  return this.setNamedEntityList([]);
};


/**
 * optional float start_time = 5;
 * @return {number}
 */
proto.Sentence.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float end_time = 6;
 * @return {number}
 */
proto.Sentence.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Sentence} returns this
 */
proto.Sentence.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.TranscribeEngine = {
  DEFAULT_TRANSCRIBE_ENGINE: 0,
  GOOGLE_TRANSCRIBE_ENGINE: 1,
  LIV_TRANSCRIBE_ENGINE: 2,
  ASSEMBLYAI_TRANSCRIBE_ENGINE: 3,
  KALDI_TRANSCRIBE_ENGINE: 4,
  DEEP_SPEECH_TRANSCRIBE_ENGINE: 5,
  AMAZON_TRANSCRIBE_ENGINE: 6,
  DEEPGRAM_TRANSCRIBE_ENGINE: 7,
  KNOWLARITY_TRANSCRIBE_ENGINE: 8,
  WHISPER_TRANSCRIBE_ENGINE: 9
};

/**
 * @enum {number}
 */
proto.CallProcessorType = {
  PYWORKER: 0,
  JARVIS: 1
};

/**
 * @enum {number}
 */
proto.TranscriptionType = {
  ONLINE: 0,
  OFFLINE: 1,
  PARTIAL: 2
};

/**
 * @enum {number}
 */
proto.LanguageCode = {
  DEFAULT_LANGUAGE: 0,
  ENGLISH_US: 1,
  SPANISH_US: 2,
  PORTUGUESE_BR: 3,
  DANISH_DK: 4,
  SWEDISH_SE: 5,
  FRENCH_FR: 6,
  DUTCH_NL: 7,
  GERMAN_DE: 8,
  ITALIAN_IT: 9,
  TURKISH_TR: 10,
  NORWEGIAN_NO: 11,
  INDONESIAN_ID: 12,
  RUSSIAN_RU: 13,
  HINDI_IN: 14,
  ENGLISH_IN: 15,
  ARABIC_BH: 16,
  ENGLISH_GB: 17,
  JAPANESE_JP: 18,
  MANDARIN: 19,
  ROMANIAN_RO: 20,
  POLISH_PL: 21,
  ENGLISH_GLOBAL: 22,
  KOREAN_KR: 23,
  FINNISH_FI: 24,
  UKRAINIAN_UA: 25,
  CATALAN_ES: 26
};

/**
 * @enum {number}
 */
proto.QueueType = {
  NORMAL: 0,
  BATCH: 1,
  PARTIAL_QUEUE: 2
};

/**
 * @enum {number}
 */
proto.DiarizationStatus = {
  DIARIZATION_INITIATED: 0,
  DIARIZATION_COMPLETED: 1
};

/**
 * @enum {number}
 */
proto.TranscriptionStatus = {
  TRANSCRIPTION_INITIATED: 0,
  TRANSCRIPTION_COMPLETED: 1
};

/**
 * @enum {number}
 */
proto.PredictionStatus = {
  PREDICTION_INITIATED: 0,
  PREDICTION_COMPLETED: 1
};

/**
 * @enum {number}
 */
proto.DiarizationEngine = {
  DEFAULT_DIARIZATION_ENGINE: 0,
  KALDI_DIARIZATION_ENGINE: 1,
  DEEPAFFECTS_DIARIZATION_ENGINE: 2,
  GOOGLE_DIARIZATION_ENGINE: 3,
  AMAZON_DIARIZATION_ENGINE: 4,
  PYANNOTE_DIARIZATION_ENGINE: 5
};

/**
 * @enum {number}
 */
proto.ProcessAudioStage = {
  START_ALL: 0,
  TRANSCRIBE: 1,
  PROCESS_TRANSCRIPT: 2,
  DIARIZE_PROCESS_TRANSCRIPT: 3,
  UPDATE_PHRASES: 4,
  UPDATE_SUMMARY: 5,
  UPDATE_AUDIO_METADATA: 6,
  DIARIZE: 7,
  UPDATE_PREDICTIONS: 8
};

/**
 * @enum {number}
 */
proto.DiarizeStage = {
  DIARIZE_AND_RECOGNIZE: 0,
  RECOGNIZE: 1
};

/**
 * @enum {number}
 */
proto.CallDisposition = {
  UNKNOWN_CALL_DISPOSITION: 0,
  CALL_CONNECTED_WITH_PROSPECT: 1,
  CALL_DID_NOT_CONNECT_WITH_PROSPECT: 2,
  CALL_NOBODY_JOINED: 3,
  CALL_BOTJOIN_DENIED: 4,
  CALL_BOTJOIN_PREVENTED: 5,
  CALL_BOTJOIN_UNSUCCESSFUL: 6
};

/**
 * @enum {number}
 */
proto.PartialCallStatus = {
  CHUNK_TRANSCRIBED: 0,
  CHUNK_FAILED: 1,
  CHUNK_EMPTY: 2,
  CHUNK_PROCESSED: 3
};

/**
 * @enum {number}
 */
proto.CallStatus = {
  UNKNOWN_CALL_STATUS: 0,
  SCHEDULED: 1,
  INITIATED: 2,
  INPROGRESS: 3,
  BUSY: 4,
  NO_ANSWER: 5,
  FAILED: 6,
  CANCELED: 7,
  COMPLETED: 8,
  WAITING_IN_QUEUE: 15,
  WAITING_IN_PYWORKER_QUEUE: 34,
  WAITING_IN_PYANNOTE_QUEUE: 35,
  WAITING_IN_WHISPER_QUEUE: 36,
  WAITING_IN_SUMMARY_BUILDER_QUEUE: 37,
  WAITING_IN_PREDICTION_QUEUE: 38,
  WAITING_IN_CONVERSATION_BUILDER_QUEUE: 39,
  WAITING_IN_ORCHESTRATOR_QUEUE: 40,
  PROCESSING: 9,
  PROCESSED: 10,
  QUEUED_FOR_DOWNLOADING_RECORDING_URL: 30,
  QUEUED_FOR_DOWNLOAD: 31,
  ERROR_IN_TRANSCRIBE: 11,
  ERROR_IN_PROCESSING: 12,
  ERROR_IN_RECORDING: 16,
  UNABLE_TO_JOIN: 21,
  FAILED_TO_DOWNLOAD: 23,
  INCALL_SOURCE_ID_ABSENT: 24,
  INCALL_USER_INFO_ABSENT: 25,
  INCALL_RECORDING_URL_ABSENT: 26,
  INCALL_USER_INFO_INVALID: 27,
  INCALL_USER_NON_RECORDING: 28,
  INCALL_USER_VOICE_FINGERPRINTING_FLOW_DISABLED: 29,
  FAILED_TO_EXTRACT_AUDIO_FROM_VIDEO: 32,
  CALL_DID_NOT_HAPPEN: 22,
  IGNORED_BY_USER: 18,
  BOTJOIN_DISABLED: 19,
  CONSENT_REVOKED: 33,
  POST_PROCESSING_DONE: 13,
  NO_DATA_INCALL: 14,
  NOBODY_JOINED_CALL: 17,
  BOTJOIN_DENIED: 20,
  ERROR_IN_HIGHLIGHTS: 41,
  MEDIA_FILE_NOT_FOUND: 42
};

/**
 * @enum {number}
 */
proto.CallType = {
  UNKNOWN_CALL_TYPE: 0,
  ZOOM: 1,
  PHONE_CONFERENCE: 2,
  PHONE_CALL: 3,
  GENERIC: 4,
  RECORDING: 5,
  GOOGLE_MEET: 6,
  ZOOM_CLOUD_RECORDING: 7,
  FRESHCALLER: 8,
  AIRCALL_RECORDING: 9,
  RINGCENTRAL: 10,
  GOTO_MEETING: 11,
  OUTREACH: 12,
  HUBSPOT: 13,
  RINGCENTRAL_MEETING: 14,
  BLUE_JEANS: 15,
  ADHOC_LIVE: 16,
  HUBSPOT_LIVE: 17,
  FRESHCALLER_LIVE: 18,
  FOLLOWUPBOSS_LIVE: 19,
  SALESLOFT: 20,
  MS_TEAMS: 21,
  DIALPAD_LIVE: 22,
  DIALPAD: 23,
  FRONTSPIN: 24,
  ZOOM_PHONE: 25,
  VENDASTA: 26,
  KNOWLARITY: 27,
  CALL_HIPPO: 28,
  RINGOVER: 29,
  CLOSEIO: 30,
  GROOVE: 31,
  TALKDESK: 32,
  WEBEX: 33
};

goog.object.extend(exports, proto);
