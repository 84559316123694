import React, { useContext } from 'react';
import { Alert, Grid, Snackbar } from '@mui/material';
import GetGptPlaygroundComponent from './GetGptPlaygroundComponent';
import {
	GptPlaygroundContext,
	GptPlaygroundDispatchContext,
} from '../redux/context';
import CircularLoadingIndicator from '../../../components/common/CircularLoadingIndicator';
import { actionTypes } from '../redux';

export default function GptPlaygroundFoundation() {
	const gptPlaygroundState = useContext(GptPlaygroundContext);
	const gptPlaygroundDispatcher = useContext(GptPlaygroundDispatchContext);

	const {
		alert: { hasError, message },
		loading: isLoading,
	} = gptPlaygroundState;

	const handleClose = () => {
		gptPlaygroundDispatcher({
			type: actionTypes.RESET_ALERT,
		});
	};

	return (
		<>
			<CircularLoadingIndicator open={isLoading} />
			<Snackbar
				open={hasError}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
			>
				<Alert onClose={handleClose} severity="error">
					{message}
				</Alert>
			</Snackbar>
			<Grid
				container
				rowGap="1rem"
				align="center"
				sx={{
					width: '100%',
					backgroundColor: 'brick',
				}}
			>
				<Grid item xs={12}>
					<GetGptPlaygroundComponent />
				</Grid>
			</Grid>
		</>
	);
}
