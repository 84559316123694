import React, { useContext } from 'react';
import GptPlaygroundInputComponent from './GptPlaygroundInputComponent';
import screens from '../screens';
import SummaryComponent from './SummaryComponent';
import {
	actionTypes,
	GptPlaygroundContext,
	GptPlaygroundDispatchContext,
} from '../redux';
import { useGptPlaygroundActions } from '../customHooks';

export default function GetGptPlaygroundComponent() {
	const {
		screen: { currScreen },
	}  = useContext(GptPlaygroundContext);

	switch (currScreen) {
		case screens.userGptPromptInputScreen:
			return (
				<GptPlaygroundInputComponent />
			);

		case screens.showSummaryScreen:
			return (
				<SummaryComponent />
			);

		default:
			return <div>Something went wrong! Please contact the IT team!</div>;
	}
}
