const actionTypes = {
    UPDATE_SCREEN_TYPE: 'UPDATE-SCREEN-TYPE',
    UPDATE_SCREEN_DATA: 'UPDATE-SCREEN-DATA',
    UPDATE_SCREEN: 'UPDATE-SCREEN',
    SET_LOADING: 'SET-LOADING',
    RESET_LOADING: 'RESET-LOADING',
    SET_ALERT: 'SET-ALERT',
    RESET_ALERT: 'RESET-ALERT',
    SET_SUMMARY: 'SET-SUMMARY',
    RESET_SUMMARY: 'RESET-SUMMARY',
};

export default actionTypes;