import {
	Paper,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	Box,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import UserEmail from './UserEmail';
import StorageIcon from '@mui/icons-material/Storage';
import { getConfig } from './config';
import IntegrationMetrics from './IntegrationMetrics';
import { PhoneForwarded } from '@mui/icons-material';
import DebugCRM from './DebugCRM';
import SyncIcon from '@mui/icons-material/Sync';
import InboxIcon from '@mui/icons-material/Inbox';
import PushCallCRM from './PushCallCRM';
import RecorderExperiments from 'RecorderExperiments';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import VideoPostProcessor from 'RecorderVideoProcessor';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const envMarker = getConfig().envMarker;

export default function CustomerSupportTools(props) {
	const debugPages = [
		{
			key: 'User Email',
			component: <UserEmail />,
			icon: <InboxIcon />,
			label: 'User Email',
		},
		{
			key: 'Push Call CRM',
			component: <PushCallCRM />,
			icon: <PhoneForwarded />,
			label: 'Push Call CRM',
		},
		{
			key: 'Debug CRM',
			component: <DebugCRM />,
			icon: <SyncIcon />,
			label: 'Debug CRM',
		},
		{
			key: 'integration_metrics',
			component: <IntegrationMetrics />,
			icon: <StorageIcon />,
			label: 'Integration Metrics',
		},
		{
			key: 'Recorder Experiments',
			component: <RecorderExperiments />,
			icon: <MicOutlinedIcon />,
			label: 'Recorder Experiments',
		},
		{
			key: 'SQS SENDER',
			component: <VideoPostProcessor />,
			icon: <OndemandVideoIcon />,
			label: 'Recorder Video Post Processor',
		},
	];

	const [selectedDebugPage, setSelectedDebugPage] = useState('User Email');

	useEffect(() => {
		const hash =
			window.location.hash.length > 0
				? window.location.hash.substr(1)
				: null;
		if (!hash) return;
		debugPages.forEach((page) => {
			if (hash.startsWith(page.key)) setSelectedDebugPage(page.key);
		});
	}, []);

	return (
		<Paper style={{ padding: '10px' }}>
			<Box display="flex" flexDirection="row">
				<Box flex="0 0 0">
					<List>
						<ListItem button component="a" href="../">
							<ListItemIcon title="Back">
								<KeyboardArrowLeftIcon />
							</ListItemIcon>
						</ListItem>
					</List>
					<Divider />
					<List>
						{debugPages.map((page) => (
							<ListItem
								button
								onClick={(e) => setSelectedDebugPage(page.key)}
								key={page.key}
								selected={page.key === selectedDebugPage}
							>
								<ListItemIcon title={page.label}>
									{page.icon}
								</ListItemIcon>
							</ListItem>
						))}
					</List>
				</Box>
				<Box
					flex="1 0 auto"
					style={{
						padding: '15px',
						height: '700px',
						overflowY: 'scroll',
					}}
				>
					{
						debugPages.filter(
							(page) => page.key === selectedDebugPage
						)[0].component
					}
				</Box>
			</Box>
		</Paper>
	);
}
