import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Input,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import Excel from 'exceljs';
import { CALL_DELETE_TYPE, RECORD_TYPE } from 'utils/constants';
import DeletionRequestsTable from 'data_deletion/DeletionRequestsTable';
import {
    getAllCustomers,
    getAllDeletionRequestsForCustomer,
    getAllManagersForCustomer,
} from 'utils/network';
import { validateEmail } from 'utils/helpers';
import { formElement } from '../components/common/FormControls';
import { getData, getJsonData } from '../network';

const customStyles = {
    control: (base, state) => ({
        ...base,
        marginTop: '1rem',
        background: '#F5F5F7',
        // Overwrittes the different states of border
        borderColor: state.isFocused ? 'black' : 'rgba(0,0,0,0.6)',
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
            borderColor: 'black',
        },
    }),
};

function DataDeletionTool() {
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedManagers, setSelectedManagers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [allManagers, setAllManagers] = useState([]);
    const [allDeletionRequests, setAllDeletionRequests] = useState([]);
    const [thresholdDate, setThresholdDate] = useState();
    const [customerPocEmail, setCustomerPocEmail] = useState('');
    const [participantEmail, setParticipantEmail] = useState('');
    const [participantPhoneNumer, setParticipantPhoneNumber] = useState('');
    const [allEmails, setAllEmails] = useState([]);
    const [allPhones, setAllPhones] = useState([]);
    const [recordTypes, setRecordTypes] = useState(new Set([RECORD_TYPE.CALLS]));
    const [disabledGenerateButton, setDisabledGenerateButton] = useState(false);

    useEffect(async () => {
        const allCustomerOptions = await getAllCustomers();
        setCustomers(allCustomerOptions);
    }, []);

    useEffect(async () => {
        if (!selectedCustomer) return;
        const customerId = selectedCustomer?.value;
        clearFormData();
        // update managers list
        const allManagersLocal = await getAllManagersForCustomer(customerId);
        setAllManagers(allManagersLocal);
        // update deletion requests
        updateAllDeletionRequestsForCustomer();
    }, [selectedCustomer]);

    const updateAllDeletionRequestsForCustomer = async () => {
        const deletionRequestsLocal = await getAllDeletionRequestsForCustomer(
            selectedCustomer?.value,
        );
        setAllDeletionRequests(deletionRequestsLocal);
    };

    const handleAddParticipantEmail = (e) => {
        if (e.key !== 'Enter') return;
        const email = e.target?.value?.trim();
        if (!email) return;
        if (!validateEmail(email)) {
            alert('Invalid email address');
            return;
        }
        if (allEmails.includes(email)) {
            alert('Email already added');
            return;
        }
        setAllEmails([...new Set([...allEmails, email])]);
        setParticipantEmail('');
    };

    const handleDeleteParticipantEmail = (emailToDelete) => {
        setAllEmails(allEmails.filter((email) => emailToDelete !== email));
    };

    const handleAddParticipantPhoneNumber = (e) => {
        if (e.key !== 'Enter') return;
        const phone = e.target?.value?.trim();
        if (!phone) return;
        if (allPhones.includes(phone)) {
            alert('Phone number already added');
            return;
        }
        setAllPhones([...new Set([...allPhones, phone])]);
        setParticipantPhoneNumber('');
    };

    const handleDeleteParticipantPhoneEmail = (phoneToDelete) => {
        setAllPhones(allPhones.filter((phone) => phoneToDelete !== phone));
    };

    // const handleDeleteTypeChange = (e) => {
    //     setDeleteType(e.target.value);
    // };

    const generateDeleteCallDetails = async () => {
        const deleteCallParams = {
            customerId: selectedCustomer?.value,
            emailId: 'akumar@clari.com', // clari_am, not required to fill
            deleteUpto: thresholdDate,
            customerPocEmail,
            deleteType: CALL_DELETE_TYPE.ALL,
            externalEmails: allEmails,
            externalPhones: allPhones,
            managerIds: selectedManagers.map((manager) => manager._id),
            recordTypes: [...recordTypes],
        };
        setDisabledGenerateButton(true);
        const response = await getData(
            '/delete_call_data/request',
            deleteCallParams,
        );
        setDisabledGenerateButton(false);
        const responseJSON = await response.json();
        if (response.status !== 200) {
            alert(responseJSON?.error);
            return;
        }
        updateAllDeletionRequestsForCustomer();
        clearFormData();
        alert(
            responseJSON?.message
				|| 'List of delete items are getting generated, please check back in minute',
        );
    };

    const clearFormData = () => {
        setThresholdDate('');
        setCustomerPocEmail('');
        setAllEmails([]);
        setAllPhones([]);
        setSelectedManagers([]);
        setRecordTypes(new Set([RECORD_TYPE.CALLS]));
    };

    const handleRecordTypeUpdate = (recordType, isChecked) => {
		const records = new Set(recordTypes);
		if (isChecked) records.add(recordType);
		else records.delete(recordType);
		console.log(recordTypes, records);
		setRecordTypes(records);
	};

    return (
      <div
          style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                marginBottom: '5rem',
            }}
        >
          {formElement(
              <Select
                  options={customers}
                  value={selectedCustomer}
                  onChange={(opt) => setSelectedCustomer(opt)}
                  isSearchable
                  placeholder="Select Customer*"
                  styles={customStyles}
                />,
            )}

          <TextField
              style={{ width: '12.5rem', marginTop: '1.75rem' }}
              InputLabelProps={{ shrink: true }}
              label="Delete all calls made before*"
              type="date"
              value={thresholdDate}
              onChange={(e) => setThresholdDate(e.target?.value)}
            />
          <TextField
              style={{ width: '40rem', marginTop: '1.75rem' }}
              InputLabelProps={{ shrink: true }}
              label="Deletion Requested by (Enter email address of point of contact from Customer who requested data to be deleted)*"
              value={customerPocEmail}
              onChange={(e) => setCustomerPocEmail(e.target.value)}
            />
          <div
              style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '3rem 0 1rem 0',
                    gap: '0.5rem',
                }}
            >
              <div style={{ color: 'rgba(0,0,0,0.6)', fontSize: '0.8rem' }}>
                  Only delete calls where participants include any of these:
				</div>
              <FormControl variant="standard">
                  <InputLabel>{"Participant emails ( Enter one email at a time and press enter)"}</InputLabel>
                  <Input
                      value={participantEmail}
                      onChange={(e) => setParticipantEmail(e?.target?.value)}
                      onKeyDown={handleAddParticipantEmail}
                    />
                  <div>
                      {allEmails.map((email) => (
                          <Chip
                              key={email}
                              label={email}
                              onDelete={() => handleDeleteParticipantEmail(email)}
                            />
                        ))}
                    </div>
                </FormControl>
              <FormControl variant="standard">
                  <InputLabel>{"Participant phone numbers ( Enter one phone number at a time and press enter)"}</InputLabel>
                  <Input
                      value={participantPhoneNumer}
                      onChange={(e) => setParticipantPhoneNumber(e?.target?.value)}
                      onKeyDown={handleAddParticipantPhoneNumber}
                    />
                  <div>
                      {allPhones.map((phone) => (
                          <Chip
                              key={phone}
                              label={phone}
                              onDelete={() => handleDeleteParticipantPhoneEmail(phone)}
                            />
                        ))}
                    </div>
                </FormControl>
              {formElement(
                  <Select
                      options={allManagers}
                      value={selectedManagers}
                      onChange={(opt) => setSelectedManagers(opt)}
                      isSearchable
                      placeholder="Select Managers"
                      isMulti
                      closeMenuOnSelect={false}
                      styles={customStyles}
                    />,
                )}
            </div>
            <FormControl style={{ margin: '1.5rem 0' }}>
				<FormControlLabel
					value={CALL_DELETE_TYPE.CALLS_ONLY}
					control={
						<Checkbox
							checked={recordTypes.has(RECORD_TYPE.GAMETAPES)}
							onChange={(e) =>
								handleRecordTypeUpdate(
									RECORD_TYPE.GAMETAPES,
									e.target.checked
								)
							}
						/>
					}
					label="Delete Gametapes"
				/>
				<FormControlLabel
					value={CALL_DELETE_TYPE.ALL}
					control={
						<Checkbox
							checked={recordTypes.has(RECORD_TYPE.SNIPPETS)}
							onChange={(e) =>
								handleRecordTypeUpdate(
									RECORD_TYPE.SNIPPETS,
									e.target.checked
								)
							}
						/>
					}
					label="Delete Snippets"
				/>
			</FormControl>
          <div
              style={{
                    margin: '2rem 0 0 ',
                    color: 'red',
                    gap: '0.5rem',
                    whiteSpace: 'pre',
                }}
            >
              {
                    'When calls are deleted, all gametapes and shared snippets associated with the calls will be lost too.\nSo, please download the generated call details and verify the calls before triggering a deletion.'
                }
            </div>

          <Button
              variant="contained"
              style={{ width: '15rem', margin: '1.5rem 0' }}
              onClick={generateDeleteCallDetails}
              disabled={disabledGenerateButton}
            >
              Generate call details
            </Button>
          <DeletionRequestsTable
              allDeletionRequests={allDeletionRequests}
              updateAllDeletionRequestsForCustomer={
                    updateAllDeletionRequestsForCustomer
                }
                allManagers={allManagers}
            />
        </div>
    );
}

const getAttendeeEmailOrPhone = (attendees) => {
    if (!Array.isArray(attendees) || attendees.length === 0) return [];
    const attendeeEmailsOrPhones = attendees?.reduce(
        (attendeeEmailsOrPhones, attendee) => {
            if (attendee?.email) attendeeEmailsOrPhones.push(attendee?.email);
            else if (attendee?.phoneNumber) attendeeEmailsOrPhones.push(attendee?.phoneNumber);
            return attendeeEmailsOrPhones;
        },
        [],
    );
    return attendeeEmailsOrPhones;
};

export default DataDeletionTool;
