import React, {Component} from 'react';
import { Paper, Grid, TextField, IconButton, Snackbar, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getJsonData, postJson } from './network';

export default class PredictionModels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            models: [],
            kind: 'fastai',
            type: '',
            version: ''
        };
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        getJsonData('/models/get-models')
            .then(models => this.setState({loading: false, models}))
            .catch(console.error);
    }

    createModel() {
        postJson('/models/create-model', {type: this.state.type, version: this.state.version, kind: this.state.kind})
            .then(resp => resp.json())
            .then(resp => {
                this.setState({type: '', version: '', kind: 'fastai'});
                this.loadData();
                if (resp.err) alert(resp.err);
            }).catch(console.error);
    }

    render() {
        return (<div>
            <Snackbar open={this.state.loading} message="Loading..." />
            <Paper style={{padding: "20px"}}>
                <Typography variant="h5">Add new model</Typography>
                <FormControl style={{width: '120px', marginRight: '10px'}}>
                    <InputLabel>Model kind</InputLabel>
                    <Select
                        onChange={e => this.setState({kind: e.target.value})}
                        value={this.state.kind}>
                        {['fastai', 'tf'].map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField label="Model type" value={this.state.type} onChange={e => this.setState({type: e.target.value})} style={{marginRight: "10px"}} />
                <TextField label="Default version" value={this.state.version} onChange={e => this.setState({version: e.target.value})} />
                <IconButton onClick={() => this.createModel()}>
                    <AddCircleIcon />
                </IconButton>
            </Paper>
            <Typography style={{margin: "20px"}} variant="h5">Existing models</Typography>
            <Grid container spacing={3}>
                {this.state.models.map(model => <Grid item xs="3">
                    <Link key={model._id} to={'/prediction_models/' + model.type} style={{textDecoration: 'none'}}>
                        <Paper style={{padding: "30px", textAlign: 'center'}}>{model.type}</Paper>
                    </Link>
                </Grid>)}
            </Grid>
        </div>);
    }
};