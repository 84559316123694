import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TableChartIcon from '@mui/icons-material/TableChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { getData } from 'network';
import ObjectTreeView from './ObjectTreeView';
import ExperimentTable from './ExperimentTable';

const useStyles = makeStyles({
	recorderExperiments: {
		display: 'flex',
		height: '100vh',
	},
	content: {
		flexGrow: 1,
		padding: '20px',
		backgroundColor: '#fff',
		overflowY: 'auto',
	},
	sidebar: {
		width: 250,
		backgroundColor: '#f4f4f4',
		minHeight: '100vh',
		padding: '10px',
	},
});

function RecorderExperiments() {
	const classes = useStyles();
	const [experimentsByState, setExperimentsByState] = useState({});
	const [allExperiments, setAllExperiments] = useState([]);
	const [selectedSidebarItem, setSelectedSidebarItem] = useState('home');
	const [selectedState, setSelectedState] = useState('CREATED');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getData(
					'/support_tools/experiments/all'
				);
				const data = await response.json();
				const organizedData = organizeDataByState(data);
				setExperimentsByState(organizedData);
				setAllExperiments(data);
			} catch (error) {
				console.error('Error fetching experiment data:', error);
			}
		};

		fetchData();
	}, []);

	const organizeDataByState = (data) =>
		data.reduce((acc, experiment) => {
			const { state } = experiment;
			acc[state] = [...(acc[state] || []), experiment];
			return acc;
		}, {});

	return (
		<div className={classes.recorderExperiments}>
			<Paper className={classes.sidebar} elevation={4}>
				<List>
					<ListItem
						button
						selected={selectedSidebarItem === 'home'}
						onClick={() => setSelectedSidebarItem('home')}
					>
						<ListItemIcon>
							<HomeRoundedIcon />
						</ListItemIcon>
						<ListItemText primary="Home" />
					</ListItem>
					<ListItem
						button
						selected={selectedSidebarItem === 'operations'}
						onClick={() => setSelectedSidebarItem('operations')}
					>
						<ListItemIcon>
							<TableChartIcon />
						</ListItemIcon>
						<ListItemText primary="Recorder Operations" />
					</ListItem>
					<ListItem
						button
						selected={selectedSidebarItem === 'settings'}
						onClick={() => setSelectedSidebarItem('settings')}
					>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</ListItem>
				</List>
			</Paper>
			<div className={classes.content}>
				{selectedSidebarItem === 'home' && (
					<>
						<Typography variant="h4" gutterBottom>
							Welcome to Recorder Experiments
						</Typography>
						<Tabs
							value={selectedState}
							onChange={(event, newValue) =>
								setSelectedState(newValue)
							}
							aria-label="State Tabs"
							variant="scrollable"
						>
							{Object.keys(experimentsByState).map((state) => (
								<Tab key={state} label={state} value={state} />
							))}
						</Tabs>
						<ObjectTreeView
							name="Experiments"
							obj={experimentsByState[selectedState] || {}}
						/>
					</>
				)}
				{selectedSidebarItem === 'operations' && (
					<ExperimentTable experiments={allExperiments} />
				)}
				{selectedSidebarItem === 'settings' && (
					<Typography variant="h6" style={{ padding: 20 }}>
						Settings content goes here.
					</Typography>
				)}
			</div>
		</div>
	);
}

export default RecorderExperiments;
