import { getConfig } from './config';
import { Logout } from './Login';

const config = getConfig();
const backendUrl = config.backendUrl;

export function getData(url, params, signal) {
    // Include the signal in the options passed to fetch
    const options = {
        method: 'GET',
        signal: signal,
    };
    // Construct the full URL with query parameters if they exist
    const fullUrl =
        url + (params ? `?${new URLSearchParams(params).toString()}` : '');
    return fetchWithCreds(fullUrl, options);
}

export function getJsonData(url) {
    return getData(url).then((res) => res.json());
}

export function postJson(
    url,
    data,
    extraHeaders = { 'Content-Type': 'application/json' }
) {
    return postData(url, JSON.stringify(data), extraHeaders);
}

export function postWithoutParsingBody(url, data) {
    return postData(url, data);
}

export function postData(url, body, headers) {
    return fetchWithCreds(url, {
        method: 'POST',
        headers,
        body,
    });
}

export function fetchWithCreds(url, opts = {}, logoutOnUnauthenticated = true) {
    const fetchOptions = { ...opts, credentials: 'include' };
    return fetch(backendUrl + url, fetchOptions).then((res) => {
        if (logoutOnUnauthenticated && res.status === 401) {
            alert('Unauthorized, please login again');
            Logout();
        }
        return res;
    });
}
