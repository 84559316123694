import { v4 as uuid } from 'uuid';

const defaultTrackers = [
    {
        categoryName: "Upfront Contract",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "Appreciate that you", "appreciate your", "Super appreciative of", "Are we still good", "next one hour", "next thirty minutes", "next 30 minutes", "next thirty minutes", "next 1 hour", "next one hour", "ask us some questions", "ask me questions", "Would that be OK", "Is there anything else that you would like to cover today", "I will also need to understand your", "by the end of our meeting", "by the end of the meeting"],
        appliesTo: "AGENT"
    },
    {
        categoryName: "Agenda Setting",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "Agenda", "will cover", "in this call", "purpose of the call", "the way we go about this call", "together if we are a fit", "anything else you would like us to cover"],
        appliesTo: "ALL"
    },
    {
        categoryName: "Competition",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "additional vendors", "alternative solutions", "competition", "competitive landscape", "competitor", "competitors", "differentiate", "differentiator", "differentiators", "evaluation process", "exploring alternatives", "look at alternatives", "look for alternatives", "look into alternatives", "looking at alternatives", "looking for alternatives", "looking into alternatives", "other solution", "other solutions", "other tools", "other vendors", "our competitors", "short list", "stack up", "stacks up", "versus the alternatives", "Where are you at with the other tool options", "How would you rank the options right now", "Which one is leading the pack", "Do you have a preference", "How would you share the differences with your team", "your vendor of choice", "Has anyone at your team used another", "What’s been your experience with", "What competitors have you"],
        appliesTo: "ALL"
    },
    {
        categoryName: "Process",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "a quote", "annual contract", "approval process", "best decision", "commercial discussion", "concern", "concerns", "contract", "decision", "decision criteria", "decision making process", "decision process", "deployment", "DocuSign", "EchoSign", "evaluation", "formal decision", "free trial", "full decision", "funding", "give it a try", "go live", "good decision", "green light", "have a decision", "implementation", "implementation manager", "implementation team", "informed decision", "make a decision", "make the decision", "monthly contract", "move forward", "my approval", "my decision", "my process", "negotiation", "new contract", "onboarding", "paid trial", "paper work", "paperwork", "pilot", "POC", "procure", "procurement", "proof of concept", "proposal", "purchase", "purchase decision", "purchase order", "purchasing decision", "request for quote", "requirements", "RFI", "RFP", "RFQ", "right decision", "short list", "signature", "simple decision", "three year contract", "to sign", "trial", "two year contract", "vendor selection", "we sign", "you sign"],
        appliesTo: "ALL"
    },
    {
        categoryName: "Budget",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "a budget conversation", "budget", "budgeted", "budgeting decision", "budgeting decisions", "budgets", "funding", "funds", "I have the budget", "my budget", "our budget", "your budget"],
        appliesTo: "ALL"
    },
    {
        categoryName: "Authority",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "approve", "CFO", "committee", "decision maker", "decision makers", "director", "GM", "her boss", "his boss", "my boss", "my CEO", "my director", "my manager", "my VP", "our CEO", "president", "project manager", "stakeholders", "VP", "your role"],
        appliesTo: "CUSTOMER"
    },
    {
        categoryName: "Timing",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "2020", "2021", "a good time", "after the holiday", "end of the month", "end of the quarter", "end of this month", "end of this quarter", "february", "fiscal year", "go live", "going live", "in six months", "move quickly", "next month", "next quarter", "next year", "not a good time", "Q1", "Q2", "Q3", "Q4", "six months from now", "this month", "this quarter", "this year", "time frame", "time line", "timeline", "timing", "went live", "couple of months", "couple of weeks", "in two weeks", "in three weeks", "in 2 weeks", "in 3 weeks", "in 1 month", "in one month", "in 2 months", "in two months", "January", "February", "March", "April", "June", "July", "August", "September", "October", "November", "December"],
        appliesTo: "ALL"
    }, {
        categoryName: "Qualification Budget",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "what is your budget", "budget allocation", "do you have a defined budget", "have you allocated a budget", "how costly is the problem", "how do you budget for this", "Do you have allocated budget for this project"],
        appliesTo: "AGENT"
    }, {
        categoryName: "Qualification Authority",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "Who is the Signer of a contract", "Who will sign the contract", "Can you share your evaluation process", "who from each department will be involved", "Who else needs to be involved in making this decision", "Who has to sign off", "Who are the people involved in the decision process"],
        appliesTo: "AGENT"
    }, {
        categoryName: "Qualification Need",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "top business objectives", "top priorities", "underlying motivation", "what are you looking to solve", "what prompted you to look for a solution", "could you tell me more about your problem", "What do you hope to achieve", "Which solution do you currently use for", "What excited you to take this meeting", "Can you walk me through"],
        appliesTo: "AGENT"
    }, {
        categoryName: "Qualification Timeline",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "What are your timelines for this initiative", "What is your timeline", "When are you looking to", "How long do you expect", "When do you plan to", "When will you review this with your team", "What is your expected go live date", "Where are you at in your process", "How long do you expect the evaluation to take"],
        appliesTo: "AGENT"
    }, {
        categoryName: "Blockers Timing",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "this isn't a priority", "try next quarter", "in two months", "in three months", "timing isn't great", "bad timing", "check back later", "too swamped", "don't have time"],
        appliesTo: "CUSTOMER"
    }, {
        categoryName: "Blockers Budget",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "don't have the budget", "too expensive", "approval from CFO", "show ROI", "see ROI", "cheaper version", "There's no money", "don't have any budget", "don't have budget"],
        appliesTo: "CUSTOMER"
    }, {
        categoryName: "Blockers Authority",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "not the decision maker", "need to loop in", "need approval from", "needs to approve", "not the right person", "not authorized to", "need to talk to"],
        appliesTo: "CUSTOMER"
    }, {
        categoryName: "Blockers Interest",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "not interested", "send me some information"],
        appliesTo: "CUSTOMER"
    }, {
        categoryName: "Blockers Status Quo",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "We’re already using", "we already use", "I already have", "we are all set", "we aren't looking for", "I have everything I need", "locked into a contract", "don't have capacity", "not a priority", "not looking for"],
        appliesTo: "CUSTOMER"
    }, {
        categoryName: "COVID",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "Covid", "corona", "cobid", "covid 19", "pandemic", "shutdown", "lockdown", "social distancing", "stay at home"],
        appliesTo: "ALL"
    }, {
        categoryName: "Customer Goals/Pain Points",
        exact: ["__EMPTY_CATEGORY_PLACEHOLDER__", "We want to improve our", "We currently have challenges", "we need to be better at", "this is our goal", "this has been frustrating", "we are interested in using", "leveraging tools to", "we need to understand", "we are focusing on", "we want to do this faster", "we are looking for", "we want to be able to"],
        appliesTo: "CUSTOMER"
    },
]


export function getDefaultTrackers() {
    let trackers = [];
    defaultTrackers.map(category => {
        const exactKeywords = category.exact.map(phrase => {
            return {
                "phrase_id": uuid(),
                "text": phrase,
                "exact_match_required": true,
                "category": category.categoryName,
                "appliesTo": category.appliesTo
            }
        })

        trackers = trackers.concat(exactKeywords);
    });
    return trackers;
}