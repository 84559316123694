import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import MultilineTextInput from '../../../components/common/MultilineTextInput';
import PropTypes from 'prop-types';

export default function GptTextOptionsComponent(props) {
	const {
		getSummary,
		transcript,
		userPrompt,
		systemPrompt,
		showTranscript = true,
		onBackHandle,
	} = props;
	const [currSystemPrompt, setSystemPrompt] = useState(systemPrompt);
	const [currUserPrompt, setUserPrompt] = useState(userPrompt);

	function validateRequest() {
		if (currSystemPrompt.length && currUserPrompt.length) {
			return true;
		}
		return false;
	}

	const onSubmitHandle = async () => {
		if (!validateRequest()) {
			return;
		}

		await getSummary(currSystemPrompt, currUserPrompt);
	};

	return (
		<Grid sx={{ marginY: '2rem' }} container rowGap="1rem">
			{showTranscript ? (
				<Grid
					item
					sx={{
						maxHeight: '30vh',
						overflow: 'scroll',
						whiteSpace: 'pre-wrap',
						textAlign: 'start',
						lineHeight: '2.5',
						padding: '1rem',
						border: '1px solid gray',
					}}
				>
					<p>{transcript}</p>
				</Grid>
			) : null}

			<MultilineTextInput
				size="large"
				variant="outlined"
				id="system-prompt"
				label="Enter the system prompt"
				onChange={setSystemPrompt}
				value={currSystemPrompt}
				maxChars={2000}
				minHeight={'5vh'}
			/>

			<MultilineTextInput
				size="large"
				variant="outlined"
				id="user-prompt"
				label="Enter the user prompt"
				onChange={setUserPrompt}
				maxChars={2000}
				value={currUserPrompt}
				minHeight={'10vh'}
			/>

			<div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignContent: 'center',
					flexDirection: 'column',
					gap: '1rem',
					marginY: '1rem',
				}}
			>
				<Button
					size="large"
					sx={{ minWidth: '20%', marginX: '30%' }}
					variant="contained"
					onClick={onSubmitHandle}
					disabled={!validateRequest()}
				>
					Get the summary!
				</Button>
				<Button
					size="large"
					sx={{ minWidth: '20%', marginX: '30%' }}
					variant="secondary"
					onClick={onBackHandle}
				>
					{'Back'}
				</Button>
			</div>
		</Grid>
	);
}

GptTextOptionsComponent.propTypes = {
	getSummary: PropTypes.func,
	onBackHandle: PropTypes.func,
	transcript: PropTypes.string,
	userPrompt: PropTypes.string,
	systemPrompt: PropTypes.string,
	showTranscript: PropTypes.bool,
};
