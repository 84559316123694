import React from 'react';
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    Switch,
    Tooltip,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

function formElement(elem, label, opacity = 1) {
    return (
      <FormControl
          variant="standard"
          style={{ opacity }}
        >
          {label && <InputLabel>{label}</InputLabel>}
          {elem}
        </FormControl>
    );
}

function toggleSwitch({
    state,
    handleChange,
    label,
    infoMessage,
    disabled = false,
}) {
    return (
      <span
          style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
          <FormControlLabel
              label={label}
              control={(
                    <Switch
                        checked={state}
                        color="primary"
                        onChange={handleChange}
                        disabled={disabled}
                />
                )}
            />
          {infoMessage && (
            <Tooltip title={infoMessage} placement="right">
                  <InfoOutlined />
                </Tooltip>
            )}
        </span>
    );
}

export { toggleSwitch, formElement };
