import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from './Home';
import DiarizationReview from './DiarizationReview';
import Login, { Logout, isLoggedIn, isAgent } from './Login';
//import CallTranscript from './CallTranscript';
import Header from './Header';
import CustomersTable from './CustomersTable';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForumIcon from '@mui/icons-material/Forum';
import CategoryIcon from '@mui/icons-material/Category';
import PredictionModels from './PredictionModels';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ModelAssignments from './ModelAssignments';
import BugReportIcon from '@mui/icons-material/BugReport';
import Debug from './Debug';
import FaceIcon from '@mui/icons-material/Face';
import Customers from './Customers';
import Customer from './Customer';
import BuildIcon from '@mui/icons-material/Build';
import FixDiarization from './FixDiarization';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AppsIcon from '@mui/icons-material/Apps';
import Migration from './Migration';
import Deployments from './Deployments';
import { useEffect } from 'react';
import { fetchWithCreds } from './network';
import * as actions from './actions';
import { connect } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ChatGPTModels from './ChatGPTModels';
import { SportsCricket } from '@mui/icons-material';
import { GptPlayground } from 'screens';
import { GoogleOAuthProvider } from '@react-oauth/google';
import DataDeletionTool from 'data_deletion/DataDeletionTool';
import { DeleteIcon } from 'designSystem';
import { SupportAgent } from '@mui/icons-material';
import CustomerSupportTools from 'CustomerSupportTools';

const theme = createTheme({
	palette: {
		primary: {
			main: '#3f51b5',
		},
		secondary: {
			main: '#f50057',
		},
	},
	components: {
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
	},
});

const links = [
	{
		url: '/billing/',
		text: 'Billing',
		icon: <ReceiptIcon />,
		element: <CustomersTable />,
		adminOnly: true,
	},
	{
		url: '/diarization_review/',
		text: 'Diarization Review',
		icon: <ForumIcon />,
		element: <DiarizationReview />,
	},
	{
		url: '/logout/',
		element: <Logout />,
		hidden: true,
		/*}, {
  url: '/user_integration_meta_data',
  text: 'User Integration MetaData',
  icon: <SupervisorAccountOutlinedIcon />,
  element: <IntegrationMetaData level="USER" />,
  adminOnly: true
}, {
  url: '/admin_integration_meta_data',
  text: 'Admin Integration MetaData',
  icon: <SupervisorAccountIcon />,
  element: <IntegrationMetaData level="ADMIN" />,
  adminOnly: true*/
	},
	{
		url: '/customers/',
		text: 'Customers',
		icon: <FaceIcon />,
		element: <Customers />,
		adminOnly: true,
	},
	{
		url: '/customers/edit/:customerId',
		element: <Customer />,
		hidden: true,
	},
	{
		url: '/customers/new',
		element: <Customer isNew />,
		hidden: true,
	},
	{
		url: '/prediction_models/:modelId',
		element: <ModelAssignments />,
		hidden: true,
	},
	{
		url: '/prediction_models/',
		text: 'Prediction models',
		icon: <CategoryIcon />,
		element: <PredictionModels />,
		adminOnly: true,
	},
	{
		url: '/debug/',
		text: 'Debug',
		icon: <BugReportIcon />,
		element: <Debug />,
		adminOnly: true,
	},
	{
		url: '/fix_diarization/',
		text: 'Fix diarization',
		icon: <BuildIcon />,
		element: <FixDiarization />,
		adminOnly: true,
	},
	{
		url: '/migration/',
		text: 'Migration',
		icon: <TransferWithinAStationIcon />,
		element: <Migration />,
		adminOnly: true,
	},
	{
		url: '/deployments/',
		text: 'Deployments',
		icon: <AppsIcon />,
		element: <Deployments />,
		adminOnly: true,
	},
	{
		url: '/chatgpt_models/',
		text: 'ChatGPT Models',
		icon: <EmojiObjectsIcon />,
		element: <ChatGPTModels />,
		adminOnly: true,
	},
	{
		url: '/gpt-playground/',
		text: 'GPT Playground',
		icon: <SportsCricket />,
		element: <GptPlayground />,
		adminOnly: true,
	},
	{
		url: '/data-deletion/',
		text: 'Data Deletion',
		icon: <DeleteIcon stroke='black'/>,
		element: <DataDeletionTool />,
		adminOnly: true,
	},
	{
		url: '/customer_support',
		text: 'Customer Support',
		icon: <SupportAgent/>,
		element: <CustomerSupportTools/>,
		adminOnly: true
	}
];
//Please do not alter or change these emails without Permission.

const ALLOWED_EMAILS = [
	"sainath@clari.com",
	"bharathwaj@clari.com",
	"rabeen.khan@clari.com",
	"sjain@clari.com",
	"psathyamoorthy@clari.com",
	"avinash@strings.ai",
	"vineet.kumar@clari.com",
	"amulani@clari.com",
	"psati@clari.com"
  ];

function getLinks() {
	const skipAdminLinks = isAgent();
	const canSeeDataDeletion = ALLOWED_EMAILS.includes(localStorage.getItem('email_id'));

	return links.filter(link => {
	  if (link.adminOnly && skipAdminLinks) {
		return false; // Filter out admin-only links for non-admin users
	  }
	  if (link.url === '/data-deletion/' && !canSeeDataDeletion) {
		return false; // Filter out the Data Deletion link for users who aren't authorized
	  }
	  return true;
	});
  }
  
function getRoutes() {
	if (!isLoggedIn())
		return [<Route key="login" path="/" element={<Login />} />];
	const links = getLinks();
	return [
		...links.map((link) => (
			<Route key={link.url} path={link.url} element={link.element} />
		)),
		<Route key="home" path="/" element={<Home links={links} />} />,
	];
}

export function getPageTitle(pathname) {
	if (!pathname) return '';
	if (pathname === '/') return 'Home';
	const match = links.find((link) => pathname.startsWith(link.url));
	return match ? match.text : '';
}

function App(props) {
	useEffect(() => {
		fetchWithCreds('/customers/all', {}, false)
			.then((r) => r.json())
			.then((customers) => {
				props.setCustomers(customers);
			})
			.catch(console.error);
	}, []);

	return (
		<div
			style={{
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<GoogleOAuthProvider clientId="426623482104-7i7g0o9hsik006phe4dqpinpo6s212tr.apps.googleusercontent.com">
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<Header />
						<Routes>{getRoutes()}</Routes>
					</BrowserRouter>
				</ThemeProvider>
			</GoogleOAuthProvider>
		</div>
	);
}

export default connect((a) => a, actions)(App);
