import React, {useState, useEffect} from 'react';
import { TextField, IconButton, Button } from '@mui/material';
import ObjectTreeView from './ObjectTreeView';
import { getJsonData } from './network';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function DebugCallEvents(props) {
  const [email, setEmail] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [event, setEvent] = useState(null);

  function refresh() {
    getJsonData('/debug_call/calendar?' + new URLSearchParams({email, startTime: startTime.toISOString(), endTime: endTime.toISOString()}))
    .then(async e => {setEvent(e); })
    .catch(console.error);
  }

  return (
    <div style={{padding: '20px', width: '500px'}}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField style={{width: '350px'}} value={email} onChange={e => setEmail(e.target.value)} label="Email" />
        <DateTimePicker renderInput={(props) => <TextField {...props} />} label="Start date" value={startTime} onChange={setStartTime} />
        <DateTimePicker renderInput={(props) => <TextField {...props} />} label="End date" value={endTime} onChange={setEndTime} />
      </LocalizationProvider>

      <IconButton onClick={refresh}><DateRangeIcon /></IconButton>
      <ObjectTreeView obj={event} name='callEvent' />
    </div>
  );
}