import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputLabel,
  Switch,
  TextField,
  Tooltip
} from '@mui/material';
import { getJsonData } from '../network';
import { InfoOutlined } from '@mui/icons-material';

function formElement(elem, label, opacity = 1) {
  return (<FormControl variant="standard" style={{ opacity, marginBottom: "1rem" }}>
    {label && <InputLabel>{label}</InputLabel>}
    {elem}
  </FormControl>);
}

function toggleSwitch({ state, handleChange, label, infoMessage, disabled = false }) {
  return <span style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
    <FormControlLabel
      label={label}
      control={
        <Switch
          checked={state}
          color="primary"
          onChange={handleChange}
          disabled={disabled}
        />
      }
    />
    {infoMessage && <Tooltip title={infoMessage} placement="right">
      <InfoOutlined />
    </Tooltip>}
  </span>
}

export default function ImpersonationControls({ customerId, enableImpersonationRestrictions, allowUsersToEnableImpersonation, listOfAdminsSelectedToImpersonate, setEnableImpersonationRestrictions, setAllowUsersToEnableImpersonation, setListOfAdminsSelectedToImpersonate }) {
  const [superAdmins, setSuperAdmins] = useState([]);
  const [selectedSuperAdminsForDropdown, setSelectedSuperAdminsForDropdown] = useState([]);
  const loggedInUserEmailId = localStorage.getItem("email_id")

  useEffect(() => {
    fetchAllSuperAdmin();
  }, [])

  useEffect(() => {
    populateAdminDropdown();
  }, [superAdmins, listOfAdminsSelectedToImpersonate])

  const populateAdminDropdown = async () => {
    console.log("Populating initial data", superAdmins, listOfAdminsSelectedToImpersonate);
    const listOfSelectedSuperAdmins = superAdmins.filter(superAdmin => listOfAdminsSelectedToImpersonate?.includes(superAdmin.id));
    setSelectedSuperAdminsForDropdown(listOfSelectedSuperAdmins);
  }

  const fetchAllSuperAdmin = async () => {
    try {
      let superAdminResponse = await getJsonData('/security/get_all_super_admin');
      setSuperAdmins(superAdminResponse);
    } catch (error) {
      console.error(error);
    }
  }

  const handleImpersonationRestriction = async () => {
    console.log(`${loggedInUserEmailId} ${!enableImpersonationRestrictions ? "enabled" : "disabled"} impersonation access for ${customerId}`);
    setEnableImpersonationRestrictions((prevState) => !prevState);
  }

  const handleAllowUsersToEnableImpersonation = async () => {
    console.log(`${loggedInUserEmailId} ${!allowUsersToEnableImpersonation ? "enabled" : "disabled"} user level impersonation access for ${customerId}`);
    setAllowUsersToEnableImpersonation((prevState) => !prevState);
  }

  const filterSearchOptions = (options, state) => {
    return options.filter((option) => option?.name.toLowerCase().includes(state.inputValue.toLowerCase()) || option?.emailId.toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  const updateSuperAdmins = (event, currentAdminsToImpersonate) => {
    console.log(`${loggedInUserEmailId} updated the super admin list for ${customerId}, new list: ${JSON.stringify(currentAdminsToImpersonate)}`);
    setListOfAdminsSelectedToImpersonate(currentAdminsToImpersonate.map(admin => admin.id));
  }

  return <div>
    <h3>Impersonation Setting</h3>
    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
      {
        formElement(toggleSwitch({
          state: enableImpersonationRestrictions,
          handleChange: handleImpersonationRestriction,
          label: "Enable Impersonation Restriction"
        }))
      }
      {
        formElement(toggleSwitch(
          {
            state: allowUsersToEnableImpersonation,
            handleChange: handleAllowUsersToEnableImpersonation,
            label: "Allow Users To Enable Impersonation",
            infoMessage: 'When selected the super admins will be restricted from impersonating users and the users have to explicitly give access from their settings page',
            disabled: !enableImpersonationRestrictions
          }))
      }
    </div>
    <Autocomplete
      disableClearable={true}
      disabled={!enableImpersonationRestrictions}
      value={selectedSuperAdminsForDropdown}
      onChange={updateSuperAdmins}
      multiple
      id="super-admin-select"
      options={superAdmins}
      filterSelectedOptions={true}
      getOptionLabel={(option) => option.emailId}
      filterOptions={filterSearchOptions}
      renderOption={(props, option) => (
        <li {...props}>{option.name} -- {option.emailId}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Super Admins"
          placeholder="Search Admin"
          margin="normal"
          fullWidth
        />
      )}
    />
  </div>;
}
