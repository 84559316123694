import { Backdrop, CircularProgress } from "@mui/material"

export default function CircularLoaderIndicator({open})  {

    return (
        <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}