import { Button, Grid } from '@mui/material';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { postJson } from 'network';
import screens from '../screens';
import { useGptPlaygroundActions } from '../customHooks';
import { GptPlaygroundContext } from '../redux';

export default function SummaryComponent() {

  const gptPlaygroundState = useContext(GptPlaygroundContext);

  const {
		setLoading,
		setError,
		resetLoading,
		changeScreen,
	} = useGptPlaygroundActions();

  const sendSummary = async (body) => {
    const response = await postJson('/gpt-playground/send-summary', body);
    return response;
  };

  const onBackButton = () => {
		changeScreen(screens.userGptPromptInputScreen);
	};

  const sendSummaryHandle = async () => {
    setLoading();
    try {
      const userName = localStorage.getItem('userName');
      const { userPrompt, systemPrompt, callId, text } =
        gptPlaygroundState.screen.screenData[screens.userGptPromptInputScreen];
      const sendSummaryBody = {
        userPrompt: userPrompt,
        systemPrompt: systemPrompt,
        callId: callId,
        user: userName,
        text: text,
        summary: gptPlaygroundState.summary.text,
      };
      const response = await sendSummary(sendSummaryBody);
      if (response?.status !== 200) {
        setError('Invalid Input!!', new Error("Something went wrong"));
        return;
      }
    } catch (e) {
      console.log(e);
      setError('Unable to send the message.', e);
    } finally {
      resetLoading();
    }
  };

  return (
    <Grid container direction={'column'} rowGap="1rem">
      <Grid
        item
        sx={{
          maxHeight: '70vh',
          minHeight: '40vh',
          overflow: 'scroll',
          whiteSpace: 'pre-wrap',
          textAlign: 'start',
          lineHeight: '2.5',
          padding: '1rem',
          border: '1px solid gray',
        }}
      >
        <p>{gptPlaygroundState.summary.text.toString()}</p>
      </Grid>

      <Button
        size="large"
        sx={{ minWidth: '20%', marginX: '30%' }}
        variant="contained"
        onClick={sendSummaryHandle}
      >
        Send the summary
      </Button>

      <Button
        size="large"
        sx={{ minWidth: '20%', marginX: '30%' }}
        variant="outlined"
        onClick={onBackButton}
      >
        Back
      </Button>
    </Grid>
  );
}
