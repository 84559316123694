import React, {useState, useEffect} from 'react';
import { TextField, IconButton, Button } from '@mui/material';
import {CallData} from './service_pb';
import ObjectTreeView from './ObjectTreeView';
import { getData, getJsonData } from './network';
import EventIcon from '@mui/icons-material/Event';

export default function DebugCallEvent(props) {
  const [callId, setCallId] = useState(null);
  const [loadedCallId, setLoadedCallId] = useState('');
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const hash = window.location.hash.length > 0 ? window.location.hash.substr(1) : null;
    if (!hash || !hash.startsWith('call_data')) return;
    const passedId = hash.substr('call_data'.length + 1);
    setCallId(passedId);
    setLoadedCallId(passedId);
  }, []);

  useEffect(() => {
    if (!loadedCallId) return;
    getJsonData('/debug_call/' + loadedCallId + '/calendar')
    .then(async e => {
      setEvent(e);
    }).catch(console.error);
  }, [loadedCallId]);

  return (
    <div style={{padding: '20px', width: '500px'}}>
      <TextField style={{width: '350px'}} value={callId} onChange={e => setCallId(e.target.value)} label="Call id" />
      <IconButton onClick={() => setLoadedCallId(callId)}><EventIcon /></IconButton>
      <ObjectTreeView obj={event} name='callEvent' />
    </div>
  );
}