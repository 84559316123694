import React, { useState } from 'react';
import {
	TextField,
	IconButton,
	Typography,
	FormControlLabel,
	Switch,
	CircularProgress,
	Snackbar,
	CardContent,
	Grid,
	Box,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomerSelect from './CustomerSelect';
import { getData } from './network';
import SendIcon from '@mui/icons-material/Send';

function PushCallCRM() {
	const [selectedCustomerId, setSelectedCustomerId] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false);
	const [forcePush, setForcePush] = useState(false);
	const [linkCall, setLinkCall] = useState(true);
	const [snackPack, setSnackPack] = useState([]);
	const [responseMessage, setResponseMessage] = useState('');

	const handleStartDateChange = (newValue) => {
		setStartDate(newValue);
	};

	const handleEndDateChange = (newValue) => {
		setEndDate(newValue);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackPack((prev) => prev.slice(1));
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const endpoint = '/support_tools/push-calls-to-CRM';
			const params = {
				customerId: selectedCustomerId,
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				forcePush,
				linkCall,
			};
			const response = await getData(endpoint, params);
			if (!response.ok) {
				throw new Error('Failed to push call data to CRM');
			}
			const resultText = await response.text();
			setResponseMessage(resultText);
			setSnackPack((prev) => [
				...prev,
				{ message: resultText, key: new Date().getTime() },
			]);
		} catch (error) {
			console.error('Error occurred:', error);
			setResponseMessage(error.toString());
			setSnackPack((prev) => [
				...prev,
				{ message: error.toString(), key: new Date().getTime() },
			]);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Grid
				container
				alignItems="center"
				justifyContent="center"
				spacing={2}
			>
				<Grid item xs={12} style={{ boxShadow: 'none' }}>
					<CardContent>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="space-around"
						>
							<Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
								<CustomerSelect
									onSelect={setSelectedCustomerId}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
								<DateTimePicker
									label="Start Date"
									value={startDate}
									onChange={handleStartDateChange}
									renderInput={(props) => (
										<TextField {...props} />
									)}
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ flexGrow: 1 }}>
								<DateTimePicker
									label="End Date"
									value={endDate}
									onChange={handleEndDateChange}
									renderInput={(props) => (
										<TextField {...props} />
									)}
								/>
							</Grid>
						</Grid>
						<Box
							sx={{
								boxShadow: 3,
								borderRadius: 1,
								marginTop: 4,
								padding: 1,
								marginX: 'auto',
								height: 'fit-content',
								maxWidth: 'fit-content',
							}}
						>
							<Grid
								item
								container
								spacing={2}
								justifyContent="flex-start"
								alignItems="center"
							>
								<Grid item>
									<FormControlLabel
										control={
											<Switch
												checked={forcePush}
												onChange={(e) =>
													setForcePush(
														e.target.checked
													)
												}
											/>
										}
										label="Force Push"
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										control={
											<Switch
												checked={linkCall}
												onChange={(e) =>
													setLinkCall(
														e.target.checked
													)
												}
											/>
										}
										label="Link Call"
									/>
								</Grid>
								<Grid item>
									{isLoading ? (
										<CircularProgress />
									) : (
										<IconButton
											color="primary"
											onClick={handleSubmit}
										>
											<SendIcon />
										</IconButton>
									)}
								</Grid>
							</Grid>
						</Box>

						<Grid item xs={12} mt={3}>
							<Typography variant="h6" align="center">
								{responseMessage}
							</Typography>
						</Grid>
					</CardContent>
				</Grid>
			</Grid>
			<Snackbar
				open={snackPack.length > 0}
				autoHideDuration={7000}
				onClose={handleSnackBarClose}
				message={snackPack[0]?.message}
				key={snackPack[0]?.key}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			/>
		</LocalizationProvider>
	);
}

export default PushCallCRM;
