import React, { useState } from 'react';
import { Button } from '@mui/material';
import BulkUploadModal from './BulkUploadModal';
import { postJson, postWithoutParsingBody } from '../network';
import { downloadBlob, downloadFile } from '../../src/util';

const userCreatedColumns = [
	{
		headerName: 'User Email',
		field: 'userEmail',
		flex: 3,
	},
	{
		headerName: 'Create UserStatus',
		field: 'responseType',
		flex: 3,
	},
];

const topicCreatedColumn = [
	{
		headerName: 'Row Number',
		field: 'rowNumber',
		flex: 3,
	},
	{
		headerName: 'Topic',
		field: 'category',
		flex: 3,
	},
	{
		headerName: 'Response',
		field: 'response',
		flex: 3,
	},
];

const bulkUserDeleteColumn = [
	{
		headerName: 'Row Number',
		field: 'rowNumber',
		flex: 1,
	},
	{
		headerName: 'Unique user Id',
		field: 'userId',
		flex: 2,
	},
	{
		headerName: 'Email',
		field: 'email',
		flex: 3,
	},
	{
		headerName: 'Response Message',
		field: 'response',
		flex: 4,
	},
];

const rbacDefaultCsvLink =
	'https://docs.google.com/spreadsheets/d/1u-w-XCP_w71EPVIvn4TcmGIyXeJfcqdKU8mQQjlZa84/edit?usp=sharing';
const userCreatedCsvLink =
	'https://docs.google.com/spreadsheets/d/1BgA3w5nXmR5VLLSFMkF4X95oKZlS2noX2jGlzJPsL1I/edit#gid=0';
const topicCreatedCsvLink =
	'https://docs.google.com/spreadsheets/d/1kRQH0QKCyxt6UYzYDOE5I2QYQuqgbiFUQsdfNRUjwJ8/edit#gid=1115333369';

export default function BulkInputHandlerForCustomer({
	rbacEnabled,
	classes,
	customerId,
}) {
	const [openTopicModal, setOpenTopicModal] = useState(false);
	const [openuBulkUserModal, setOpenuBulkUserModal] = useState(false);
	const [openDeleteBulkUserModal, setOpenDeleteBulkUserModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const bulkUploadUserCsvApi = async (formData) => {
		const response = await postWithoutParsingBody(
			'/customers/create-user-csv',
			formData
		);
		return response;
	};

	const bulkUploadTopicCsvApi = async (formData) => {
		const response = await postWithoutParsingBody(
			'/customers/create-topic-csv',
			formData
		);
		return response;
	};

	const bulkUserDeleteCsvApi = async (formData) => {
		const response = await postWithoutParsingBody(
			'/customers/bulk_user_delete',
			formData
		);
		return response;
	};

	async function handleIntegrationStatusDownload() {
		setIsLoading(true);
		try {
			const response = await postJson('/customers/download_user_csv', {
				customerId,
			});
			const csvData = await response.text();
			downloadBlob(csvData, 'userData.csv', 'text/csv');
		} catch (error) {
			console.error(error);
		}
		setIsLoading(false);
	}

	return (
		<>
			{customerId && (
				<Button
					variant="contained"
					color="info"
					className={classes.formField}
					onClick={() => setOpenuBulkUserModal(true)}
				>
					CREATE USERS IN BULK
				</Button>
			)}

			<BulkUploadModal
				openModal={openuBulkUserModal}
				customerId={customerId}
				setOpenModal={setOpenuBulkUserModal}
				isRbacEnabled={rbacEnabled}
				bulkUploadCsv={bulkUploadUserCsvApi}
				columns={userCreatedColumns}
				csvSampleLink={
					rbacEnabled ? rbacDefaultCsvLink : userCreatedCsvLink
				}
			>
				<div>
					<span>Add Users</span>
					<span className={classes.greyColor}>
						&bull; For reference check
					</span>
				</div>
			</BulkUploadModal>

			{customerId && (
				<Button
					variant="contained"
					color="info"
					className={classes.formField}
					onClick={() => setOpenTopicModal(true)}
				>
					UPLOAD TOPICS
				</Button>
			)}

			<BulkUploadModal
				openModal={openTopicModal}
				customerId={customerId}
				setOpenModal={setOpenTopicModal}
				bulkUploadCsv={bulkUploadTopicCsvApi}
				columns={topicCreatedColumn}
				csvSampleLink={topicCreatedCsvLink}
				defaultExcelText="Topic upload sheet"
			>
				<div>
					<span>Upload topic csv</span>
				</div>
			</BulkUploadModal>

			{customerId && (
				<Button
					variant="contained"
					color="info"
					className={classes.formField}
					onClick={() => setOpenDeleteBulkUserModal(true)}
				>
					delete bulk user
				</Button>
			)}

			<BulkUploadModal
				openModal={openDeleteBulkUserModal}
				customerId={customerId}
				setOpenModal={setOpenDeleteBulkUserModal}
				bulkUploadCsv={bulkUserDeleteCsvApi}
				columns={bulkUserDeleteColumn}
				defaultExcelText="Download Users"
				isSampleLink={false}
				loadingForDownload={isLoading}
				handleCsvSample={
					handleIntegrationStatusDownload
				}
			>
				<div>
					<span>Delete user in bulk</span>
				</div>
			</BulkUploadModal>
			<hr style={{ width: '100%' }} />
		</>
	);
}
