export const EMAIL_REGEX_PATTERN =	/^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/;
export const CALL_DELETION_REQUEST_STATUS = {
    GENERATING: 'GENERATING',
    GENERATED: 'GENERATED',
    DELETED: 'DELETED',
    DELETING: 'DELETING',
    GENERATION_FAILED: 'GENERATION FAILED',
    DELETION_FAILED: "DELETION FAILED"
};

// TODO: safe delete 
export const CALL_DELETE_TYPE = {
    CALLS_ONLY: 'Calls Only',
    ALL: 'All',
};

export const RECORD_TYPE = {
    CALLS: "CALLS",
    GAMETAPES: "GAMETAPES",
    SNIPPETS: "SNIPPETS",
}

export const CONTENT_TYPE = {
    EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV: 'text/csv',
};

export const CLARI_CUSTOMER_ID = '620fd3e42f2c5316b2aaf887';