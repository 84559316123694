import React, { useState, useEffect } from 'react';
import {
	TextField,
	IconButton,
	Tabs,
	Tab,
	Box,
	Typography,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import { CallData, ModelWorkflowOutput } from './service_pb';
import ObjectTreeView from './ObjectTreeView';
import { getData } from './network';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import PropTypes from 'prop-types';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{children}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export default function DebugCallData(props) {
	const [callId, setCallId] = useState('');
	const [dataCallId, setDataCallId] = useState('');
	const [callData, setCallData] = useState(null);
	const [callProto, setCallProto] = useState(null);
	const [callProtoOtherDetails, setCallProtoOtherDetails] = useState(null);
	const [completeCallProto, setCompleteCallProto] = useState(null);
	const [tabValue, setTabValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const [predictionArray, setPredictionArray] = useState([]);
	const [topicArray, setTopicArray] = useState([]); // Added state for topics

	useEffect(() => {
		const hash =
			window.location.hash.length > 0
				? window.location.hash.substr(1)
				: null;
		if (!hash || !hash.startsWith('call_data')) return;
		const passedId = hash.substr('call_data'.length + 1);
		setCallId(passedId);
		setDataCallId(passedId);
	}, []);

	useEffect(() => {
		setCompleteCallProto({
			call_proto_bytes: callProto,
			...callProtoOtherDetails,
		});
	}, [callProtoOtherDetails, callProto]);

	useEffect(() => {
		if (!dataCallId) return;
		setLoading(true);

		const protoPromise = getData(
			'/diarization/calldata?callId=' + dataCallId
		)
			.then((resp) => resp.arrayBuffer())
			.then((bytes) => {
				const protoData = CallData.deserializeBinary(bytes).toObject();
				setCallProto(protoData);
			})
			.catch((error) => {
				console.error(
					'Error fetching or deserializing call data:',
					error
				);
			});

		const protoOtherDetailsPromise = getData(
			'/debug_call/call-proto-details/' + dataCallId
		)
			.then((resp) => resp.json())
			.then((data) => {
				console.log('Received call proto other details:', data);
				let deserializedPredictions = [];
				let deserializedTopics = [];

				if (data.call_topic_bytes) {
					deserializedTopics = Object.values(data.call_topic_bytes).map(
						(element) => {
							return ModelWorkflowOutput.deserializeBinary(
								element
							).toObject();
						}
					);
					setTopicArray(deserializedTopics); // Update the state with deserialized topics
					console.log('Deserialized topics:', deserializedTopics);
				}

				if (data.call_prediction_bytes) {
					deserializedPredictions = data.call_prediction_bytes.map(
						(element) => {
							return ModelWorkflowOutput.deserializeBinary(
								element
							).toObject();
						}
					);
					setPredictionArray(deserializedPredictions);
					console.log(
						'Deserialized predictions:',
						deserializedPredictions
					);
				}

				setCallProtoOtherDetails({
					...data,
					call_prediction_bytes: deserializedPredictions,
					call_topic_bytes: deserializedTopics,
				});
			})
			.catch((error) => {
				console.error(
					'Error fetching or deserializing call proto other details:',
					error
				);
			});

		const dataPromise = getData('/debug_call/call-details/' + dataCallId)
			.then((resp) => resp.json())
			.then((data) => {
				setCallData(data);
			})
			.catch((error) => {
				console.error('Error fetching call details:', error);
			});

		Promise.all([
			protoPromise,
			protoOtherDetailsPromise,
			dataPromise,
		]).finally(() => setLoading(false));
	}, [dataCallId]);

	return (
		<div style={{ padding: '20px' }}>
			<Backdrop open={loading} style={{ zIndex: '5000' }}>
				<CircularProgress />
			</Backdrop>
			<TextField
				style={{ width: '350px' }}
				value={callId}
				onChange={(e) => setCallId(e.target.value)}
				label="Call id"
			/>
			<IconButton onClick={() => setDataCallId(callId)}>
				<FormatAlignJustifyIcon />
			</IconButton>
			<Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
				<Tab label="Call data" id={0} />
				<Tab label="Call proto" id={1} />
				<Tab label="Predictions" id={2} />
				<Tab label="Topics" id={3} /> {/* New Tab for Topics */}
			</Tabs>
			<TabPanel value={tabValue} index={0}>
				<ObjectTreeView obj={callData} name="callData" />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<ObjectTreeView obj={completeCallProto} name="callProto" />
			</TabPanel>
			<TabPanel value={tabValue} index={2}>
				<ObjectTreeView
					obj={predictionArray}
					name="deserializedPredictions"
				/>
			</TabPanel>
			<TabPanel value={tabValue} index={3}>
				<ObjectTreeView
					obj={topicArray}
					name="deserializedTopics"
				/>
			</TabPanel>
		</div>
	);
}
