import React, { Component } from 'react';
import { Paper, Chip, TextField, IconButton, Typography, Select, MenuItem, InputLabel, FormControl, Snackbar, Menu, Grid, Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {Link} from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { getJsonData, postJson } from './network';

export default class ModelAssignments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelId: this.props.match.params.modelId,
            loading: true,
            versions: [],
            configs: [],
            editingConfig: null,
            customers: [],
            customerIds: []
        };
        getJsonData('/customers/all')
            .then(customers => this.setState({customers, customerIds: customers.map(c => c._id)}))
            .catch(console.error);
        this.loadData = this.loadData.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.getCustomerName = this.getCustomerName.bind(this);
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        getJsonData('/models/get-assignments/' + this.state.modelId)
            .then(assignments => this.setState({loading: false, versions: this.buildVersions(assignments)}))
            .catch(console.error);
        getJsonData('/models/get-configs/' + this.state.modelId)
            .then(configs => this.setState({configs}))
            .catch(console.error);
    }

    buildVersions(assignments) {
        const versions = {};
        assignments.forEach(assignment => {
            if (!versions.hasOwnProperty(assignment.version)) versions[assignment.version] = [];
            versions[assignment.version].push(assignment.customerId);
        });
        return Object.keys(versions).sort().map(key => ({version: key, customers: versions[key]}));
    }

    getAllVersions() {
        return this.state.versions.map(v => v.version);
    }

    setNewVersion(i, customers) {
        const copy = [...this.state.versions];
        copy[i].customers = customers;
        this.setState({versions: copy});
    }

    closeDialog() {
        this.setState({editingConfig: null})
    }

    getCustomerName(id) {
        const customer = this.state.customers.filter(customer => customer._id === id);
        if (customer.length < 1) return id;
        return customer[0].name;
    }

    render() {
        return (<div>
            <Snackbar open={this.state.loading} message="Loading..." />
            {this.state.editingConfig && <ConfigEditor 
                modelId={this.state.modelId}
                afterSave={this.loadData}
                editingConfig={this.state.editingConfig}
                onClose={this.closeDialog}
                allCustomers={this.state.customerIds}
                getCustomerName={this.getCustomerName}
                allVersions={this.getAllVersions()} />}
            <Grid container spacing={1}>
                <Grid item xs="6">
                    <Link to="/prediction_models/" title="Back"><KeyboardArrowLeftIcon /></Link>
                    <Typography style={{marginLeft: '10px', display: 'inline'}} variant="h5">{this.state.modelId} versions</Typography>
                    {this.state.versions.map((version, i) => <ExistingVersion 
                        key={i}
                        type={this.state.modelId}
                        allCustomers={this.state.customerIds}
                        getCustomerName={this.getCustomerName}
                        version={version}
                        onUpdateCustomers={customers => this.setNewVersion(i, customers)}
                        onUpdate={this.loadData} />)}
                    <NewVersion type={this.state.modelId} allCustomers={this.state.customerIds} getCustomerName={this.getCustomerName} onUpdate={this.loadData} />
                </Grid>
                <Grid item xs="6">
                    <Typography variant="h5">{this.state.modelId} prediction configs</Typography>
                    {this.state.configs.map(config => <ConfigView key={config._id} config={config} getCustomerName={this.getCustomerName} onEdit={() => this.setState({editingConfig: config})} />)}
                    <Button style={{width: "100%", marginTop: "10px"}} onClick={() => this.setState({editingConfig: {}})}>+</Button>
                </Grid>
            </Grid>
        </div>);
    }
}

class ConfigEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: this.props.editingConfig._id || null,
            versions: this.props.editingConfig.versions || [],
            customers: this.props.editingConfig.customers || [],
            configs: this.props.editingConfig.configs || [{name: 'default', config: {type: 'boolean', comparison: 'gt'}}]
        };
        this.saveConfig = this.saveConfig.bind(this);
        this.addCustomer = this.addCustomer.bind(this);
        this.removeCustomer = this.removeCustomer.bind(this);
        this.addVersion = this.addVersion.bind(this);
        this.removeVersion = this.removeVersion.bind(this);
    }

    saveConfig() {
        const body = {
            type: this.props.modelId,
            _id: this.state._id,
            versions: this.state.versions,
            customers: this.state.customers,
            configs: this.state.configs};
        console.log('Saving config', body);
        postJson('/models/save-config', body)
            .then(resp => resp.json())
            .then(resp => {
                this.props.afterSave();
                this.props.onClose();
                if (resp.err) alert(resp.err);
            }).catch(console.error);
        this.updateConfig = this.updateConfig.bind(this);
    }

    updateConfig(i, c) {
        const copy = [...this.state.configs];
        copy[i].config = c;
        this.setState({configs: copy});
    }

    configEntry(c, i) {
        const operators = {
            'gt': '>',
            'lt': '<'
        };
        return (<div>
            <TextField label='class' value={c.config.class} onChange={e => this.updateConfig(i, {...c.config, class: e.target.value})} />
            <FormControl style={{width: "80px"}}>
                <InputLabel>&gt; / &lt;</InputLabel>
                <Select value={c.config.comparison} onChange={e => this.updateConfig(i, {...c.config, comparison: e.target.value})}>
                    {Object.keys(operators).map(k => <MenuItem key={k} value={k}>{operators[k]}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField label='threshold' value={c.config.threshold} onChange={e => this.updateConfig(i, {...c.config, threshold: e.target.value})} />
        </div>);
    }

    addCustomer(id) {
        this.setState({customers: [...this.state.customers, id]});
    }

    removeCustomer(id) {
        this.setState({customers: this.state.customers.filter(i => i !== id)});
    }

    addVersion(v) {
        this.setState({versions: [...this.state.versions, v]});
    }

    removeVersion(v) {
        this.setState({versions: this.state.versions.filter(i => i !== v)});
    }

    render() {
        return (<Dialog open={!!this.props.editingConfig} onClose={this.props.onClose}>
            <DialogTitle>Editing config</DialogTitle>
            <DialogContent>
                Customers: <ChipList allValues={this.props.allCustomers} values={this.state.customers} getName={this.props.getCustomerName} onAdd={this.addCustomer} onRemove={this.removeCustomer} /><br/>
                Versions: <ChipList allValues={this.props.allVersions} values={this.state.versions} onAdd={this.addVersion} onRemove={this.removeVersion} /><br/>
                {this.state.configs.map((c, i) => this.configEntry(c, i))}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">Cancel</Button>
                <Button onClick={this.saveConfig} color="primary">Save</Button>
            </DialogActions>
        </Dialog>)
    }
};

class ConfigView extends Component {
    constructor(props) {
        super(props);
        this.signs = {
            'gt': '>',
            'gte': '>=',
            'lt': '<',
            'lte': '<=',
            'eq': '=='
        };
    }

    showConfig(name, config) {
        if (config.type === 'boolean') {
            return <div key={name}>Predict {name === 'default' ? '' : name} True if: prob({config.class}) {this.signs[config.comparison]} {config.threshold}</div>;
        }
    }

    getConfigName() {
        const versions = this.props.config.versions || [];
        const customerIds = this.props.config.customers || [];
        const customers = customerIds.map(c => this.props.getCustomerName(c));
        const def = this.props.config.default ? ['Default'] : [];
        return def.concat(versions).concat(customers).join(', ');
    }

    render() {
        return (<Paper style={{marginTop: "10px", padding: "20px"}}>
            <IconButton style={{float: 'right'}} onClick={this.props.onEdit}>
                <EditIcon fontSize='small' />
            </IconButton>
            <Typography variant="subtitle2">{this.getConfigName()} config</Typography>
            {this.props.config.configs.map(config => this.showConfig(config.name, config.config))}
        </Paper>)
    }
}

class NewVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version: '',
            customers: ['default']
        };
        this.addCustomer = this.addCustomer.bind(this);
        this.removeCustomer = this.removeCustomer.bind(this);
    }

    addCustomer(customerId) {
        if (this.state.customers.indexOf(customerId) !== -1) return;
        this.setState({customers: [...this.state.customers, customerId]});
    }

    removeCustomer(customerId) {
        if (this.state.customers.indexOf(customerId) === -1) return;
        this.setState({customers: this.state.customers.filter(customer => customer !== customerId)});
    }

    createVersion() {
        postJson('/models/assign-model', {type: this.props.type, version: this.state.version, customerIds: this.state.customers})
            .then(resp => resp.json())
            .then(resp => {
                this.setState({version: '', customer: ['default']});
                this.props.onUpdate();
                if (resp.err) alert(resp.err);
            }).catch(console.error);
    }

    render() {
        return (<Paper style={{marginTop: "15px", padding: "20px"}}>
            <Typography variant="h5">Add new version</Typography>
            <TextField label="Model version" value={this.state.version} onChange={e => this.setState({version: e.target.value})} style={{marginRight: "10px"}} />
            <ChipList allValues={this.props.allCustomers} getName={this.props.getCustomerName}
                values={this.state.customers} onAdd={this.addCustomer} onRemove={this.removeCustomer} />
            <IconButton onClick={() => this.createVersion()} title="Create version">
                <DoneIcon />
            </IconButton>
        </Paper>);
    }
}

class ExistingVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updated: false
        };
        this.addCustomer = this.addCustomer.bind(this);
        this.removeCustomer = this.removeCustomer.bind(this);
        this.saveUpdates = this.saveUpdates.bind(this);
    }

    addCustomer(customerId) {
        this.props.onUpdateCustomers([...this.props.version.customers, customerId]);
        this.setState({updated: true});
    }

    removeCustomer(customerId) {
        this.props.onUpdateCustomers(this.props.version.customers.filter(c => c !== customerId));
        this.setState({updated: true});
    }

    saveUpdates() {
        postJson('/models/assign-model', {type: this.props.type, version: this.props.version.version, customerIds: this.props.version.customers})
            .then(resp => resp.json())
            .then(resp => {
                this.setState({updated: false});
                this.props.onUpdate();
                if (resp.err) alert(resp.err);
            }).catch(console.error);
    }

    render() {
        return (<Paper style={{marginTop: "15px", padding: "20px"}}>
            <div>Version: {this.props.version.version}</div>
            <ChipList allValues={this.props.allCustomers} 
                getName={this.props.getCustomerName}
                values={this.props.version.customers} 
                onAdd={this.addCustomer} 
                onRemove={this.removeCustomer} />
            {this.state.updated && <IconButton onClick={this.saveUpdates} title="Save version changes">
                <DoneIcon />
            </IconButton>}
        </Paper>);
    }
}

class ChipList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            menuOpen: false
        };
    }

    select(id) {
        this.props.onAdd(id);
        this.setState({menuOpen: false});
    }

    getName(id) {
        if (this.props.getName) return this.props.getName(id);
        return id;
    }

    render() {
        return (<span>
            {this.props.values.map(id => <Chip key={id} label={this.getName(id)} onDelete={() => this.props.onRemove(id)} />)}
            <IconButton onClick={(e) => this.setState({anchorEl: e.currentTarget, menuOpen: true})}>
                <AddCircleIcon />
            </IconButton>
            <Menu anchorEl={this.state.anchorEl} open={this.state.menuOpen} onClose={() => this.setState({menuOpen: false})}>
                {this.props.allValues.map(v => <MenuItem key={v} value={v} onClick={() => this.select(v)}>{this.getName(v)}</MenuItem>)}
            </Menu>
        </span>);
    }
}