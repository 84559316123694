import React, { useState, useEffect } from 'react';
import {
	TextField,
	Backdrop,
	CircularProgress,
	FormGroup,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Box,
	Paper,
	Accordion,
	Typography,
	AccordionDetails,
	AccordionSummary,
} from '@mui/material';
import { getData, postJson } from './network';
import PropTypes from 'prop-types';
import ApiIcon from '@mui/icons-material/Api';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

function safeJsonParse(input) {
	try {
		return JSON.parse(input);
	} catch (error) {
		alert('Not a proper json, the field will remain empty for now');
		console.error('Error parsing JSON:', error);
		return {};
	}
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{children}
		</div>
	);
}

function displayStr(item) {
	const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
	return str.replace(/_/g, ' ');
}

function formElement(elem, label, opacity = 1) {
	return (
		<FormControl
			variant="standard"
			style={{ opacity, marginBottom: '1rem' }}
		>
			{label && <InputLabel>{label}</InputLabel>}
			{elem}
		</FormControl>
	);
}

function select(state, setState, arr, multiple) {
	return (
		<Select
			value={state}
			multiple={multiple}
			onChange={(e) => setState(e.target.value)}
		>
			{arr.map((item, i) => (
				<MenuItem key={i} value={item}>
					{displayStr(item.toString())}
				</MenuItem>
			))}
		</Select>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function updateListOfObjects(
	index,
	propertyName,
	updateValue,
	state,
	stateUpdateFunc
) {
	let newList = [];
	for (let i = 0; i < state.length; i++) {
		if (i == index) {
			let newObject = { ...state[i], [propertyName]: updateValue };
			newList = [...newList, newObject];
		} else {
			newList = [...newList, state[i]];
		}
	}
	stateUpdateFunc(newList);
}
const FollowupModelComponent = ({
	index,
	followupModels,
	setFollowupModels,
}) => {
	return (
		<Accordion expanded={true}>
			<AccordionSummary expandIcon={<GridExpandMoreIcon />}>
				<Typography>
					<strong>FollowupModel No.{index + 1} </strong>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<FormGroup>
					<TextField
						style={{ width: '100%', marginBottom: '0.7rem' }}
						value={followupModels[index].model_name}
						onChange={(e) =>
							updateListOfObjects(
								index,
								'model_name',
								e.target.value,
								followupModels,
								setFollowupModels
							)
						}
						label="Model Name"
					/>
					<TextField
						style={{ width: '100%', marginBottom: '0.7rem' }}
						value={followupModels[index].model_version}
						onChange={(e) =>
							updateListOfObjects(
								index,
								'model_version',
								e.target.value,
								followupModels,
								setFollowupModels
							)
						}
						label="Model Version"
					/>
					<TextField
						style={{ width: '100%', marginBottom: '0.7rem' }}
						value={followupModels[index].field}
						onChange={(e) =>
							updateListOfObjects(
								index,
								'field',
								e.target.value,
								followupModels,
								setFollowupModels
							)
						}
						label="Field Name"
					/>
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};

export default function ChatGPTModels(props) {
	const [allModelDetails, setAllModelDetails] = useState([]);
	const [modelsList, setModelsList] = useState([]);
	const [versionIdList, setVersionIdList] = useState([]);
	const [currentModelName, setCurrentModelName] = useState('');
	const [currentVersionId, setCurrentVersionId] = useState('');
	//version details
	const [versionPath, setVersionPath] = useState('v1');
	const [chatgptVersion, setChatgptVersion] = useState('gpt-3.5-turbo');
	const [isVersionDefault, setIsVersionDefault] = useState(false);
	const [hasFollowupModels, setHasFollowupModels] = useState(false);
	const [prompt, setPrompt] = useState('');
	const [systemPrompt, setSystemPrompt] = useState('');
	const [chatCompletionParamsStr, setChatCompletionParamsStr] =
		useState('{}');
	const [functionCallStr, setFuncitonCallStr] = useState('{}');
	const [followupModels, setFollowupModels] = useState([]);

	const [loading, setLoading] = useState(false);

	function renderFollowupModels() {
		const followupModelComponents = [];
		for (let i = 0; i < followupModels.length; i++) {
			followupModelComponents.push(
				<FollowupModelComponent
					style={{ marginBottom: '0.7rem' }}
					key={i}
					index={i}
					followupModels={followupModels}
					setFollowupModels={setFollowupModels}
				/>
			);
		}
		return followupModelComponents;
	}
	//Populates the list of models
	useEffect(() => {
		(async () => {
			try {
				var data = await getData('/chatgpt-models/get-models');
				data = await data.json();
				setAllModelDetails(data);
				setModelsList(data.map((modelDetails) => modelDetails.type));
			} catch (e) {
				alert(
					'Some error occured while fetching the models' + e.message
				);
			}
		})();
	}, []);

	//on changing the model we update the versionId List
	useEffect(() => {
		let versionList = [];
		for (var modelDetail of allModelDetails) {
			if (modelDetail.type == currentModelName) {
				for (var versionDetail of modelDetail.versions) {
					versionList.push(versionDetail.version_id);
				}
			}
		}
		setVersionIdList(versionList.sort());
	}, [currentModelName]);

	//on changing the versionId we update the versionId Data shown
	useEffect(() => {
		for (var modelDetail of allModelDetails) {
			if (modelDetail.type == currentModelName) {
				for (var versionDetail of modelDetail.versions) {
					if (versionDetail.version_id == currentVersionId) {
						setChatCompletionParamsStr(
							JSON.stringify(versionDetail.chat_completion_params)
						);
						setFuncitonCallStr(
							JSON.stringify(
								versionDetail?.function_call_obj || {}
							)
						);
						setChatgptVersion(versionDetail.chatgpt_model);
						setHasFollowupModels(versionDetail.has_followup_models);
						setIsVersionDefault(versionDetail.is_default);
						setPrompt(versionDetail.prompt);
						setSystemPrompt(versionDetail?.system_prompt || '');
						setVersionPath(versionDetail.version_path);
						setFollowupModels(versionDetail?.followup_models || []);
					}
				}
			}
		}
	}, [currentVersionId]);

	//Sets the form Data
	function updateVersionDetails() {
		if (!currentModelName || !currentVersionId) {
			alert('Please Select a model name and version Id to update');
			return;
		}
		if (currentVersionId == 'v1') {
			alert('You are not allowed to change v1');
			return;
		}
		let modelDetail;
		for (let i = 0; i < allModelDetails.length; i++) {
			if (allModelDetails[i].type == currentModelName) {
				modelDetail = allModelDetails[i];
				for (let j = 0; j < modelDetail.versions.length; j++) {
					if (
						modelDetail.versions[j].version_id == currentVersionId
					) {
						let chatCompletionParams = safeJsonParse(
							chatCompletionParamsStr
						);
						modelDetail.versions[j].chat_completion_params =
							chatCompletionParams;
						modelDetail.versions[j].chatgpt_model = chatgptVersion;
						modelDetail.versions[j].has_followup_models =
							hasFollowupModels;
						modelDetail.versions[j].is_default = isVersionDefault;
						modelDetail.versions[j].prompt = prompt;
						modelDetail.versions[j].system_prompt = systemPrompt;
						modelDetail.versions[j].version_path = versionPath;
						modelDetail.versions[j].followup_models =
							followupModels;
						let functionCallObj = safeJsonParse(functionCallStr);
						if (Object.keys(functionCallObj).length !== 0) {
							modelDetail.versions[j].function_call_obj =
								functionCallObj;
						}
						break;
					} else {
						continue;
					}
				}
				break;
			}
		}

		postJson('/chatgpt-models/update-model', modelDetail)
			.then((res) => {
				if (res.status == 200) {
					alert('Model was updated succesfully');
					window.location.reload();
				}
			})
			.catch((error) =>
				alert('An error occurred while updating the model' + error)
			);
	}
	function saveAsNewVersion() {
		if (!currentModelName || !currentVersionId) {
			alert('Please Select a model name and version Id to update');
			return;
		}
		const newVersionId = window.prompt(
			'Enter the new versionId for the config. For ex: v5'
		);
		if (
			newVersionId == '' ||
			newVersionId == null ||
			versionIdList.includes(newVersionId)
		) {
			alert('You need to put a new and unique version Id');
			return;
		}
		if (newVersionId == 'v1') {
			alert('You are not allowed to change v1');
			return;
		}
		console.log('User input:', newVersionId);

		let modelDetail;
		for (let i = 0; i < allModelDetails.length; i++) {
			if (allModelDetails[i].type == currentModelName) {
				modelDetail = allModelDetails[i];
				let newVersionObj = {};
				newVersionObj.version_id = newVersionId;
				let chatCompletionParams = safeJsonParse(
					chatCompletionParamsStr
				);
				newVersionObj.chat_completion_params = chatCompletionParams;
				newVersionObj.chatgpt_model = chatgptVersion;
				newVersionObj.has_followup_models = hasFollowupModels;
				newVersionObj.is_default = isVersionDefault;
				newVersionObj.prompt = prompt;
				newVersionObj.system_prompt = systemPrompt;
				newVersionObj.version_path = versionPath;
				newVersionObj.followup_models = followupModels;
				modelDetail.versions.push(newVersionObj);
				let functionCallObj = safeJsonParse(functionCallStr);
				if (Object.keys(functionCallObj).length !== 0) {
					newVersionObj.function_call_obj = functionCallObj;
				}
				break;
			}
		}

		postJson('/chatgpt-models/update-model', modelDetail)
			.then((res) => {
				if (res.status == 200) {
					alert('Model was updated succesfully');
					window.location.reload();
				}
			})
			.catch((error) =>
				alert('An error occurred while updating the model' + error)
			);
	}

	function deleteVersion() {
		if (!currentModelName || !currentVersionId) {
			alert('Please Select a model name and version Id to delete');
			return;
		}
		if (currentVersionId == 'v1') {
			alert('You are not allowed to delete v1');
			return;
		}
		let modelDetail;
		for (let i = 0; i < allModelDetails.length; i++) {
			if (allModelDetails[i].type == currentModelName) {
				modelDetail = allModelDetails[i];
				let newVersionsList = [];
				for (let j = 0; j < modelDetail.versions.length; j++) {
					if (
						modelDetail.versions[j].version_id == currentVersionId
					) {
						continue;
					}
					newVersionsList.push(modelDetail.versions[j]);
				}
				modelDetail.versions = newVersionsList;
			}
		}

		postJson('/chatgpt-models/update-model', modelDetail)
			.then((res) => {
				if (res.status == 200) {
					alert('Version was deleted succesfully');
					window.location.reload();
				}
			})
			.catch((error) =>
				alert('An error occurred while deleting the version' + error)
			);
	}

	function createNewModel() {
		if (!currentModelName || !currentVersionId) {
			alert(
				'Please Select a model name and version Id to source data from'
			);
			return;
		}
		const newModelName = window.prompt(
			'Enter the new model name ex: opportunity_summarizer'
		);
		const newVersionId = window.prompt(
			'Enter the version id in the new model ex: v1'
		);
		if (
			!newModelName ||
			newModelName === currentModelName ||
			newVersionId == '' ||
			newVersionId == null
		) {
			alert('You need to input a valid model and version id');
			return;
		}
		for (let i = 0; i < allModelDetails.length; i++) {
			if (allModelDetails[i].type == newModelName) {
				alert('A model with the same name already exists');
				return;
			}
		}
		console.log('User input:', newVersionId);

		let modelDetail = {};
		modelDetail['type'] = newModelName;
		let newVersionObj = {};
		newVersionObj.version_id = newVersionId;
		let chatCompletionParams = safeJsonParse(chatCompletionParamsStr);
		newVersionObj.chat_completion_params = chatCompletionParams;
		newVersionObj.chatgpt_model = chatgptVersion;
		newVersionObj.has_followup_models = hasFollowupModels;
		newVersionObj.is_default = isVersionDefault;
		newVersionObj.prompt = prompt;
		newVersionObj.system_prompt = systemPrompt;
		newVersionObj.version_path = versionPath;
		newVersionObj.followup_models = followupModels;
		modelDetail['versions'] = [newVersionObj];
		let functionCallObj = safeJsonParse(functionCallStr);
		if (Object.keys(functionCallObj).length !== 0) {
			newVersionObj.function_call_obj = functionCallObj;
		}
		postJson('/chatgpt-models/create-model', modelDetail)
			.then((res) => {
				if (res.status == 200) {
					alert(
						'Model was updated succesfully, please refresh the page'
					);
				}
				window.location.reload();
			})
			.catch((error) =>
				alert('An error occurred while updating the model' + error)
			);
	}

	return (
		<Paper style={{ padding: '20px', overflow: 'scroll' }}>
			<Backdrop open={loading} style={{ zIndex: '5000' }}>
				<CircularProgress />
			</Backdrop>
			<FormGroup>
				{formElement(
					select(currentModelName, setCurrentModelName, modelsList),
					'Model Name'
				)}
				{formElement(
					select(
						currentVersionId,
						setCurrentVersionId,
						versionIdList
					),
					'Version Id'
				)}
				{currentVersionId ? (
					<Accordion>
						<AccordionSummary expandIcon={<GridExpandMoreIcon />}>
							<Typography>
								<strong>Version Details</strong>
							</Typography>
						</AccordionSummary>
						<AccordionDetails
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<TextField
								style={{ marginBottom: '1rem' }}
								value={chatgptVersion}
								onChange={(e) =>
									setChatgptVersion(e.target.value)
								}
								label="Chatgpt Model Version"
							/>
							<TextField
								style={{ marginBottom: '1rem' }}
								value={versionPath}
								onChange={(e) => setVersionPath(e.target.value)}
								label="Version Path"
							/>

							{formElement(
								select(
									hasFollowupModels,
									setHasFollowupModels,
									[true, false]
								),
								'Has Followup models?'
							)}

							{formElement(
								select(isVersionDefault, setIsVersionDefault, [
									true,
									false,
								]),
								'Is Default Version?'
							)}
							<TextField
								style={{ marginBottom: '1rem' }}
								value={chatCompletionParamsStr}
								onChange={(e) =>
									setChatCompletionParamsStr(e.target.value)
								}
								label="Chat Completion Parameters"
								multiline
							/>
							<TextField
								style={{ marginBottom: '1rem' }}
								value={functionCallStr}
								onChange={(e) =>
									setFuncitonCallStr(e.target.value)
								}
								label="Function call object"
								multiline
							/>
							<TextField
								style={{ marginBottom: '1rem' }}
								value={prompt}
								onChange={(e) => setPrompt(e.target.value)}
								label="Prompt"
								multiline
							/>
							<TextField
								style={{ marginBottom: '1rem' }}
								value={systemPrompt}
								onChange={(e) =>
									setSystemPrompt(e.target.value)
								}
								label="System Prompt"
								multiline
							/>
							<div style={{ marginBottom: '1rem' }}>
								{hasFollowupModels
									? renderFollowupModels()
									: ''}
							</div>
						</AccordionDetails>
					</Accordion>
				) : (
					''
				)}
				<div
					style={{
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Button
						style={{ width: '100%', marginBottom: '0.7rem' }}
						variant="contained"
						color="primary"
						onClick={() => updateVersionDetails()}
						startIcon={<ApiIcon />}
					>
						Update Current Version
					</Button>
					<Button
						style={{ width: '100%', marginBottom: '0.7rem' }}
						variant="contained"
						color="primary"
						onClick={() => saveAsNewVersion()}
						startIcon={<ApiIcon />}
					>
						Save As New Version
					</Button>
					<Button
						style={{ width: '100%', marginBottom: '0.7rem' }}
						variant="contained"
						color="primary"
						onClick={() => createNewModel()}
						startIcon={<ApiIcon />}
					>
						Create a New Model
					</Button>
					<Button
						style={{ width: '100%', marginBottom: '0.7rem' }}
						variant="contained"
						color="secondary"
						onClick={() => deleteVersion()}
						startIcon={<ApiIcon />}
					>
						Delete Current Version
					</Button>
				</div>
			</FormGroup>
		</Paper>
	);
}
