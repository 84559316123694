import actionTypes from "./actions";

export default function gptScreenReducer(initialData, action) {
    switch (action.type) {
      //Only updates the curr screen name, this helps in updating the component on the screen.
      case actionTypes.UPDATE_SCREEN_TYPE: {
        return {
          ...initialData,
          screen: {
            ...initialData.screen,
            currScreen: action.payload,
          },
        };
      }
  
      case actionTypes.UPDATE_SCREEN_DATA: {
        return {
          ...initialData,
          screen: {
            ...initialData.screen,
            screenData: {
              ...initialData.screen.screenData,
              [action.payload.screeName]: action.payload.screenData,
            },
          },
        };
      }
  
      case actionTypes.UPDATE_SCREEN: {
        return {
          ...initialData,
          screen: {
            ...initialData.screen,
            currScreen: action.payload.screenName,
            screenData: {
              ...initialData.screen.screenData,
              [action.payload.updatedScreen]: action.payload.screenData,
            },
          },
        };
      }
  
      case actionTypes.SET_LOADING: {
        return {
          ...initialData,
          loading: true,
        };
      }
  
      case actionTypes.RESET_LOADING: {
        return {
          ...initialData,
          loading: false,
        };
      }
  
      case actionTypes.SET_ALERT: {
        return {
          ...initialData,
          alert: { ...action.payload },
        };
      }
  
      case actionTypes.RESET_ALERT: {
        return {
          ...initialData,
          alert: {
            hasError: false,
            message: '',
            error: null,
          },
        };
      }
  
      case actionTypes.SET_SUMMARY: {
        return {
          ...initialData,
          summary: {
            text: action.payload,
          },
        };
      }
  
      case actionTypes.RESET_SUMMARY: {
        return {
          ...initialData,
          summary: {
            text: '',
          },
        };
      }
  
      default: {
        return initialData;
      }
    }
  }