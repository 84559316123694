import { getData, getJsonData } from 'network';
import { CONTENT_TYPE } from 'utils/constants';
import { downloadBlob } from 'utils/dataUtils';

export const getAllManagersForCustomer = async (customerId) => {
    const response = await getData('/delete_call_data/get_all_manager_roles', {
        customerId,
    }).then(async (res) => await res.json());

    if (!(Array.isArray(response) && response.length)) return [];
    const allManagersList = response.reduce((acc, manager) => {
        if ((manager?.display_name || manager?.email) && manager?._id) {
            acc.push({
                label: manager?.display_name ?? manager?.email_id,
                value: manager?._id,
                ...manager,
            });
        }
        return acc;
    }, []);
    return allManagersList;
};

export const getAllDeletionRequestsForCustomer = async (customerId) => {
    const resp = await getData(
        '/delete_call_data/get_all_deletion_requests_for_customer',
        { customerId },
    );
    const responseJson = await resp.json();
    if (!(Array.isArray(responseJson) && responseJson.length)) return [];
    return responseJson;
};

export const getAllCustomers = async () => {
    const response = await getJsonData('/customers/all');
    if (!(response && response?.length)) return [];
    const allCustomerOptions = response.reduce((allOptions, currOption) => {
        if (!(currOption?.name && currOption?._id)) return allOptions;
        allOptions.push({
            label: currOption?.name,
            value: currOption?._id,
            ...currOption,
        });
        return allOptions;
    }, []);
    return allCustomerOptions;
};


export const downloadFileFromS3 = async (url, fileName, contentType) => {
    // example url 'https://backup-wingman.s3.amazonaws.com/dev-deleted-call-ids/64f030cfd6bd94197658edd9.csv';
    const response = await getData('/delete_call_data/download_file_from_s3', {
        url,
    }).then((res) => res.json());
    const pom = document.createElement('a');
    pom.href = response?.signedUrl;
    pom.download = fileName;
    pom.click();
};
