import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
} from '@mui/material';
import { getData } from 'network';
import { formatQuery } from './util';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getRelevantFileName } from './util';

function S3DataView({ data }) {
	const [fileContent, setFileContent] = useState({});
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState('');
	const [isHeadersDialogOpen, setIsHeadersDialogOpen] = useState(false);
	const [currentHeaders, setCurrentHeaders] = useState({});
	const [fileType, setFileType] = useState('');

	const fetchFileContent = async (fileName) => {
		try {
			console.log(`Fetching file content for: ${fileName}`);
			const response = await getData(`/debug_call/integration/fetch-fileContent`, { filename: fileName });
	  
			if (!response.ok) {
				throw new Error(`Server responded with status: ${response.status}`);
			}
	  
			const responseData = await response.json();
			return responseData.content; // Return raw content
		} catch (error) {
			console.error('Error fetching file content for:', fileName, error);
			return null; // Indicate an error occurred
		}
	};
	
	const handleOpenFile = async (fileName) => {
		try {
			const rawContent = await fetchFileContent(fileName);
			if (rawContent) {
				const fileType = fileName.includes('_response') ? 'response' : 'request';
				let content;
				if (fileType === 'response') {
					// Parse and re-stringify the content to format it as JSON
					try {
						content = JSON.stringify(JSON.parse(rawContent), null, 2);
					} catch (e) {
						// If parsing fails, fallback to the raw string
						content = rawContent;
					}
				} else {
					// If it's a request, parse the JSON string to an object
					content = JSON.parse(rawContent);
				}
				setFileContent(content);
				setFileType(fileType);
				setSelectedFile(getRelevantFileName(fileName));
				setIsDialogOpen(true);
			}
		} catch (error) {
			console.error('Error handling file open for:', fileName, error);
		}
	};
	
	  

	const handleDownload = async (fileName) => {
		try {
			const content = await fetchFileContent(fileName);
			if (content) {
				// Check if content is a JSON string
				let blobContent;
				try {
					
					JSON.parse(content);
					blobContent = content; // If it's valid JSON, use it directly
				} catch (error) {
					
					blobContent = JSON.stringify(content, null, 2);
				}
	
				const blob = new Blob([blobContent], {
					type: 'application/json', 
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = `${fileName}.txt`;
				link.click();
				window.URL.revokeObjectURL(url);
			}
		} catch (error) {
			console.error('Error downloading file:', fileName, error);
		}
	};
	

	const contentTableStyles = {
		width: '100%',
		minWidth: '800px',
		maxHeight: '100vh',
		minHeight: '65vh',
		overflowY: 'auto',
	};

	// Styles for the file list table
	const fileListTableStyles = {
		width: '100%',
		minWidth: '800px',
	};

	const tableCellStyles = {
		borderRight: '1px solid #ddd',
		padding: '10px 20px',
	};

	const buttonStyles = {
		margin: '10px',
		width: '130px',
	};

	return (
		<>
			<TableContainer component={Paper} style={fileListTableStyles}>
				<Table aria-label="s3 data table">
					<TableHead>
						<TableRow>
							<TableCell style={tableCellStyles}>
								Filename
							</TableCell>
							<TableCell style={tableCellStyles}>Size</TableCell>
							<TableCell style={tableCellStyles}>
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length > 0 ? (
							data.map((file, index) => (
								<TableRow key={index}>
									<TableCell style={tableCellStyles}>
										{getRelevantFileName(file.name)}
									</TableCell>
									<TableCell style={tableCellStyles}>
										{file.size
											? `${file.size} bytes`
											: 'Unknown'}
									</TableCell>
									<TableCell style={tableCellStyles}>
										<IconButton
											color="primary"
											onClick={() =>
												handleOpenFile(file.name)
											}
										>
											<VisibilityIcon />
										</IconButton>
										<IconButton
											color="secondary"
											onClick={() =>
												handleDownload(file.name)
											}
										>
											<CloudDownloadIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={3} style={tableCellStyles}>
									No files fetched
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog
				open={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				maxWidth="100vw"
				minWidth="85vw"
				fullWidth={true}
			>
				<DialogTitle>File Details for {selectedFile}</DialogTitle>
				<DialogContent>
					{fileType === 'response' ? (
						<pre>{fileContent}</pre>
					) : (
						// Display Request Data in Table
						<TableContainer
							component={Paper}
							style={contentTableStyles}
						>
							<Table
								size="small"
								aria-label="request content table"
							>
								<TableHead>
									<TableRow>
										<TableCell style={tableCellStyles}>
											Headers
										</TableCell>
										<TableCell style={tableCellStyles}>
											Params
										</TableCell>
										<TableCell style={tableCellStyles}>
											Body
										</TableCell>
										<TableCell style={tableCellStyles}>
											URL
										</TableCell>
										<TableCell style={tableCellStyles}>
											Timestamp
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell style={tableCellStyles}>
											{fileContent.headers ? (
												<Button
													variant="outlined"
													color="primary"
													onClick={() => {
														setCurrentHeaders(
															fileContent.headers
														);
														setIsHeadersDialogOpen(
															true
														);
													}}
												>
													View Headers
												</Button>
											) : (
												'No headers'
											)}
										</TableCell>

										<TableCell style={tableCellStyles}>
											{fileContent.params
												? fileContent.params.map(
														(param) => (
															<div
																key={param.name}
															>
																<strong>
																	{param.name}
																	:
																</strong>
																<pre>
																	{formatQuery(
																		param.value
																	)}
																</pre>
															</div>
														)
												  )
												: 'No params'}
										</TableCell>

										<TableCell style={tableCellStyles}>
											{fileContent.body
												? JSON.stringify(
														fileContent.body
												  )
												: 'No body'}
										</TableCell>
										<TableCell style={tableCellStyles}>
											{fileContent.url
												? JSON.stringify(
														fileContent.url
												  )
												: 'No URL'}
										</TableCell>
										<TableCell style={tableCellStyles}>
											{fileContent.timestamp
												? JSON.stringify(
														fileContent.timestamp
												  )
												: 'No timestamp'}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => setIsDialogOpen(false)}
						color="primary"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={isHeadersDialogOpen}
				onClose={() => setIsHeadersDialogOpen(false)}
				maxWidth="lg"
				fullWidth={true}
				maxHeight="mg"
			>
				<DialogTitle>Headers</DialogTitle>
				<DialogContent>
					<pre>{JSON.stringify(currentHeaders, null, 2)}</pre>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setIsHeadersDialogOpen(false)}
						color="primary"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default S3DataView;
