import React, { useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';

export default function ObjectTreeView(props) {
	const [expanded, setExpanded] = useState([]);

	function render(label, obj, path) {
		if (!obj) return null;
		var children = [];
		if (typeof obj === 'object') {
			children = Object.keys(obj).map((i) =>
				render(i, obj[i], path + '.' + i)
			);
			label += ' (' + children.length + ')';
		} else {
			label += ': ' + obj;
		}
		return (
			<TreeItem
				sx={{ width: '600px' }}
				key={path}
				nodeId={path}
				label={label}
			>
				{children}
			</TreeItem>
		);
	}

	function getAllNodeIds(obj, path, depth) {
		var children = [];
		if (depth > 0 && typeof obj === 'object') {
			children = Object.keys(obj)
				.map((i) => getAllNodeIds(obj[i], path + '.' + i, depth - 1))
				.flat();
		}
		return [path, ...children];
	}

	return (
		<>
			<Button
				onClick={() =>
					setExpanded(getAllNodeIds(props.obj, props.name, 2))
				}
			>
				Expand all
			</Button>
			<TreeView
				expanded={expanded}
				onNodeToggle={(e, exp) => setExpanded(exp)}
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
			>
				{render(props.name, props.obj, props.name)}
			</TreeView>
		</>
	);
}
