import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';
import MultilineTextInput from 'components/common/MultilineTextInput';
import TextHorizontalDivider from 'components/common/TextHorizontalDivider';

function GptPlaygroundCallTextInput(props) {
	const {
		isInputState,
		callId,
		setCallIdHandler,
		userText,
		onUserTextHandle,
		onNextHandle,
	} = props;

	const isCallInputVisible = isInputState || !!callId.length;
	const isTextInputVisible = isInputState || !!userText.length;

	const isNextButtonEnabled = (callId, userText) => {
		return callId?.length || userText?.length;
	};

	return (
		<>
			{isCallInputVisible && (
				<TextField
					id="outlined-basic"
					value={callId}
					fullWidth
					label="Enter the Call-Id"
					size="large"
					variant="outlined"
					onChange={setCallIdHandler}
					disabled={!!userText.length}
				/>
			)}
			{isInputState && (
				<TextHorizontalDivider sx={{ width: '100%' }} text="OR" />
			)}
			{isTextInputVisible && (
				<MultilineTextInput
					minHeight="30vh"
					size="large"
					variant="outlined"
					id="user-text"
					label="Enter your text here"
					onChange={onUserTextHandle}
					value={userText}
					maxChars={50000}
					disabled={!!callId.length}
				/>
			)}
			{isInputState && (
				<Button
					sx={{ marginY: '2rem' }}
					variant="contained"
					onClick={onNextHandle}
					disabled={!isNextButtonEnabled(callId, userText)}
				>
					{'Next'}
				</Button>
			)}
		</>
	);
}

GptPlaygroundCallTextInput.propTypes = {
	isInputState: PropTypes.bool,
	callId: PropTypes.string,
	userText: PropTypes.string,
	setCallIdHandler: PropTypes.func,
	onUserTextHandle: PropTypes.func,
	onNextHandle: PropTypes.func,
};

export default GptPlaygroundCallTextInput;
