import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Typography,
	IconButton,
} from '@mui/material';
import { Popover } from '@mui/material';
import { formatDate } from './util';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { CircularProgress } from '@mui/material';

function IntegrationMetricsTable({
	data,
	onFetchS3,
	onViewDetails,
	activeSyncId,
	isFetchingS3Data,
}) {
	const calculateSum = (obj) => {
		return Object.values(obj).reduce((acc, val) => acc + val, 0);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [popoverData, setPopoverData] = React.useState(null);

	const handlePopoverOpen = (event, data) => {
		setAnchorEl(event.currentTarget);
		setPopoverData(data);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
		setPopoverData(null);
	};

	const open = Boolean(anchorEl);

	return (
		<TableContainer component={Paper}>
			<Table aria-label="integration metrics table" size="small">
				<TableHead>
					<TableRow>
						<TableCell style={{ width: '10%' }}>Time</TableCell>
						<TableCell style={{ width: '10%' }}>Sync ID</TableCell>
						<TableCell style={{ width: '10%' }}>
							Sched Msgs
						</TableCell>
						<TableCell style={{ width: '10%' }}>Errors</TableCell>
						<TableCell style={{ width: '13%' }}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((item, index) => (
						<TableRow key={index}>
							<TableCell style={{ width: '20%' }}>
								{formatDate(item.startTime)}
							</TableCell>
							<TableCell
								style={{ width: '30%', fontSize: '0.8rem' }}
							>
								<span
									onClick={() => onViewDetails(item)}
									style={{
										cursor: 'pointer',
										textDecoration: 'underline',
									}}
								>
									{item.syncId}
								</span>
							</TableCell>
							<TableCell style={{ width: '10%' }}>
								<span
									onClick={(event) =>
										handlePopoverOpen(
											event,
											item.messageScheduledCount
										)
									}
									style={{
										cursor: 'pointer',
										textDecoration: 'underline',
									}}
								>
									{calculateSum(item.messageScheduledCount)}
								</span>
							</TableCell>
							<TableCell style={{ width: '10%' }}>
								<span
									onClick={(event) =>
										handlePopoverOpen(
											event,
											item.messageErroredCount
										)
									}
									style={{
										cursor: 'pointer',
										textDecoration: 'underline',
									}}
								>
									{calculateSum(item.messageErroredCount)}
								</span>
							</TableCell>
							<TableCell
								style={{ width: '5%', padding: '6px 8px' }}
							>
								{isFetchingS3Data &&
								activeSyncId === item.syncId ? (
									<CircularProgress size={20} />
								) : (
									<IconButton
										color={
											activeSyncId === item.syncId
												? 'secondary'
												: 'primary'
										}
										onClick={() => onFetchS3(item.syncId)}
										disabled={
											isFetchingS3Data &&
											activeSyncId !== item.syncId
										}
									>
										<FolderOpenIcon />
									</IconButton>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Popover
				id={`popover-${anchorEl?.dataset.idx}`}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography sx={{ p: 2 }}>
					{popoverData &&
						Object.entries(popoverData).map(([key, value]) => (
							<div key={key}>
								<strong>{key}:</strong> {value}
							</div>
						))}
				</Typography>
			</Popover>
		</TableContainer>
	);
}

export default IntegrationMetricsTable;
