import React, { useState, useEffect } from 'react';
import {
	Paper,
	IconButton,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
	Snackbar,
	Alert,
	Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import { getData } from 'network';

const ExperimentTable = ({ experiments }) => {
	const [searchText, setSearchText] = useState('');
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedExperiment, setSelectedExperiment] = useState(null);
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');

	useEffect(() => {
		if (!selectedExperiment) return;

		const fetchUsers = async () => {
			setIsLoading(true);
			setError(null);

			try {
				const response = await getData(
					`/support_tools/experiments/${selectedExperiment._id}/customers`
				);
				const data = await response.json();

				if (data && Object.keys(data).length > 0) {
					setUsers(data);
				} else {
					setSnackbarMessage('No customers found for the experiment');
					setIsSnackbarOpen(true);
				}
			} catch (error) {
				setError('Error fetching users');
				setSnackbarMessage('Internal server error');
				setIsSnackbarOpen(true);
			} finally {
				setIsLoading(false);
			}
		};

		fetchUsers();
	}, [selectedExperiment]);

	const handleEditClick = (experiment) => {
		setSelectedExperiment(experiment);
		setIsDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
		setSelectedExperiment(null);
		setUsers([]);
		setError(null);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') return;
		setIsSnackbarOpen(false);
	};

	const renderDialogContent = () => {
		if (isLoading) return <CircularProgress />;
		if (error) return <Typography color="error">{error}</Typography>;
		if (users.length === 0)
			return (
				<Typography>No customers found for the experiment</Typography>
			);
		return (
			<List>
				{Object.entries(users).map(([key, value]) => (
					<ListItem key={key}>
						<ListItemText primary={`${key} - ${value}`} />
					</ListItem>
				))}
			</List>
		);
	};

	const columns = [
		{
			field: '_id',
			headerName: 'ID',
			width: 200,
			headerAlign: 'center',
			align: 'left',
			renderCell: (params) => (
				<>
					<IconButton
						onClick={() => handleEditClick(params.row)}
						sx={{ mr: 1 }}
					>
						<CreateIcon />
					</IconButton>
					{params.value}
				</>
			),
		},
		{
			field: 'name',
			headerName: 'Name',
			width: 300,
			headerAlign: 'center',
			align: 'left',
		},
		{
			field: 'call_type',
			headerName: 'Call Type',
			width: 200,
			headerAlign: 'center',
			align: 'left',
		},
	];

	const visibleExperiments = experiments.filter(
		(exp) =>
			exp._id.toLowerCase().includes(searchText.toLowerCase()) ||
			exp.name.toLowerCase().includes(searchText.toLowerCase()) ||
			exp.call_type.toLowerCase().includes(searchText.toLowerCase())
	);

	return (
		<Paper sx={{ height: '50%', width: '100%' }}>
			<TextField
				label="Search Experiments"
				variant="outlined"
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				size="small"
				sx={{ width: '40%', m: 1 }}
			/>
			<Button variant="contained" sx={{ m: 1 }}>
				Add Customer
			</Button>
			<DataGrid
				rows={visibleExperiments}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				getRowId={(row) => row._id}
				density="compact"
				sx={{ height: '100%' }}
			/>
			{selectedExperiment && (
				<Dialog
					open={isDialogOpen}
					onClose={handleCloseDialog}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">
						Edit Experiment: {selectedExperiment._id}
					</DialogTitle>
					<DialogContent>{renderDialogContent()}</DialogContent>
					<Button onClick={handleCloseDialog} color="primary">
						Close
					</Button>
				</Dialog>
			)}
			<Snackbar
				open={isSnackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity="error"
					sx={{ width: '100%' }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Paper>
	);
};

export default ExperimentTable;
