import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Button, Checkbox, Select, MenuItem, Input } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import {CallData} from './service_pb';
import { getData, getJsonData, postData } from './network';

export default function FixDiarization() {
  const [callId, setCallId] = useState(null);
  const [fetchedCallId, setFetchedCallId] = useState('');
  const [callData, setCallData] = useState(null);
  const [users, setUsers] = useState([]);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (!fetchedCallId) return;
    getData('/diarization/calldata?callId=' + fetchedCallId)
    .then(resp => resp.arrayBuffer())
    .then(bytes => {
      setCallData(CallData.deserializeBinary(bytes));
    }).catch(console.error);
    getJsonData('/customers/users?callId=' + fetchedCallId)
    .then(u => setUsers(u))
    .catch(console.error);
  }, [fetchedCallId]);

  function saveCallData() {
    const body = callData.serializeBinary();
    console.log('Saving call data, len:', body.length);
    postData('/diarization/update-calldata?callId=' + fetchedCallId, body, {'Content-Type': 'application/octet-stream'})
      .then(resp => resp.text())
      .then(resp => {
        setChanged(false);
        alert(resp);
      })
      .catch(err => {
        console.error(err);
        alert('Some error happened, see console for more details');
      });
  }

  function renderChannels() {
    if (!callData || !callData.getDiarizationResponse() || !callData.getDiarizationResponse().getEngineIdToStringsIdList() || callData.getDiarizationResponse().getEngineIdToStringsIdList().length === 0) return "No channels";
    return callData.getDiarizationResponse().getEngineIdToStringsIdList().map(c => <Channel key={c.getStringsId()} channel={callData.getChannelList()[c.getStringsId()]} engineIdStringsIdEntry={c} users={users} setChanged={setChanged} />)
  }

  function recorderDiarizationNames() {
    if (!callData) return null;
    var rd = callData.getRecorderCapturedDiarization();
    if (rd && rd.getEngineIdToStringsIdList() !== null) {
      return <div><div>Recorder diarization names:</div>{rd.getEngineIdToStringsIdList().map(mi => mi.getSpeakerName()).join(', ')}</div>;
    }
    return null;
  }

  return (<>
    <div style={{padding: '20px'}}>
      <TextField style={{width: '350px'}} value={callId} onChange={e => setCallId(e.target.value)} label="Call id" />
      <IconButton onClick={() => setFetchedCallId(callId)}><SyncIcon /></IconButton>
      {changed && <Button onClick={saveCallData} variant="contained" color="primary">Save</Button>}
      {fetchedCallId && renderChannels()}
      {recorderDiarizationNames()}
    </div>
  </>);
}

function Channel({engineIdStringsIdEntry, channel, users, setChanged}) {
  const [isRep, setIsRep] = useState(engineIdStringsIdEntry.getIsRep());
  const [userId, setUserId] = useState(engineIdStringsIdEntry.getUserId());
  const [speakerName, setSpeakerName] = useState(engineIdStringsIdEntry.getSpeakerName());

  useEffect(() => {
    if (isRep !== engineIdStringsIdEntry.getIsRep()) engineIdStringsIdEntry.setIsRep(isRep);
    if (isRep !== channel.getIsRep()) { channel.setIsRep(isRep); setChanged(true); }
  }, [isRep]);

  useEffect(() => {
    if (userId !== engineIdStringsIdEntry.getUserId()) engineIdStringsIdEntry.setUserId(userId);
    if (userId !== channel.getUserId()) { channel.setUserId(userId); setChanged(true); }
  }, [userId]);

  useEffect(() => {
    if (speakerName !== engineIdStringsIdEntry.getSpeakerName()) { engineIdStringsIdEntry.setSpeakerName(speakerName); setChanged(true); }
  }, [speakerName]);

  return (<div>
    <Input value={speakerName} onChange={e => setSpeakerName(e.target.value)} />
    <Checkbox checked={isRep} onChange={e => setIsRep(e.target.checked)} />
    <Select disabled={!isRep} value={isRep ? userId : null} onChange={e => setUserId(e.target.value)}>
      <MenuItem value={null}>NONE</MenuItem>
      {users.map(u => <MenuItem value={u._id}>{u.display_name} ({u.email_id})</MenuItem>)}
    </Select>
  </div>);
}