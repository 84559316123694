import { TextField, Autocomplete } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';

function CustomerSelect(props) {
	console.log(props.customers);
	return (
		<div style={{ width: '250px' }}>
			{Array.isArray(props.customers) ? (
				<Autocomplete
					options={props.customers}
					getOptionLabel={(c, index) => `${c.name}`}
					onChange={(e, v) => props.onSelect(v && v._id)}
					renderInput={(params) => (
						<TextField {...params} label="Customer" fullWidth />
					)}
					renderOption={(props, option, { selected, index }) => (
						<li {...props} key={option._id}>
							{option.name}
						</li>
					)}
				/>
			) : null}
		</div>
	);
}

export default connect((a) => a, actions)(CustomerSelect);
