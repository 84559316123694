import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { useState } from 'react';
import { getJsonData, postJson } from './network';
import CustomerMigration from './CustomerMigration';
import CustomerSelect from './CustomerSelect';
import { toggleSwitch, formElement } from "./components/common/FormControls";

export default function Migration(props) {
  const [migrationConfig, setMigrationConfig] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [doNotSendOnBoardingEmails, setDoNotSendOnBoardingEmails] = useState(false);
  const [writeCopilotLinksToCrm, setWriteCopilotLinksToCrm] = useState(false);

  const toggleOnboardingControl = async (data) => {
    const updatedOnBoardingEmailFlag = !doNotSendOnBoardingEmails;
    setDoNotSendOnBoardingEmails((prevState) => !prevState);
    await postJson('/customers/update_onboarding_email_flag', { customerId: selectedCustomerId, onBoardingFlag: updatedOnBoardingEmailFlag });
  };

  const toggleWriteToCrm = async (data) => {
    const updatedWriteToCrmFlag = !writeCopilotLinksToCrm;
    setWriteCopilotLinksToCrm((prevState) => !prevState);
    await postJson('/customers/write_to_crm_during_migration_flag', { customerId: selectedCustomerId, writeToCrmFlag: updatedWriteToCrmFlag });
  };

  function refreshSelectedCustomer() {
    console.log('Reloading migration config for ' + selectedCustomerId);
    getJsonData('/migration/' + selectedCustomerId + '/migration-config').then(r => {
      if (r.success) {
        setMigrationConfig(r.config);
      } else {
        setMigrationConfig(null);
      }
    }).catch(console.error);
  }

  useEffect(() => {
    if (!selectedCustomerId) {
      setMigrationConfig(null);
    } else {
      refreshSelectedCustomer(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  async function fetchCustomerData(customerId) {
    const customerData = await getJsonData('/customers/get_partial_customer_data/' + customerId);
    setDoNotSendOnBoardingEmails(customerData.do_not_sent_onboarding_emails);
  }

  async function selectCustomer(id) {
    setSelectedCustomerId(id);
    fetchCustomerData(id);
  }

  return (<Paper style={{ padding: '30px' }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '3rem',
        marginBottom: '1rem'
      }}
    >
      <CustomerSelect onSelect={id => selectCustomer(id)} />
      {selectedCustomerId && formElement(
        toggleSwitch({
          state: doNotSendOnBoardingEmails,
          handleChange: toggleOnboardingControl,
          label: 'Do not send onboarding emails',
        })
      )},
      {selectedCustomerId && formElement(
          toggleSwitch({
            state: writeCopilotLinksToCrm,
            handleChange: toggleWriteToCrm,
            label: 'Write copilot links to crm',
          })
      )}
    </div>
    {selectedCustomerId && <CustomerMigration
      migrationConfig={migrationConfig}
      selectedCustomerId={selectedCustomerId}
      onSync={refreshSelectedCustomer} />}
  </Paper>);
}

