import React from 'react';
import { Link } from "react-router-dom";
import { Paper, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  a: {
    textDecoration: 'none'
  }
}));

export default function Home(props) {
  const classes = useStyles();
  return (<Grid container spacing={3}>
    {props.links.filter(link => !link.hidden).map((link, i) => (<Grid item key={i} xs={4}>
      <Link key={link.url} to={link.url} className={classes.a}>
        <Paper className={classes.paper}>
          <div>{link.icon}</div>
          {link.text}
        </Paper>
      </Link>
    </Grid>))}
  </Grid>);
}