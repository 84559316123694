import { Button, Modal } from '@mui/material';
import { getData } from 'network';
import React, { useEffect, useState } from 'react';

function ConfirmDeletionDialog({ open, handleClose, requestDoc }) {
    const [numCalls, setNumCalls] = useState(0);

    const [numSharedSnippets, setNumSharedSnippets] = useState(0);
    const [numGametapes, setNumGametapes] = useState(0);

    useEffect(() => {
        setNumCalls(requestDoc?.total_recorded_calls_to_delete || 0);
        setNumSharedSnippets(requestDoc?.total_shared_snippet_to_delete || 0);
        setNumGametapes(requestDoc?.total_game_tapes_to_delete || 0);
    }, [requestDoc]);

    const deleteCallsForRequest = async (requestId) => {
        const response = await getData('/delete_call_data/confirm', {
            docId: requestId,
        });
        if (response.status === 500) alert('Request failed');
        const responseJSON = await response.json();
        alert(
            responseJSON?.message
				|| 'Your call deletion will take some time. Please check the status after some time.',
        );
        handleClose();
    };

    return (
      <Modal
          open={open}
          onClose={handleClose}
          style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
          <div
              style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '50%',
                    outline: 'none',
                    padding: '3rem',
                    gap: '1rem',
                }}
            >
              <div>{`${numCalls} calls match your deletion criteria`}</div>
              <div>{`${numSharedSnippets} externally shared links and ${numGametapes} gametapes will be deleted`}</div>
              <div
                  style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '4rem 0 1rem 2rem',
                        color: 'red',
                        gap: '0.5rem',
                    }}
                >
                  {'Note: This is NON Recoverable'}
                  <Button
                      variant="contained"
                      style={{ width: '12rem' }}
                      onClick={() => deleteCallsForRequest(requestDoc._id)}
                    >
                      Confirm Deletion
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmDeletionDialog;
