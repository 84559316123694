import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Menu,
	Badge,
	FormControl,
	InputLabel,
	Paper,
	Typography,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import {
	TextField,
	Select,
	MenuItem,
	FormControlLabel,
	Button,
} from '@mui/material';
import { postJson } from './network';
import SyncIcon from '@mui/icons-material/Sync';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineOppositeContent,
} from '@mui/lab';
import { getConfig } from './config';
import { DataGrid } from '@mui/x-data-grid';
import MyDoughnut from './MyDoughnut';

const appLink = getConfig().appLink;

const REPROCESS_STATUSES = new Set([
	'POST_PROCESSING_DONE',
	'ERROR_IN_PROCESSING',
	'NO_DATA_INCALL',
	'COMPLETED',
]);
const REPROCESS_LANGUAGES = [
	'ENGLISH_US',
	'SPANISH_US',
	'PORTUGUESE_BR',
	'DANISH_DK',
	'SWEDISH_SE',
	'FRENCH_FR',
	'DUTCH_NL',
	'GERMAN_DE',
	'ITALIAN_IT',
	'TURKISH_TR',
	'NORWEGIAN_NO',
	'INDONESIAN_ID',
	'RUSSIAN_RU',
	'HINDI_IN',
	'ENGLISH_IN',
	'ARABIC_BH',
	'ENGLISH_GB',
	'JAPANESE_JP',
	'MANDARIN',
	'ROMANIAN_RO',
	'POLISH_PL',
	'ENGLISH_GLOBAL',
	'KOREAN_KR',
	'FINNISH_FI',
	'CATALAN_ES',
	'UKRAINIAN_UA',
];
const DAY_IN_MS = 1000 * 60 * 60 * 24;

export default function CallStatuses() {
	const [callIdsStr, setCallIdsStr] = useState('');
	const [callStatuses, setCallStatuses] = useState([]);
	const [useRecorderDiarization, setUseRecorderDiarization] = useState(false);
	const [startStageStr, setStartStageStr] = useState('START_ALL');
	const [reprocessLanguage, setReprocessLanguage] = useState('');
	const [submitted, setSubmitted] = useState(new Set());
	const [fetchingCallStatus, setFetchingCallStatus] = useState(false);
	const [callStatusStats, setCallStatusStats] = useState(null);
	const [callTypeStats, setCallTypeStats] = useState(null);
	const [callCustomerStats, setCallCustomerStats] = useState(null);
	const [distributionDialogOpen, setDistributionDialogOpen] = useState(false);
	const [typeMenuAnchorEl, setTypeMenuAnchorEl] = useState(null);
	const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
	const [customerMenuAnchorEl, setCustomerMenuAnchorEl] = useState(null);
	const [uniqCallTypes, setUniqCallTypes] = useState([]);
	const [uniqCallStatuses, setUniqCallStatuses] = useState([]);
	const [uniqCustomers, setUniqCustomers] = useState([]);
	const [filteredCallTypes, setFilteredCallTypes] = useState([]);
	const [filteredCallStatuses, setFilteredCallStatuses] = useState([]);
	const [filteredCustomers, setFilteredCustomers] = useState([]);
	const [filteredCalls, setFilteredCalls] = useState([]);
	const [reprocessDialogOpen, setReprocessDialogOpen] = useState(false);
	const [selectedStatusLog, setSelectedStatusLog] = useState(null);
	const [loading, setLoading] = useState(false);
	const [forceCleanup, setForceCleanup] = useState(false);

	useEffect(() => {
		setFilteredCalls(
			callStatuses.filter((s) => {
				return (
					filteredCallTypes.indexOf(s.type) === -1 &&
					filteredCallStatuses.indexOf(s.status) === -1 &&
					filteredCustomers.indexOf(s.customer_name) === -1
				);
			})
		);
	}, [
		callStatuses,
		filteredCallTypes,
		filteredCallStatuses,
		filteredCustomers,
	]);

	useEffect(() => {
		setCallStatusStats(makeMap(filteredCalls.map((r) => r.status)));
		setCallTypeStats(makeMap(filteredCalls.map((r) => r.type)));
		setCallCustomerStats(
			makeMap(filteredCalls.map((r) => r.customer_name))
		);
	}, [filteredCalls]);

	const columns = [
		{
			field: 'id',
			headerName: 'Id',
			renderCell: (params) => (
				<a target="_blank" href={`${appLink}/call/${params.row.id}`}>
					{params.row.id}
				</a>
			),
		},
		{
			field: 'type',
			headerName: 'Type',
		},
		{
			field: 'customer_name',
			headerName: 'Customer name',
		},
		{
			field: 'name',
			headerName: 'Call title',
			flex: 1,
		},
		{
			field: 'status',
			renderCell: (params) => {
				if (params.row.call_status_logs) {
					return (
						<Button
							onClick={() => {
								setSelectedStatusLog(
									params.row.call_status_logs
								);
							}}
						>
							{params.row.status}
						</Button>
					);
				}
				return params.row.status;
			},
		},
	];

	function makeMap(vals) {
		return vals.reduce((o, i) => {
			if (!o.hasOwnProperty(i)) o[i] = 0;
			o[i]++;
			return o;
		}, {});
	}

	function uniq(vals) {
		return [...new Set(vals)];
	}

	function fetchCallStatuses() {
		setFetchingCallStatus(true);
		const ids = callIdsStr.split('\n').map((c) => c.trim());
		postJson('/debug_call/statuses', { ids })
			.then((resp) => resp.json())
			.then((resp) => {
				setCallStatuses(resp);
				if (resp) {
					setUniqCallStatuses(uniq(resp.map((r) => r.status)));
					setUniqCallTypes(uniq(resp.map((r) => r.type)));
					setUniqCustomers(uniq(resp.map((r) => r.customer_name)));
					setFilteredCallStatuses([]);
					setFilteredCallTypes([]);
					setFilteredCustomers([]);
				}
				setFetchingCallStatus(false);
			});
	}
	function submitReprocessAllReq() {
		if (!filteredCalls) return;
		setLoading(true);
		var callIds = filteredCalls.map((c) => c.id);
		console.log('Submitting reprocess for all call', callIds);
		const endpoint = forceCleanup
			? '/debug_call/force_cleanup'
			: '/debug_call/reprocess';

		postJson(endpoint, {
			callIds,
			useRecorderDiarization,
			startStageStr,
			reprocessLanguage,
			cleanupPartialData: forceCleanup,
		})
			.then(console.log)
			.catch((err) => {
				alert('Error reprocessing');
				console.error(
					'Caught error submitting calls for reprocessing',
					err
				);
			})
			.then(() => setLoading(false));
	}

	function callStatusesTable() {
		if (callStatuses.length === 0) return <div />;
		return (
			<Paper style={{ height: 500, width: '100%' }}>
				<DataGrid
					rows={filteredCalls}
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					density="compact"
				/>
			</Paper>
		);
	}

	function formElement(elem, label, opacity = 1) {
		return (
			<FormControl variant="standard" style={{ opacity, width: 200 }}>
				{label && <InputLabel>{label}</InputLabel>}
				{elem}
			</FormControl>
		);
	}

	function select(state, setState, arr, multiple) {
		return (
			<Select
				value={state}
				multiple={multiple}
				onChange={(e) => setState(e.target.value)}
			>
				{arr.map((item, i) => (
					<MenuItem key={i} value={item}>
						{item}
					</MenuItem>
				))}
			</Select>
		);
	}

	function reprocessDialog() {
		return (
			<Dialog
				open={reprocessDialogOpen}
				fullWidth
				onClose={() => setReprocessDialogOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle>
					Reprocess {filteredCalls.length} calls
				</DialogTitle>
				<DialogContent>
					<FormControlLabel
						label="Recorder diarization"
						control={
							<Checkbox
								checked={useRecorderDiarization}
								onChange={(e) =>
									setUseRecorderDiarization(e.target.checked)
								}
							/>
						}
					/>
					<FormControlLabel
						label="Force Cleanup"
						control={
							<Checkbox
								checked={forceCleanup}
								onChange={(e) =>
									setForceCleanup(e.target.checked)
								}
							/>
						}
					/>
					{formElement(
						select(
							startStageStr,
							setStartStageStr,
							[
								'START_ALL',
								'TRANSCRIBE',
								'PROCESS_TRANSCRIPT',
								'DIARIZE_PROCESS_TRANSCRIPT',
								'UPDATE_PHRASES',
								'UPDATE_SUMMARY',
							],
							false
						),
						'Processing Stage'
					)}
					{formElement(
						select(
							reprocessLanguage,
							setReprocessLanguage,
							REPROCESS_LANGUAGES,
							false
						),
						'Reprocess Language'
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setReprocessDialogOpen(false)}>
						Close
					</Button>
					<Button
						onClick={() => {
							if (!filteredCalls) return;
							setSubmitted(new Set());
							submitReprocessAllReq();
							setReprocessDialogOpen(false);
						}}
						color="primary"
						autoFocus
					>
						Reprocess
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function distributionDialog() {
		return (
			<Dialog
				open={distributionDialogOpen}
				fullWidth
				onClose={() => setDistributionDialogOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Call distribution ({filteredCalls && filteredCalls.length})
				</DialogTitle>
				<DialogContent>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{callStatusStats && (
							<MyDoughnut
								title="Call status distribution"
								stats={callStatusStats}
							/>
						)}
						{callTypeStats && (
							<MyDoughnut
								title="Call type distribution"
								stats={callTypeStats}
							/>
						)}
						{callCustomerStats && (
							<MyDoughnut
								title="Customer distribution"
								stats={callCustomerStats}
							/>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setDistributionDialogOpen(false)}
						color="primary"
						autoFocus
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function getHumanReadableDuration(start, end) {
		const dur = new Date(end).getTime() - new Date(start).getTime();
		const durStr = new Date(dur).toISOString();
		const days = Math.floor(dur / DAY_IN_MS);
		return (days > 0 ? days + 'd ' : ' ') + durStr.substr(11, 8);
	}

	function statusLogDialog() {
		return (
			<Dialog
				open={!!selectedStatusLog}
				onClose={() => setSelectedStatusLog(null)}
				aria-labelledby="alert-dialog-title"
			>
				<DialogTitle id="alert-dialog-title">
					Call status log
				</DialogTitle>
				<DialogContent>
					<Timeline position="right">
						{selectedStatusLog &&
							selectedStatusLog.map((s, i) => {
								const isLast =
									i === selectedStatusLog.length - 1;
								return (
									<TimelineItem key={i}>
										<TimelineSeparator>
											<TimelineDot />
											{!isLast && <TimelineConnector />}
										</TimelineSeparator>
										<TimelineContent
											style={{ whiteSpace: 'nowrap' }}
										>
											<Typography variant="caption">
												Starting{' '}
												{new Date(
													s.call_status_update_time
												).toString()}
											</Typography>
											<Typography>
												was in {s.call_status}{' '}
											</Typography>
											{!isLast && (
												<Typography variant="caption">
													for{' '}
													{getHumanReadableDuration(
														s.call_status_update_time,
														selectedStatusLog[i + 1]
															.call_status_update_time
													)}
												</Typography>
											)}
										</TimelineContent>
										<TimelineOppositeContent
											style={{ width: '0px' }}
										></TimelineOppositeContent>
									</TimelineItem>
								);
							})}
					</Timeline>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setSelectedStatusLog(null)}
						color="primary"
						autoFocus
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function toggle(value, list, setter) {
		const newList =
			list.indexOf(value) === -1
				? [...list, value]
				: list.filter((v) => v !== value);
		setter(newList);
	}

	function isSelected(value, filtered) {
		return filtered.indexOf(value) === -1;
	}

	function filterComponent(
		name,
		anchorEl,
		setAnchorEl,
		options,
		filtered,
		filteredSetter
	) {
		if (!options || options.length < 2) return null;
		return (
			<>
				<Button
					variant={filtered.length > 0 ? 'contained' : 'outlined'}
					onClick={(e) => setAnchorEl(e.currentTarget)}
				>
					{name}
					<Badge
						badgeContent={options.length - filtered.length}
						color="primary"
						style={{ margin: '0 5px' }}
					>
						<FilterListIcon />
					</Badge>
				</Button>
				<Menu
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					open={Boolean(anchorEl)}
				>
					{options.map((t, i) => (
						<MenuItem
							key={i}
							onClick={() => toggle(t, filtered, filteredSetter)}
							selected={isSelected(t, filtered)}
						>
							{t}
						</MenuItem>
					))}
				</Menu>
			</>
		);
	}

	return (
		<div>
			<Backdrop open={loading} style={{ zIndex: '5000' }}>
				<CircularProgress />
			</Backdrop>
			<div>
				<TextField
					style={{ width: '350px' }}
					value={callIdsStr}
					onChange={(e) => setCallIdsStr(e.target.value)}
					label="Call ids"
					multiline
				/>
				{!fetchingCallStatus && (
					<IconButton onClick={fetchCallStatuses}>
						<SyncIcon />
					</IconButton>
				)}
				{filteredCalls.length > 0 && (
					<>
						<Button
							variant="contained"
							style={{ margin: '5px' }}
							onClick={() =>
								navigator.clipboard.writeText(
									filteredCalls.map((c) => c.id).join('\n')
								)
							}
						>
							Copy ids
						</Button>
						<Button
							variant="contained"
							style={{ margin: '5px' }}
							onClick={() => setDistributionDialogOpen(true)}
						>
							Show distribution
						</Button>
						<Button
							variant="contained"
							style={{ margin: '5px' }}
							onClick={() => setReprocessDialogOpen(true)}
						>
							Reprocess
						</Button>
					</>
				)}
			</div>
			{filteredCalls.length > 0 && (
				<>
					<div>
						{filterComponent(
							'Type',
							typeMenuAnchorEl,
							setTypeMenuAnchorEl,
							uniqCallTypes,
							filteredCallTypes,
							setFilteredCallTypes
						)}
						{filterComponent(
							'Status',
							statusMenuAnchorEl,
							setStatusMenuAnchorEl,
							uniqCallStatuses,
							filteredCallStatuses,
							setFilteredCallStatuses
						)}
						{filterComponent(
							'Customer',
							customerMenuAnchorEl,
							setCustomerMenuAnchorEl,
							uniqCustomers,
							filteredCustomers,
							setFilteredCustomers
						)}
					</div>
					<div>{callStatusesTable()}</div>
				</>
			)}
			{distributionDialog()}
			{reprocessDialog()}
			{statusLogDialog()}
		</div>
	);
}
