import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement, Tooltip, Legend} from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

function getColor(d, seed) {
  var r = (Math.floor((d + seed) * 360) % 360);
  return "hsl(" + r + ",50%,70%)";
}

function makeChartJsData(stats) {
  if (!stats) return null;
  const labels = Object.keys(stats);
  const data = labels.map(k => stats[k]);
  const seed = Math.random();
  const backgroundColor = labels.map((k, i) => getColor(i / labels.length, seed));
  return {
    datasets: [{data, backgroundColor}],
    labels
  };
}

export default function MyDoughnut({stats, title}) {
  return (<span style={{margin: '15px'}}>
    <div style={{marginBottom: '5px'}}>{title}:</div>
    <Doughnut data={makeChartJsData(stats)} options={{
      plugins: {
        legend: {
          position: 'top'
        }
      }
    }} />
  </span>);
}