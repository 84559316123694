import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton } from '@mui/material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import S3DataView from 'S3DataView';
import { getConfig } from './config';
import { getJsonData } from 'network';
import { getData } from 'network';
import ObjectTreeView from './ObjectTreeView';
import CustomerSelect from './CustomerSelect';
import { formElement, select } from './util';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IntegrationMetricsTable from 'IntegrationMetricsTable';
import SyncDetailsTable from 'SyncDetailsTable';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const customerDataOptions = [
	'HUBSPOT',
	'RINGCENTRAL',
	'OUTREACH',
	'AIRCALL_RECORDING',
	'FRESHCALLER',
	'DIALPAD',
	'SALESLOFT',
	'CALL_HIPPO',
	'FRONTSPIN',
	'ZOOM_PHONE',
	'PIPEDRIVE',
	'SALESFORCE',
	'FRESHSALES',
	'CLOSE',
	'RESTAPI',
];

function IntegrationMetrics(props) {
	const [customerData, setCustomerData] = useState([]);
	const [selectedCustomerId, setSelectedCustomerId] = useState('');
	const [selectedCrm, setSelectedCrm] = useState('');
	const [fetchedData, setFetchedData] = useState([]);
	const [nSyncs, setNSyncs] = useState(10); // Default value
	const [upto, setUpto] = useState(new Date().toISOString());
	const [fetchedS3Data, setFetchedS3Data] = useState([]);
	const [isFetchingData, setIsFetchingData] = useState(false);

	const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
	const [selectedSyncData, setSelectedSyncData] = useState({});
	const [showS3Data, setShowS3Data] = useState(false);
	const [activeSyncId, setActiveSyncId] = useState(null); // New state to track the active file
	const [isFetchingS3Data, setIsFetchingS3Data] = useState(false); // New state to track whether S3 data is being fetched

	const handleDetailsDialogOpen = (data) => {
		console.log('Selected Sync Data:', data);
		setSelectedSyncData(data);
		setOpenDetailsDialog(true);
	};

	const handleDetailsDialogClose = () => {
		setOpenDetailsDialog(false);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
		setFetchedS3Data([]); // Clear the S3 data when the dialog is closed
	};

	const handleCrmChange = (event) => {
		setSelectedCrm(event.target.value);
	};

	const fetchData = async () => {
		setIsFetchingData(true);

		try {
			const endpoint = '/debug_call/integration/fetch-data';
			const response = await getData(endpoint, {
				customerId: selectedCustomerId,
				crm: selectedCrm,
				nSyncs: nSyncs,
				upto: upto,
				nSyncs: nSyncs,
				upto: upto,
			});
			const data = await response.json();
			setFetchedData(data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		setIsFetchingData(false);
	};

	const fetchCustomerData = async () => {
		try {
			const endpoint = '/customers/all';
			const response = await getJsonData(endpoint);
			setCustomerData(response);
		} catch (error) {
			console.error('Error fetching customer data:', error);
		}
	};

	useEffect(() => {
		fetchCustomerData();
	}, []);

	const fetchS3Resources = async (syncId) => {
		setActiveSyncId(syncId); // Update the activeSyncId state when starting the fetch
		setIsFetchingS3Data(true);
		try {
			console.log(`Fetch S3 frontend function inside try`);
			const config = await getConfig();
			const params = {
				crm: selectedCrm,
				customerId: selectedCustomerId,
			};

			const endpoint = `/debug_call/integration/fetch-s3-data/${syncId}`;
			console.log('Endpoint:', endpoint, 'Params:', params);
			const response = await getData(endpoint, params);

			if (!response.ok) {
				throw new Error(
					`Server responded with status: ${response.status}`
				);
			}

			const s3Data = await response.json();
			const formattedS3Data = s3Data.map((file) => {
				return {
					name: file.name,
					size: file.size,
				};
			});
			setFetchedS3Data(formattedS3Data);

			setShowS3Data(true);
			setIsFetchingS3Data(false); // Set the fetching status to false when data has been fetched

			console.log(
				'Fetched S3 Data for Sync ID:',
				syncId,
				formattedS3Data
			);
		} catch (error) {
			console.error('Error fetching S3 data for Sync ID:', syncId, error);
			setIsFetchingS3Data(false); // Set the fetching status to false if an error occurs
		}
	};

	return (
		<div
			style={{
				maxWidth: '100%',
				width: '100%',
				
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1.5rem',
						width: '100%',
						maxWidth: '100%',
						
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							maxWidth: '100%'
						}}
					>
						<div style={{ width: '27%' }}>
							<CustomerSelect onSelect={setSelectedCustomerId} />
						</div>
						<div style={{ width: '18%' }}>
							{formElement(
								select(
									selectedCrm,
									setSelectedCrm,
									customerDataOptions
								),
								'CRM Name'
							)}
						</div>
						<div style={{ width: '8%' }}>
							{formElement(
								<TextField
									type="number"
									value={nSyncs}
									onChange={(e) => setNSyncs(e.target.value)}
									label="nSyncs"
								/>
							)}
						</div>

						<div style={{ width: '25%' }}>
							<DateTimePicker
								renderInput={(props) => (
									<TextField {...props} />
								)}
								label="Upto Date-Time"
								value={upto}
								onChange={(date) => setUpto(date.toISOString())}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</div>

						<div
							style={{
								width: '5%',
								height: '100%',
								marginTop: '0.8rem',
							}}
						>
							<IconButton
								color="primary"
								disabled={isFetchingData}
								onClick={fetchData}
							>
								<CloudDownloadIcon
									style={{ fontSize: '2rem' }}
								/>
							</IconButton>
						</div>
					</div>

					{fetchedData.length > 0 && (
						<div style={{ marginTop: '1rem' }}>
							<IntegrationMetricsTable
								key={fetchedData.length}
								data={fetchedData}
								onFetchS3={fetchS3Resources}
								onViewDetails={handleDetailsDialogOpen}
								activeSyncId={activeSyncId} // Pass the active file as a prop
								isFetchingS3Data={isFetchingS3Data} // Pass the fetching status as a prop
							/>
						</div>
					)}

					{showS3Data && <S3DataView data={fetchedS3Data} />}

					<Dialog
						open={openDetailsDialog}
						onClose={handleDetailsDialogClose}
						aria-labelledby="details-dialog-title"
						aria-describedby="details-dialog-description"
						maxWidth="lg"
						fullWidth={true}
					>
						<DialogTitle id="details-dialog-title">
							Sync Details
						</DialogTitle>
						<DialogContent>
							<SyncDetailsTable data={selectedSyncData} />
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleDetailsDialogClose}
								color="primary"
							>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</LocalizationProvider>
		</div>
	);
}

export default IntegrationMetrics;
