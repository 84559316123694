import React, { useReducer } from 'react';
import { GptPlaygroundFoundation } from './components';
import { initialState, gptScreenReducer, GptPlaygroundContext, GptPlaygroundDispatchContext } from './redux';


function GptPlayground() {
  const [gtpPlaygroundState, gptPlaygroundDispatcher] = useReducer(
    gptScreenReducer,
    initialState
  );

  return (
    <GptPlaygroundContext.Provider value={gtpPlaygroundState}>
      <GptPlaygroundDispatchContext.Provider value={gptPlaygroundDispatcher}>
        <GptPlaygroundFoundation />
      </GptPlaygroundDispatchContext.Provider>
    </GptPlaygroundContext.Provider>
  );
}

export default GptPlayground;
