import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textField: {
		'& .MuiInputBase-inputMultiline': {
			minHeight: (props) => props.minHeight || '3em',
			maxHeight: (props) => props.maxHeight || '3em',
		},
	},
}));

export default function MultilineTextInput(props) {
	const {
		maxRows = 4,
		maxChars = 1000,
		onChange,
		label,
		size = 'large',
		variant = 'outlined',
		value,
		minHeight,
		disabled = false,
	} = props;

	const classes = useStyles({ minHeight });

	const handleChange = async (event) => {
		onChange(event.target.value);
	};

	const handleOnPaste = async (event) => {
		onChange(event.target.value);
	};

	return (
		<TextField
			className={minHeight?.length ? classes.textField : ''}
			disabled={disabled}
			size={size}
			variant={variant}
			onPaste={handleChange}
			fullWidth
			label={label}
			multiline
			maxRows={maxRows}
			value={value}
			onChange={handleChange}
			inputProps={
				maxChars && {
					maxLength: maxChars,
				}
			}
			InputProps={
				maxChars && {
					endAdornment: (
						<span
							style={{
								fontSize: '12px',
							}}
						>
							{maxChars - value?.length}/{maxChars}
						</span>
					),
				}
			}
		/>
	);
}

MultilineTextInput.propTypes = {
	maxRows: PropTypes.number,
	maxChars: PropTypes.number,
	onChange: PropTypes.func,
	label: PropTypes.string,
	size: PropTypes.string,
	variant: PropTypes.string,
	value: PropTypes.string,
	minHeight: PropTypes.string,
	disabled: PropTypes.bool,
};
