import {
	Paper,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	Box,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DebugImages from './DebugImages';
import CallStatuses from './CallStatuses';
import DebugCallData from './DebugCallData';
import DebugCallEvent from './DebugCallEvent';
import DebugCallEvents from './DebugCallEvents';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import EventIcon from '@mui/icons-material/Event';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DebugCallSummaries from './DebugCallSummaries';
import { getConfig } from './config';


const envMarker = getConfig().envMarker;

export default function Debug(props) {
  const debugPages = [{
    key: 'debug_images',
    component: <DebugImages />,
    icon: <BurstModeIcon />,
    label: 'Debug images'
  }, {
    key: 'call_statuses',
    component: <CallStatuses />,
    icon: <FindReplaceIcon />,
    label: 'Call statuses'
  }, {
    key: 'call_data',
    component: <DebugCallData />,
    icon: <FormatAlignJustifyIcon />,
    label: 'Call data'
  }, {
    key: 'call_event',
    component: <DebugCallEvent />,
    icon: <EventIcon />,
    label: 'Call event'
  }, {
    key: 'call_events',
    component: <DebugCallEvents />,
    icon: <DateRangeIcon />,
    label: 'Call events'
  },{
    key: 'call_summaries',
    component: <DebugCallSummaries/>,
    icon: <SummarizeIcon/>,
    label: 'Call Summaries'
    
  }
];

	const [selectedDebugPage, setSelectedDebugPage] = useState('debug_images');

	useEffect(() => {
		const hash =
			window.location.hash.length > 0
				? window.location.hash.substr(1)
				: null;
		if (!hash) return;
		debugPages.forEach((page) => {
			if (hash.startsWith(page.key)) setSelectedDebugPage(page.key);
		});
	}, []);

	return (
		<Paper style={{ padding: '10px' }}>
			<Box display="flex" flexDirection="row">
				<Box flex="0 0 0">
					<List>
						<ListItem button component="a" href="../">
							<ListItemIcon title="Back">
								<KeyboardArrowLeftIcon />
							</ListItemIcon>
						</ListItem>
					</List>
					<Divider />
					<List>
						{debugPages.map((page) => (
							<ListItem
								button
								onClick={(e) => setSelectedDebugPage(page.key)}
								key={page.key}
								selected={page.key === selectedDebugPage}
							>
								<ListItemIcon title={page.label}>
									{page.icon}
								</ListItemIcon>
							</ListItem>
						))}
					</List>
				</Box>
				<Box
					flex="1 0 auto"
					style={{
						padding: '15px',
						height: '700px',
						overflowY: 'scroll',
					}}
				>
					{
						debugPages.filter(
							(page) => page.key === selectedDebugPage
						)[0].component
					}
				</Box>
			</Box>
		</Paper>
	);
}
