import React from 'react';
import { Button, Typography } from '@mui/material';
import { toggleSwitch, formElement } from './common/FormControls';
import { postJson } from '../network';

export default function OnBoardingEmailControls({
	customerId,
	doNotSendOnBoardingEmails,
	setDoNotSendOnBoardingEmails,
	classes,
	currentCustomerDonotSendOnboardingEmailFlag,
}) {
	const toggleOnboardingControl = () => {
		setDoNotSendOnBoardingEmails((prevState) => !prevState);
	};

	const sendWelcomeEmails = async () => {
		await postJson('/customers/send_welcome_emails', { customerId });
	};

	return (
		<>
			<h3>Onboarding Controls</h3>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
                    margin: '6px'
				}}
			>
				{formElement(
					toggleSwitch({
						state: doNotSendOnBoardingEmails,
						handleChange: toggleOnboardingControl,
						label: 'Do not send onboarding emails',
					})
				)}
				<Button
					variant="contained"
					color="primary"
                    style={{fontSize: '14px'}}
					disabled={currentCustomerDonotSendOnboardingEmailFlag}
					onClick={sendWelcomeEmails}
				>
					Send welcome emails
				</Button>
			</div>
		</>
	);
}
